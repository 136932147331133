@import "src/common/scss/typography.scss";

.custom-label {
    @include text-field-label;
    padding-right: 6px;
    flex-shrink: 0;
    display: inline-block;
    margin-bottom: 3px;

    &.custom-label--disabled {
        @include text-field-label-inactive;
    }
}

.disablePadding {
    padding: 0px !important;
}
