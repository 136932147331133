@import 'src/denali-components/functions.scss';

.titleContainer {
  display: flex;
  gap: rem(8);

  .title {
    margin-bottom: rem(4);
    max-width: rem(250);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.titleCentered {
  align-items: center;
}

.dateContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: rem(2);

  .date {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(14);
    line-height: rem(20);
    margin: 0;
  }
}

.typeContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: rem(2);
  text-align: left;

  .type {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(14);
    line-height: rem(20);
    margin: 0;
  }
}
