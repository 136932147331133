@import '../../denali-components/functions.scss';

.actionTitle {
  font-size: rem(18);
  font-weight: 500;
  line-height: rem(24);
}

.collapsibleWithActions {
  padding: 0;

  > [class*='collapsible_collapsibleHeader'] {
    padding: rem(20) 0 rem(20);
  }

  // Since we want the border to go  to the right edge, we add left padding to the header and
  // right padding to the actions.
  .collapsibleWithActionsHeader {
    padding: 0 0 0 rem(20);
  }

  .collapsibleToggle {
    margin-right: rem(20);
  }
}

.collapsibleWithActionsHeader {
  &.collapsed {
    .collapsibleWithActionsButtons {
      opacity: 0;
      visibility: collapse;
      transform: translateX(100px);
      transition: opacity 100ms ease-out, transform 150ms ease-out,
        visibility 5ms linear 300ms;
    }

    [class*='amplify-button'] {
      &[class*='amplify-button--small'] {
        > svg {
          transform: rotateZ(180deg);
        }
      }
    }
  }

  .collapsibleWithActionsButtons {
    height: 36px;
    width: 290px;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: opacity 100ms ease-in 100ms, translate 200ms ease-in,
      visibility 5ms linear;
  }

  [class*='amplify-button'] {
    &[class*='amplify-button--small'] {
      border-radius: rem(12);
      padding: rem(8) rem(16);
      background-color: var(--amplify-colors-neutral-10);
      color: var(--amplify-colors-neutral-90);

      > svg {
        margin-left: rem(10);
        transform: rotateZ(0deg);
        transition: transform 300ms ease-in-out;
      }
    }
  }
}
