@import 'src/denali-components/functions.scss';

.title {
  margin-bottom: rem(8);
}

.status {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
}

.documentSummary {
  padding: rem(24);
  margin: rem(24) 0;
  border-radius: rem(24);
  border: 1px solid var(--amplify-colors-neutral-20);

  .documentModalTitle {
    margin: rem(16) 0 rem(4);
    font-size: rem(18);
    line-height: normal;
    font-weight: 500;
  }

  .documentModalChips {
    margin-bottom: rem(32);
  }
}

.documentModalAttachments {
  .attachmentsHeadline {
    font-size: rem(18);
    line-height: normal;
    font-weight: 500;
    margin-bottom: rem(16);
  }

  .attachmentRow {
    margin: 0;
    padding: rem(12) rem(16);
    border-radius: rem(16);
    border: 1px solid var(--amplify-colors-neutral-20);
    display: flex;
    align-items: center;
  }
}
