@import '../../common/scss/colors.scss';
@import '../../common/scss/grid.scss';

.truck-roll-kpi-table {
  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    color: $tba-darker-grey;
    background-color: $tba-light-grey;
    border-radius: 2px 2px 0 0;
    .icon {
      margin: 0;
      font-size: 18px;

      &.icon-left-caret,
      &.icon-right-caret {
        cursor: pointer;
      }
    }
  }

  &-year {
    margin: 0 20px;
    font-size: 22px;
  }

  &-wrapper {
    columns: 2 auto;
    grid-template-columns: 1fr 1fr; 
    padding: 10px 5px 18px;
    color: $black;
    background-color: $tba-lightest-grey;
    border-left: 1px solid $tba-dark-grey;
    border-right: 1px solid $tba-dark-grey;
    border-bottom: 1px solid $tba-dark-grey;
    border-radius: 0 0 2px 2px;

    @include screen-max-small {
      grid-template-columns: 1fr; 
    }
    .month-group {
      display: flex;
      flex-direction: column; 
      //width: 285px;
    }
  }

  .trkt-row,
  .trkt-titles-row {
    display: grid;
    grid-template-columns: 70px calc(50% - 40px) calc(50% - 40px);
    grid-gap: 2px;
    height: 35px;

    span {
      display: flex;
      align-items: center;
      margin-left: 4px;
      font-weight: bold;
    }
  }

  .trkt-row {
    margin-top: 4px;

    input {
      margin-left: 4px;
      background-color: $white;
    }
  }

  .trkt-titles-row {
    @include screen-max-small {
      &:not(:first-child) {
        display: none;
      }
    }

    span {
      font-size: 14px;
    }
  }

  .section-duplicate{
    background-color: #f4f4f4;
    border-left: 1px solid #999999;
    border-right: 1px solid #999999;
    display: flex;
    padding-left: 8px;
  }
}
