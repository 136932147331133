@import "./colors.scss";
@import "./emerald-icons.scss";
//mixins
	@mixin bounding-area {
		height: 200px;
		margin: 10px 0;
		padding: 16px;
		border: 1px solid $tba-dark-grey;
		border-radius: 2px;
		background-color: $white;
	};

.spinner {
	display: inline-block;
	font-family: "Emerald" !important;
	line-height: 1;
	color: $tba-dark-grey;
	animation: rotate 1.2s infinite linear;
	&::before {
		content: map_get($icons, loader);
	}
}

@keyframes rotate {
	100% { transform: rotate(360deg); }
}

.page-loading-area,
.section-loading-area,
.dialog-loading-area,
.widget-loading-area {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: wait;
}

.widget-loading-area {
	position: absolute;
	top: 0;
	left: 0;
	background-color: $white;
}

.table-loading-area {
	display: flex;
	align-items: center;
	justify-content: center;
	@include bounding-area;
	cursor: wait;
}

.page-loading-area .spinner,
.table-loading-area .spinner,
.widget-loading-area .spinner {
	font-size: 160px;
	height: 160px;
}

.section-loading-area .spinner {
	font-size: 135px;
	height: 135px;
}

.dialog-loading-area .spinner {
	font-size: 120px;
	height: 120px;
}
