@import "src/components/legacy/common/colors.scss";

//mixins
@mixin text-button {
	font-weight: 900;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.36px;
	text-transform: uppercase;
	color: $white;
}

@mixin text-button-small {
	font-weight: 900;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.27px;
	text-transform: uppercase;
	color: $white;
}

@mixin button-link {
	@include text-button;
	text-decoration: underline;
	color: $tba-darker-grey;
	&:hover {
		color: $tba-dark-grey;
	}
}

@mixin text-field-label {
	font-weight: bold;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.26px;
}

@mixin text-field-label-secondary {
	font-weight: bold;
	font-size : 12px;
	line-height : 14px;
	letter-spacing : 0.36px;
}

@mixin text-field-label-inactive {
	color: $tba-dark-grey;
}

@mixin check-box-inactive {
	background: $tba-medium-grey;
}

@mixin text-field-label-error {
	color: $red;
}

@mixin text-field-input {
	font-weight: bold;
	font-size: 12px;
	line-height: 18px;
}

@mixin text-field-input-inactive {
	color: $tba-dark-grey;
	background-color: $tba-light-grey;
	border-color: $tba-medium-grey;
}

@mixin text-field-input-error {
	background: $pink;
	border: 2px solid $red;
}

@mixin text-field-item {
	font-weight: bold;
	font-style: italic;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.20px;
}

@mixin text-form-error {
	font-weight: bold;
	font-style: italic;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.26px;
	color: $red;
}

@mixin text-drop-down {
	font-weight: bold;
	font-size: 12px;
	line-height: 26px;
}

@mixin text-drop-down-small {
	font-weight: bold;
	font-size: 10px;
	line-height: 22px;
}

@mixin text-list {
	font-size: 12px;
	line-height: 19px;
	letter-spacing: 0.24px;
}

@mixin text-list-condensed {
	font-family: "Archivo Narrow", sans-serif;
	font-size: 12px;
	line-height: 19px;
	letter-spacing: 0.24px;
}

@mixin text-search-item {
	font-family: "Archivo Narrow", sans-serif;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.24px;
}

@mixin small-table-header {
	font-weight: 900;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.36px;
	text-transform: uppercase;
	color: $black;
}

@mixin text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin text-menuitem {
	font-size: 14px;
	line-height: 18px;
}

@mixin no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

@mixin user-select($string: none) {
	-webkit-touch-callout: $string;
	-webkit-user-select: $string;
	-khtml-user-select: $string;
	-moz-user-select: $string;
	-ms-user-select: $string;
	user-select: $string;
}
