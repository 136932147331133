.base_tooltip {
  position: fixed;
  pointer-events: none;
  padding: 0.75em;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 0.25em 1em 0 rgba(0, 0, 0, 0.15);
  animation: fade-in 0.25s ease;
  font-size: 0.9em;
}
.base_tooltip__title {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.color-item {
  border-radius: 3px;
  display: flex;
  height: 15px;
  margin: 0 6px 6px 0;
  width: 15px;
}
.div_flex{
  display: flex;
  flex-wrap: wrap;
  
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-left-item{
  text-align: start;
}
.grid-right-item{
  text-align: end;
  .value{
    margin-left: 5px;
  }
}