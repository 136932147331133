.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  z-index: auto;
  box-sizing: border-box;
}

.mainContent {
  overflow: auto;
  flex: 1;
  z-index: 1;
}