@import '../functions.scss';

[data-amplify-theme='denali-theme'] {
  .errorMessage {
    color: var(--amplify-colors-red-60);
  }

  :global(.amplify-radiogroup) {
    :global(.amplify-radio__button) {
      border-color: var(--amplify-colors-neutral-30);

      &:focus,
      &:hover {
        background-color: var(--amplify-colors-neutral-10);
      }

      &:before {
        width: 65%;
        height: 65%;
      }
    }

    :global(.amplify-radio__input:checked + .amplify-radio__button) {
      color: var(--amplify-colors-neutral-90);
    }
  }

  // Card styled Radio buttons
  .cardRadioGroup {
    gap: rem(16);
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: rem(6) 0 0;

    .cardRadio {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      border: 1px solid var(--amplify-colors-neutral-20);
      border-radius: rem(16);
      color: var(--amplify-colors-neutral-90);
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
      height: rem(118);
      min-width: rem(263);
      padding: rem(16);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      &.cardRadioDisabled {
        opacity: 0.5;
      }

      :global(.amplify-radio__button) {
        border-color: var(--amplify-colors-neutral-30);

        &:before {
          width: 65%;
          height: 65%;
        }
      }

      :global(.amplify-radio__input:checked + .amplify-radio__button) {
        color: var(--amplify-colors-neutral-90);
      }

      [data-color='orange'] svg {
        background: var(
          --amplify-components-denali-table-chip-orange-background-color
        );
        color: var(--amplify-components-denali-table-chip-orange-color);
      }

      [data-color='purple'] svg {
        background: var(
          --amplify-components-denali-table-chip-purple-background-color
        );
        color: var(--amplify-components-denali-table-chip-purple-color);
      }

      [data-color='turquoise'] svg {
        background: var(--amplify-colors-turquoise-10);
        color: var(--amplify-colors-turquoise-50);
      }

      [data-color='pink'] svg {
        background: var(
          --amplify-components-denali-table-chip-pink-background-color
        );
        color: var(--amplify-components-denali-table-chip-pink-color);
      }

      [data-color='green'] svg {
        background: var(
          --amplify-components-denali-table-chip-green-background-color
        );
        color: var(--amplify-components-denali-table-chip-green-color);
      }

      [data-color='blue'] svg {
        background: var(
          --amplify-components-denali-table-chip-blue-background-color
        );
        color: var(--amplify-components-denali-table-chip-blue-color);
      }

      [data-color='black'] svg {
        background: var(
          --amplify-components-denali-table-chip-black-background-color
        );
        color: var(--amplify-components-denali-table-chip-black-color);
      }

      [data-color='gold'] svg {
        background: var(
          --amplify-components-denali-table-chip-gold-background-color
        );
        color: var(--amplify-components-denali-table-chip-gold-color);
      }

      [data-color='red'] svg {
        background: var(
          --amplify-components-denali-table-chip-red-background-color
        );
        color: var(--amplify-components-denali-table-chip-red-color);
      }

      svg {
        border-radius: rem(12);
        height: rem(18);
        width: rem(18);
        padding: rem(11);
      }

      .cardRadioText {
        margin-top: rem(8);
      }
    }
  }

  .denaliForm {
    fieldset {
      background-color: var(--amplify-colors-white);
      border: 1px solid var(--amplify-colors-neutral-20);
      border-radius: rem(20);
      margin: rem(10) 0;
      padding: rem(20);

      .userBubble {
        margin-top: rem(6);
        margin-bottom: 0;
        padding: rem(12);
      }
    }

    .cancelButton {
      background-color: var(--amplify-colors-neutral-20);
      color: var(--amplify-colors-black);
    }

    input {
      background-color: var(--amplify-colors-white);
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(12);
      color: var(--amplify-colors-neutral-90);
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
      height: rem(44);
      margin: rem(10) 0;
      padding: rem(12) rem(16);

      &::placeholder,
      &::-webkit-input-placeholder {
        color: var(--amplify-colors-neutral-50);
      }

      &[disabled] {
        color: var(--amplify-colors-neutral-40);
        border-color: var(--amplify-colors-neutral-10);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &.lrgInput {
        border-radius: rem(16);
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(24);
        height: rem(54);
        padding: rem(16) rem(24);
      }

      // Name inputs need to be slightly larger text.
      &[name='name'] {
        font-size: rem(16);
        height: rem(54);
      }
    }

    :global(.amplify-input),
    :global(.amplify-textarea) {
      &:global(.amplify-input--error),
      &:global(.amplify-textarea--error) {
        border: 1px solid var(--amplify-colors-red-50);
      }
    }

    select {
      align-items: center;
      background-color: var(--amplify-colors-white);
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(12);
      color: var(--amplify-colors-neutral-90);
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
      height: rem(44);
      margin-bottom: rem(10);
      padding: rem(12) rem(16);
      text-transform: capitalize;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:has(option[value='']:checked) {
        color: var(--amplify-colors-neutral-50);
      }
    }

    :global(.amplify-textareafield) {
      height: 100%;
    }

    textarea {
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(12);
      height: 100%;
      min-height: 135px;

      &::placeholder,
      &::-webkit-input-placeholder {
        color: var(--amplify-colors-neutral-50);
      }
    }

    :global(.amplify-selectfield) {
      min-width: 200px;
    }

    :global(.amplify-selectfield),
    :global(.amplify-textareafield) {
      &.smlLabel {
        label {
          color: var(--amplify-colors-neutral-90);
          font-size: rem(14);
          font-weight: 500;
          line-height: rem(20);
        }
      }

      &.lrgInput select {
        border-radius: rem(16);
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(24);
        height: rem(54);
        padding: rem(16) rem(24);
        position: relative;
      }
    }

    input[type='search']::-webkit-search-cancel-button {
      cursor: pointer;
      filter: brightness(0) saturate(100%);
      padding: rem(1);
    }

    :global(.amplify-select__icon-wrapper) {
      transform: translateY(-65%);
      height: rem(24);
      width: rem(24);

      :global(.amplify-select__icon) {
        height: rem(24);
        width: rem(24);
      }

      svg {
        color: var(--amplify-colors-neutral-40);
        height: rem(24);
        width: rem(24);
      }
    }

    hr {
      border: none;
      border-top: 1px solid var(--amplify-colors-neutral-20);
      margin: rem(32) 0;
    }

    .horizontalWrapper {
      overflow-x: auto;
      position: relative;
    }

    .scrollRightOverlay {
      background: var(--amplify-components-denali-table-filter-scroll-right);
      height: rem(122);
      width: rem(80);
      pointer-events: none;
      position: absolute;
      bottom: rem(16);
      right: 0;
      z-index: 1;
    }

    label {
      color: var(--amplify-colors-neutral-90);
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(22);
    }

    .lrgLabel {
      font-size: rem(18);
      font-weight: 500;
      line-height: rem(24);
    }

    .smlLabel {
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
    }

    .emptyLabelSpacer {
      margin-top: rem(34);
    }

    ::-webkit-calendar-picker-indicator {
      background-image: url(../../denali-ui/legacy/components/icon/svg/Icons__calendar-solid.svg);
      fill: var(--amplify-colors-neutral-50);
      height: rem(24);
      width: rem(24);
    }

    ::-webkit-scrollbar {
      width: rem(8);
      height: rem(8);
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--amplify-components-denali-table-row-border-color);
      border-radius: rem(4);
    }

    .scrollOverlayWhiteFade {
      background: var(--amplify-components-denali-table-filter-scroll-light);
      height: rem(40);
      inset: auto 0 0;
      pointer-events: none;
      position: absolute;
      z-index: 1;
    }

    .selectorContainer {
      padding: 0;
      .searchInner {
        margin-bottom: rem(10);
      }

      .cardRadioGroup {
        margin-bottom: rem(10);
      }
    }

    // Inline Building Selector
    .inlineBuildingSelectorHeader {
      color: var(--amplify-colors-neutral-90);
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(22);

      svg {
        background-color: var(--amplify-colors-blue-lightest);
        border-radius: rem(12);
        color: var(--amplify-colors-blue-50);
        height: rem(18);
        width: rem(18);
        padding: rem(11);
      }

      .subHeader {
        color: var(--amplify-colors-neutral-50);
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(20);
      }
    }

    .search,
    .searchInner {
      position: relative;
      width: 100%;

      svg {
        position: absolute;
        z-index: 2;
        top: rem(24);
        left: rem(18);
        width: rem(14);
        height: rem(14);

        path {
          fill: var(--amplify-components-denali-table-filter-color);
        }
      }

      input {
        padding-inline-start: rem(40);
      }

      input[type='search']::-webkit-search-cancel-button {
        cursor: pointer;
        filter: brightness(0) saturate(100%);
        padding: rem(1);
      }
    }

    .createdByContainer {
      gap: rem(20);
      input,
      select {
        margin-top: rem(6);
      }
    }

    .inlineEquipmentSelector {
      position: relative;
      gap: 0;

      fieldset {
        margin: 0;
      }

      .loaderWrapper {
        display: flex;
      }
    }

    // may need checkbox specific styles
    .cardCheckboxWrapper {
      @extend .cardRadioGroup;
      margin-bottom: rem(10);

      .cardCheckbox {
        @extend .cardRadio;
      }
      .checkbox {
        //will need to target checkbox icon and color while overriding amplify styles
        svg {
          padding: 0;
        }
      }
    }

    .noBuildingResults {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      background: var(--amplify-colors-neutral-10);
      border: none;
      border-radius: rem(16);
      color: var(--amplify-colors-neutral-90);
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
      height: rem(118);
      margin-top: rem(10);

      .noBuildingsSubText {
        color: var(--amplify-colors-neutral-50);
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(20);

        svg {
          color: var(--amplify-colors-neutral-50);
          margin-right: rem(8);
        }
      }
    }

    // Inline Drop Area
    .attachedFiles,
    .filesError {
      section {
        border: 0;
        padding: 0;
        flex-grow: 1;
      }
    }

    // Card Financials
    .financialsWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: rem(16);
      margin: rem(16) 0;
    }

    .cardFinancial {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 1 0 49%;
      gap: rem(16);
      background-color: var(--amplify-colors-white);
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(24);
      max-width: 49%;
      padding: rem(20);

      hr {
        border-top: solid 1px var(--amplify-colors-neutral-20);
        margin: 0;
        width: 100%;
      }

      label {
        white-space: nowrap;
      }

      .icon {
        background: var(--amplify-colors-black);
        color: var(--amplify-colors-white);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(12);
        padding: rem(8);
        width: rem(40);
        height: rem(40);

        &[data-color='turquoise'] {
          background: var(--amplify-colors-turquoise-lightest);

          svg {
            color: var(--amplify-colors-turquoise-50);
          }
        }

        &[data-color='green'] {
          background: var(--amplify-colors-green-lightest);

          svg {
            color: var(--amplify-colors-green-60);
          }
        }

        &[data-color='orange'] {
          background: var(--amplify-colors-orange-lightest);

          svg {
            color: var(--amplify-colors-orange-50);
          }
        }

        &[data-color='purple'] {
          background: var(--amplify-colors-purple-lightest);

          svg {
            color: var(--amplify-colors-purple-50);
          }
        }

        svg {
          width: rem(18);
          height: rem(18);
        }
      }

      .title {
        color: var(--amplify-colors-neutral-90);
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(22);
      }

      .cardPreview {
        display: flex;
        flex-direction: column;
        gap: rem(4);
        border: solid 1px var(--amplify-colors-neutral-20);
        border-radius: rem(16);
        padding: rem(16);
        width: 100%;

        .primary {
          font-size: rem(24);
          font-weight: 500;
          line-height: rem(30);
          text-transform: lowercase;
        }

        .disclaimer {
          color: var(--amplify-colors-neutral-50);
          font-size: rem(14);
          font-style: italic;
          font-weight: 400;
          line-height: rem(20);
          text-transform: capitalize;
        }

        .description {
          font-size: rem(14);
          font-weight: 400;
          line-height: rem(20);
        }
      }

      .formula {
        display: flex;
        align-items: center;
        gap: rem(8);
        color: var(--amplify-colors-neutral-50);
        font-size: rem(12);
        font-weight: 500;
        line-height: rem(16);
        width: 100%;

        svg {
          height: rem(12);
          width: rem(12);
          color: var(--amplify-colors-neutral-50);
        }
      }

      &.disabledCard {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    // Text Editor
    .textEditorWrapper {
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(18);
      padding: rem(4) rem(8);
    }

    .textEditorToolbar {
      border: none;
      padding: rem(8) 0 0;
      margin-bottom: 0;

      :global(.rdw-option-wrapper) {
        border: none;

        &:hover {
          box-shadow: unset;
        }
      }
    }

    .textEditorMain {
      background-color: var(--amplify-colors-white);
      border: none;
      min-height: 230px;
      padding: 0 rem(8);
    }

    .subSectionSpacer {
      margin: rem(4) 0;
    }

    .inlineSubSectionSpacer {
      margin: rem(20) 0;
    }

    .visibilityWrapper {
      background-color: var(--amplify-colors-white);
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(16);
      position: relative;

      .visibilityIcon {
        position: absolute;
        top: rem(16);
        left: rem(16);
        height: rem(24);
        width: rem(24);

        &.visible {
          color: var(--amplify-colors-green-50);
        }

        &.notVisible {
          color: var(--amplify-colors-red-50);
        }
      }

      .visibilitySelect select {
        background-color: transparent;
        height: rem(54);
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(24);
        padding: rem(16) rem(24) rem(16) rem(48);
      }

      &.smlSelect {
        .visibilityIcon {
          top: rem(16);
          left: rem(16);
          height: rem(14);
          width: rem(14);
        }

        .visibilitySelect select {
          font-size: rem(14);
          line-height: rem(20);
          height: rem(44);
          padding: rem(12) rem(16) rem(12) rem(38);
        }
      }
    }

    .titleRow {
      gap: rem(16);

      fieldset {
        &:not(.userBubble) {
          margin: 0;
        }
      }

      .titleInput {
        height: rem(54);
      }

      .titleColumn {
        gap: rem(20);

        .emptyLabelSpacer {
          margin-top: rem(14);
        }

        .lrgLabel {
          padding: 0 rem(1);

          .errorMessage {
            font-size: rem(14);
          }
        }
      }
    }

    .sectionRoot {
      column-gap: rem(32);
      row-gap: rem(32);

      fieldset {
        margin: 0;
      }

      .impactContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        border: rem(1) solid var(--amplify-colors-neutral-20);
        padding: rem(24);
        background: var(--amplify-colors-white);
        border-radius: rem(20);

        hr {
          margin: 0;
        }

        [data-color='blue'] svg {
          color: var(--amplify-components-denali-table-chip-blue-color);
        }

        [data-color='orange'] svg {
          color: var(--amplify-components-denali-table-chip-orange-color);
        }

        [data-color='purple'] svg {
          color: var(--amplify-components-denali-table-chip-purple-color);
        }

        [data-color='turquoise'] svg {
          color: var(--amplify-colors-turquoise-50);
        }

        [data-color='pink'] svg {
          color: var(--amplify-components-denali-table-chip-pink-color);
        }

        [data-color='green'] svg {
          color: var(--amplify-components-denali-table-chip-green-color);
        }

        [data-color='blue'] svg {
          color: var(--amplify-components-denali-table-chip-blue-color);
        }

        [data-color='black'] svg {
          color: var(--amplify-components-denali-table-chip-black-color);
        }

        [data-color='gold'] svg {
          color: var(--amplify-components-denali-table-chip-gold-color);
        }

        [data-color='red'] svg {
          color: var(--amplify-components-denali-table-chip-red-color);
        }

        [data-color='transparent-black'] svg {
          color: var(
            --amplify-components-denali-table-chip-transparent-black-color
          );
        }

        .impactIcon {
          svg {
            background-color: transparent;
          }
        }

        .impactLabel {
          text-transform: capitalize;
        }

        .impactLabelContainer {
          display: flex;
          flex-direction: column;
          gap: rem(6);
        }

        .kpiSpecificValueContainer {
          display: flex;
          flex-direction: row;
          flex: 0 1;

          .kpiSpecificValueElement {
            margin-top: 0;
            text-align: left;
            display: flex;
            flex-direction: row;
            flex: 1 1 0%;
            min-width: 120px;
            :global(.amplify-select__wrapper) {
              align-self: start;
            }
          }
        }

        .customMetricInputContainer {
          border: none;
          padding: 0;
          display: flex;
          gap: rem(6);
          flex-direction: column;

          .customMetricInputElement {
            margin-top: 0;
          }
        }

        .impactCheckboxCard {
          display: flex;
          flex-direction: row;
          flex: 1 1 32%;
          min-width: rem(300);
          max-width: 50%;
          border-radius: rem(20);
          border-top: rem(1);
          border-right: rem(1);
          border-bottom: rem(4);
          border-left: rem(1);
          border-style: solid;
          border-color: var(--amplify-colors-neutral-20);
        }

        @media screen and (min-width: 1400px) {
          .impactCheckboxCard:nth-of-type(n + 4) {
            flex: 1 1 23%;
          }
        }
        @media screen and (max-width: 1180px) {
          .impactCheckboxCard {
            max-width: 100%;
          }
        }
      }
    }

    .settingsRow {
      flex-direction: row;
      gap: 16px;

      .subLine {
        font-size: rem(12);
        font-weight: 500;
        font-style: italic;
        color: var(--amplify-colors-neutral-50);
        margin-bottom: 0px;
      }

      .descriptionContainer {
        gap: rem(20);
        width: 50%;
        flex: 1;
        height: max-content;
        fieldset {
          padding: 0;
        }
      }

      .imageContainer {
        display: flex;
        flex: 1;
        padding: rem(20);
        gap: rem(20);
        height: auto;
      }

      .supportingImages {
        display: flex;
        flex-direction: column;
        gap: rem(12);
        flex: 1;
        height: auto;
      }
    }

    .attatchmentSectionRow {
      flex-direction: row;
      padding: rem(20);
      gap: rem(32);

      .attachmentContainer {
        flex: 1;
        padding: 0;
        border: none;
      }

      .verticalDivider {
        display: flex;
        align-items: stretch;
        justify-content: stretch;

        span {
          background-color: var(--amplify-colors-neutral-20);
          width: 1px;
        }
      }

      .miscContainer {
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        padding: 0 rem(1) rem(1);
        > div {
          flex-direction: row;
          > fieldset {
            flex: 1;
            gap: rem(6);
            padding: 0;
            input,
            select {
              margin: 0;
            }
          }
        }
      }
    }

    .denaliFormDisabledOverlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      background-color: var(--amplify-colors-neutral-formoverlay);
      pointer-events: none;

      &.visible {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
        pointer-events: all;
      }
    }

    .footerActionsWrapper {
      flex-direction: row;
      justify-content: flex-end;
      gap: rem(12);
    }
  }

  .addFindingButton,
  .addImageButton {
    display: flex;
    flex: 1 1 48%;
    flex-direction: column;
    align-items: center;
    gap: rem(12);
    justify-content: center;
    background-color: var(--amplify-colors-white);
    border: 2px dashed var(--amplify-colors-neutral-20);
    border-radius: rem(20);
    color: var(--amplify-colors-neutral-60);
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(20);
    height: 100%;
    text-align: center;
    padding: rem(16) 0;

    label {
      color: var(--amplify-colors-black);
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(22);
    }

    svg {
      height: rem(24);
      width: rem(24);
    }
  }

  .addFindingButton {
    order: 99;
  }
}

button.buttonWrapper {
  background: var(--amplify-colors-white);
  color: var(--amplify-colors-black);
  border: 1px solid var(--amplify-colors-neutral-20);
}

.denaliFileUpload {
}

.supportingImageContainer {
  display: flex;
  flex-direction: column; // Or row for side-by-side
  gap: rem(12);
  height: 100%;

  // Switch to column layout if there's a dropzone
  // &:has(.imageContainer) {
  //   flex-direction: column;
  //   gap: rem(20);
  // }

  .dragAndDropWrapper {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    // & + button {
    //   margin-top: rem(12);
    // }
  }

  .gap20 {
    display: flex;
    flex-direction: column;
    gap: rem(20);
  }

  .imageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: rem(16);
    row-gap: rem(20);
    padding: rem(20);
    border-radius: rem(20);
    border: 1px solid var(--amplify-colors-neutral-20);
  }

  .imageUploadField {
    flex: 1;

    .imageUploadFieldLabel {
      font-size: rem(14);
    }
  }

  .imageUploadSpinner {
  }

  .uploadAreaOverride {
    position: relative;
    border: 1px solid var(--amplify-colors-neutral-20);
    width: 100%;
    padding: rem(20);
    border-radius: rem(20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: rem(12);

    button.edit-icon {
      color: blue;
    }

    .imageLoadedWrapper {
      display: flex;
      gap: rem(12);
    }

    .clickableDiv {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    .backgroundImageContainer {
      all: unset;
      cursor: pointer;
      display: inline-flex;
      flex: 1;
      .bgImage {
        cursor: inherit;
        overflow: clip;
        border-radius: rem(12);
        height: rem(44);
        width: rem(44);
        background-position: center;
        background-size: contain;
      }
    }
    .pasteImageContainer {
      z-index: 9999;

      > div {
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
      }

      .pasteImageDiv {
        caret-color: transparent;
        user-select: none;
        color: var(--amplify-colors-neutral-60);
        font-size: rem(14);
        font-weight: 400;

        &:empty::before {
          content: attr(data-placeholder);
        }
      }
    }

    h2 {
      color: var(--amplify-colors-neutral-100);
      font-size: rem(16);
      font-weight: 400;
      margin-bottom: 0;
      line-height: rem(22);
      text-align: center;
    }
    h2 + span {
      text-align: center;
      font-size: rem(16);
    }
    h2 + span + div > div {
      text-align: center;
      margin-top: rem(12);
      span {
        padding: rem(12);
      }
    }
  }
}

.dialogWrapper {
  overflow: hidden;

  .assignedToContainer {
  }

  .searchContainer {
    width: 100%;
    position: relative;
    display: inline-flex;

    svg {
      position: absolute;
      z-index: 2;
      top: rem(14);
      left: rem(14);
      width: rem(14);
      height: rem(14);

      path {
        fill: var(--amplify-components-denali-table-filter-color);
      }
    }

    input.searchInput {
      padding-inline-start: rem(40);
    }
  }
  .scrollContainer {
    margin-top: rem(12);
    padding: 0 rem(4);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: rem(12);
    max-height: rem(400);

    .userOptionWrapper {
      // link variation looks good so far...
    }
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
