@import 'src/components/legacy/common/typography.scss';
@import 'src/components/legacy/controls/button.scss';

.wide-modal {
  width: 860px;
  top: 5vh;
}


.extra-wide-modal {
  width: 890px;
  top: 5vh;
}


.widget-tile-view-modal {
  width: 1005px;
  top: 5vh;
}

.widget-preview {
  max-width: 340px;
}
.color-picker-modal {
  max-width: 290px;
  top: -10vh;
  margin-left: 200px;
}

.center {
  position: relative;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -35%);
}

.modal-container {
  position: relative;
  padding: 0;
  border: 0;
  border-radius: 6px;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
  background: $white;

  .close-button {
    position: absolute;
    top: 14px;
    right: 12px;
    cursor: pointer;
  }

  .modal-panel {
    padding: 0;
    background: inherit;
    border-radius: 0;
    width: 100%;
  }

  .modal-header {
    width: 98%;
    height: 40px;
    padding: 0 10px 0 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    border-bottom: 1px solid $tba-lighter-grey;

    .icon {
      font-size: 22px;
      margin-right: 6px;
    }
    .icon-error {
      color: $red;
      font-size: 16px;
    }
  }

  .modal-content {
    border-top: 1px solid $white;
    padding: 20px;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 20px;
    color: inherit;
  }

  .modal-button-row {
    width: 100%;
    min-height: 44px;
    padding: 5px 20px;
    border-top: 1px solid $white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .error-message {
      display: flex;
      align-items: center;
      border: 2px solid $red;

      .error-text {
        margin: 0 9px;
        color: $red;
      }
    }

    .tip {
      padding-top: 0;
    }

    .modal-button {
      @extend button, .primary;
      margin: 8px 0 8px 12px;

      &.type-decline {
        @extend button, .red;
      }

      &.type-cancel {
        @extend button, .secondary;
      }

      &.type-alternative {
        @extend button, .action;
        border-radius: 2px;
      }
    }
  }

  &:not(.is-gray) {
    .modal-content {
      border-bottom: 1px solid $tba-lighter-grey;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &.is-gray {
    background: $tba-lighter-grey;

    .modal-panel {
      padding: 16px 10px;
      background: $white;
      border-radius: 6px;
    }
    .modal-panel-no-background {
      padding: 16px 10px;
      border-radius: 6px;
    }

    .modal-header {
      border-bottom: 1px solid $tba-light-grey;
    }

    .modal-content {
      border-bottom: 1px solid $tba-light-grey;
      font-weight: inherit;
      font-size: inherit;
      font-style: inherit;
    }
  }

  &.has-error {
    .modal-content {
      color: $red;
    }
  }
  @media all and (max-width: 709px) {
    margin-left: 100px;
    width: 452px;
  }
  @media all and (max-width: 899px) {
    margin-left: 100px;
    width: 452px;
  }
  @media all and (max-width: 519px) {
    margin-left: 80px;
    width: 350px;
  }
}

.custom-wide-modal {
  .modal-container {
    .modal-button-row {
      display: flex;
      justify-content: space-between;
    }
    .modal-content{
      padding-top:0px;
      .modal-panel-no-background{
        padding:0px
      }
    }
  }
  .conditional-tooltip{
    .conditional-tooltip__children-wrapper {
    .conditional-tooltip__ellipsis-wrapper {
     text-decoration: underline
    }
   }
    }
}
