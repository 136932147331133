@import '../../denali-components/functions.scss';

[data-amplify-theme='denali-theme'] {
  .fileBadge {
    display: flex;
    align-items: center;
    gap: rem(16);
    border: solid 1px var(--amplify-colors-neutral-20);
    border-radius: rem(24);
    color: var(--amplify-colors-neutral-90);
    font-size: rem(16);
    font-weight: 500;
    line-height: rem(22);
    min-width: 380px;
    padding: rem(16) rem(20);
    justify-content: space-between;
  }

  .fileContent {
    display: flex;
    flex: 1 1 80%;
    gap: rem(16);
    align-items: center;
  }

  .fileInfo {
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    gap: 0;
  }

  .fileDateAndSize {
    color: var(--amplify-colors-neutral-50);
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(20);
  }

  .descriptionField {
    margin-top: rem(16);

    label {
      color: var(--amplify-colors-black);
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(20);
      margin-top: rem(16);
    }
  }

  .fileDescription {
    display: flex;
    flex: 1 1 50%;
    font-size: rem(14);
    font-style: italic;
    font-weight: 400;
    line-height: rem(18);
  }

  .fileActions {
    display: flex;
    flex: 1 1 22%;
    align-items: center;
    gap: rem(8);
    justify-content: flex-end;
  }

  .filePreview {
    background-color: var(--amplify-colors-blue-lightest);
    border-radius: rem(12);
    min-width: rem(50);
    max-width: rem(50);
    height: rem(50);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--amplify-colors-blue-50);
      width: rem(18);
      height: rem(18);
    }

    // Need colors for different file types
    &[data-color='doc'] {
      background-color: var(--amplify-colors-blue-lightest);

      svg {
        color: var(--amplify-colors-blue-50);
      }
    }

    &[data-color='pdf'] {
      background-color: var(
        --amplify-colors-purple-lightest
      ); // Testing - need specs

      svg {
        color: var(--amplify-colors-purple-50); // Testing - need specs
      }
    }
  }

  // FindingBadge
  .findingPreview {
    margin-right: rem(12);
    position: relative;
    width: 100px;
    height: auto;
    max-height: 80px;

    :global(.image-gallery-wrapper) {
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(12);
      height: 100%;
      min-height: 100%;
    }

    > :global(
        .image-gallery-wrapper
          .image-gallery-content:not(.fullscreen)
          .image-gallery-slides
      ) {
      height: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    p:empty {
      display: none;
    }
  }

  .findingLink {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(16);
    font-weight: 500;
    line-height: rem(22);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
