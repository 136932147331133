@import "src/components/legacy/common/typography.scss";

.equipment-setup-details-page {
	.not-found {
		padding: 20px;
	}
	.react-page__header {
		background: $white;
		border: 1px solid $tba-dark-grey;
		border-radius: 2px;

		.vertical-line {
			border-left: 1px solid $tba-dark-grey;
			margin: 10px 0;
			padding: 0;
		}

		.horizontal-line {
			border-top: 1px solid $tba-dark-grey;
			margin: 0 10px;
			padding: 0;
		}

		.esdp-left-column {
			.esdp-image-placeholder {
				align-items: center;
				background: $tba-lighter-grey;
				border: 1px solid $tba-light-grey;
				color: $tba-light-grey;
				display: flex;
				height: 120px;
				overflow: hidden;
				width: 180px;

				img {
					width: 100%
				}
			}

			.esdp-equpment-params {
				> label {
					width: 180px;
				}

				.esdp-equipment-param__value {
					min-width: 200px;
					//white-space: nowrap;
				}
			}
		}

		.esdp-right-column {
			padding: 10px 20px;

			.esdp-data-map__header {
				margin-top: 0;
				margin-bottom: 8px;
			}

			.esdp-data-map {
				display: inline-block;
				margin-bottom: 10px;

				.esdp-data-map__title {
					border-bottom: 1px solid $tba-light-grey;
					font-size: 16px;
					margin: 0 0 5px 0;
					white-space: nowrap;

					.icon-check,
					.icon-unavailable-x {
						border-radius: 50%;
						display: inline-block;
						font-size: 12px;
						height: 18px;
						line-height: 18px;
						margin-right: 5px;
						text-align: center;
						vertical-align: middle;
						width: 18px;
					}

					.icon-check {
						background: $tba-green;
						color: $white;
					}

					.icon-unavailable-x {
						color: $red;
					}

					.esdp-data-map__message {
						display: flex;
						align-items: center;
					}

					button {
						margin: 10px 0 10px 24px;
					}
				}

				.esdp-data-map__sub-title {
					color: $tba-darker-grey;
					font-style: italic;
				}
			}

			.esdp-date {
				font-size: 14px;
				margin-top: 4px;
			}
		}
	}

	.tab-list {
		.conditional-tooltip__children--content {
			white-space: nowrap;
		}
	}

	.esdp-new-automated-tests-tab {
		label {
			width: 65px;
			height: 18px;
			color: #000000;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: -0.21px;
			line-height: 18px;
		}
		a{
			color: inherit;
			width: 277px;
			height: 16px;
			font-size: 13px;
			font-weight: normal;
			line-height: 16px;
			&:hover{
				color: #000000;
				text-decoration: underline;
			}
		}
	}

	.equipment-characteristics {
		font-size: 13px;

		.toolbar-buttons {
			display: flex;
			padding-bottom: 10px;
			padding-left: 6px;

			&.left {
				justify-content: flex-end;
			}

			button {
				margin-left: 14px;
			}
		}

		@media (max-width: 900px) {
			.rx-col-6 {
				width: 100%;

				&:first-child {
					padding-bottom: 0;
				}

				&:last-child {
					padding-top: 0;
				}
			}
		}
		.state-title{
			font-size: 14px;
			font-weight: bold;
			padding: 0px 20px;
		}
		.state-btn-wrapper{
			[class^="icon-"]{
				height:26px;
				padding: 6px 10px;
			}
			button:first-child{
				margin-right:6px;
			}
		}
		.param__value {
			margin-top: 5px;

			.fields-group {
				display: flex;

				.input-group, .select-wrapper, .reactDatePickerInput {
					flex-grow: 1;
					min-width: 200px;
					max-width: unset;
					width: auto;
				}

				.input-group {
					display: flex;
					align-items: center;

					input {
						flex-grow: 1;
						text-align: left;
					}

					.uom {
						padding-left: 5px;
					}
				}

				.datepicker-input {
					background: $tba-lightest2-grey;
				}

				.value-type {
					margin:0;
					align-self: center;
					margin-left: 10px;
					font-size: 12px;
					font-style: italic;
					font-weight: 400;
					flex-basis: 90px;
				}

				.reset {
					align-self: center;
					font-size: 12px;
					margin-left: 10px;
					flex-basis: 100px;
					cursor: pointer;
					color: $blue-2;
					text-decoration: underline;
				}
			}

			.error-message {
				position: absolute;
				padding: 0;
				border: none;
				color: $red;
				font-size: 10px;
				font-style: italic;
				font-weight: bold;
				height: 16px;
				letter-spacing: 0.20px;
				line-height: 16px;
				width: 100%;
			}
		}
	}

	.esdp-associated-equipment-tab {
		.toolbar-buttons {
			display: flex;
			justify-content: flex-end;
			padding-bottom: 10px;
		}

		.associated-equipment-table {
			.actions {
				.conditional-tooltip {
					display: inline-block;
					vertical-align: middle;
					margin: 0 2px;
				}
			}

			.table-column {
				padding-top: 2px;
				padding-bottom: 2px;
			}
		}
	}

	.esdp-characteristic-curves-tab {
		.header {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #ccc;
			margin-bottom: 20px;
			padding-bottom: 8px;

			.custom-label {
				margin: 0;
				line-height: 22px;
			}

			.go-back-link-button {
				margin-left: 30px;
				font-weight: 700;
				text-decoration: underline;
				background: none;
				border: none;
			}

			.toolbar-buttons {
				text-transform: uppercase;
			}
		}

		.characteristic-curves-table {
			.name a {
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}

			.actions {
				.conditional-tooltip {
					display: inline-block;
					vertical-align: middle;
				}

				.icon-button {
					width: 25px;
				}
			}
		}
	}
}

.association-dialog-modal {
	.edit-view {
		&.edit-view--type-select {
			.select-wrapper {
				.control,
				.select-dropdown {
					width: auto;
					max-width: 300px
				}
			}
		}
	}
}

.associated-details-modal {

	&.wide-modal {
		width: 860px;
	}

	.input-focus-kludge {
		position: absolute;
		visibility: hidden;
	}


	.modal-container {
		.modal-panel {
			padding: 20px;
		}
	}

	.param__value {
		line-height: 15px;
	}

	.toggle-box-container:not(:first-child) {
		margin-top: 15px;

		.toggle-box-body-inner {
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 409px;
		}
	}

	.equipment-characteristics {
		border: 1px solid $tba-dark-grey;
		border-radius: 2px;

		.custom-label {
			padding: 12px 14px;
		}

		.rx-grid [class*="rx-col-"] {
			padding: 8px;
		}
	}

	.section-spinner {
		margin: 0;
	}
}

.characteristic-curve-modal {
	width: 860px;

	.left-column {
		.edit-view {
			&.edit-view--group-type {
				.edit-view__fields {
					display: block;
				}
			}

			.edit-view__field {
				height: auto;
			}

			.range-component {
				label {
					font-size: 12px;
					font-weight: normal;
				}
				label + input {
					margin-left: 5px;
				}
				input {
					width: 80px;
					text-align: center;
				}
				input + label {
					margin-left: 15px;
				}
			}

			&.validation-error {
				.custom-label {
					@include text-field-label-error;
				}

				.range-component {
					label {
						@include text-field-label-error;
					}
					input {
						@include text-field-input-error;
					}
				}
			}
		}

		.x-axis-component {
			.edit-view {
				padding: 0;
			}
		}

		.enable-test {
			.checkbox-container {
				display: inline-block;

				label {
					font-size: 12px;
				}
			}

			.icon-infocircle {
				padding-left: 5px;
			}
		}
	}

	.right-column {
		border-left: 1px solid $tba-light-grey;
	}
}


.esdp-associated-equipment-tab 	.conditional-tooltip {
	display: inline-block;
	vertical-align: middle;
	margin: 0 2px;
}

