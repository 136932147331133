.amplify-button {
  &.button-icon {
    font-size: rem(14);
    line-height: rem(14);
    min-width: rem(44);
    min-height: rem(44);
    padding-inline-start: rem(12);
    padding-inline-end: rem(12);
  }

  &.button-help {
    background-color: var(--amplify-colors-neutral-10);
    border-radius: 50%;
    cursor: pointer;
    width: rem(24);
    height: rem(24);
    padding: 0;

    svg {
      color: var(--amplify-colors-neutral-60);
      width: rem(12);
      height: rem(12);
    }
  }

  &:focus-visible {
    outline: 1px solid var(--amplify-colors-blue-50);
    outline-offset: 2px;
  }

  &.amplify-button--small {
    border-radius: var(--amplify-components-button-small-border-radius);
  }

  svg:first-child:not(:last-child) {
    margin-right: rem(12);
  }

  &.amplify-button--menu-trigger {
    svg {
      margin-left: rem(8);
    }
  }

  &.amplify-button--menu {
    svg {
      margin-right: rem(8);
    }
  }

  &.button-paginate {
    border-radius: rem(8);
    font-size: rem(14);
    min-width: rem(36);
    min-height: rem(36);
    padding-inline-start: rem(6);
    padding-inline-end: rem(6);

    img {
      width: rem(20);
      height: auto;
    }
  }

  &.pagination-select {
    border-radius: rem(8);
    background-color: var(--amplify-colors-neutral-10);
    color: var(--amplify-colors-neutral-50);
    font-size: rem(14);
    font-weight: 500;

    svg {
      margin-left: rem(8);
    }
  }
}

.amplify-menu-content .amplify-button--menu,
.amplify-button {
  &.amplify-button--disabled,
  &:disabled,
  &.amplify-button--disabled:hover,
  &:disabled:hover,
  &.amplify-button--disabled:focus,
  &:disabled:focus {
    background: var(--amplify-components-button-disabled-background-color);
    color: var(--amplify-components-button-disabled-color);
  }
}

.amplify-buttongroup {
  gap: rem(12);
}

// increased selector specificity to avoid use of "!important"
[data-amplify-theme='denali-theme'] .amplify-button.button-paginate:hover {
  background: var(--amplify-colors-neutral-30);
  color: var(--amplify-colors-white);
}

// assuming we do not want the active button to turn gray on hover
[data-amplify-theme='denali-theme'] .amplify-button.button-paginate.active {
  background: var(--amplify-colors-black);
  color: var(--amplify-colors-white);
}

[data-amplify-theme='denali-theme'] .amplify-radiogroup {
  width: 100%;
}
