.engery-cost-summary-chart {
  width: 100%;
  height: 55vh;
  min-height: 400px;
}

.savings-line {
  fill: rgb(251, 143, 13);
}

.savings-text {
  font-size: 12px;
  justify-items: center;
  padding: 3px;
  > p {
    margin: 5px 0;
  }
}

.stacked-tt-item {
  text-transform: capitalize;
  > p {
    margin: 3px 0;
  }
}

.ecs-tooltip {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: black;
  padding: 10px;

  p {
    margin: 5px 0;
  }

  .title {
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
  }

  .total {
    display: flex;
    justify-content: space-between;
  }

  span.value {
    text-align: right;
    flex: 1;
  }

  span.icon {
    display: inline-block;
    width: 1px;
    height: 1px;
    padding: 5px;
    margin-right: 5px;
    background: transparent;
  }

  .item {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
  }
}

.ecs-tooltip-bg {
  background: #fff !important;
  -webkit-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
}

aside.engery-cost-summary-chart-legend {
  max-width: 200px;
}

.chart-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > svg {
    flex: 1;
  }
}

.ecs-timeline-chart {
  width: 100%;
  height: 100%;
}

.energy-cost-summary-time-slider {
  .tick .range-text {
    fill: black !important;
    font-size: 11px;
  }
}

.ecs-x-label-block {
  width: 25px;
  position: relative;
}

.ecs-x-label {
  transform-origin: 0 0;
  transform: rotate(270deg);
  position: absolute;
  top: 50%;
  white-space: nowrap;
  font-weight: 100;
}
