@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/loaders.scss";

.section-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    margin: 10px 0;
    padding: 16px;
    border: 1px solid $tba-dark-grey;
    border-radius: 2px;
    background-color: $white;
    cursor: wait;

    .spin-wheel {
        font-size: 135px;
        display: inline-block;
        font-family: "Emerald" !important;
        line-height: 1;
        color: $tba-dark-grey;
        animation: rotate 1.2s infinite linear;
    }

    &--overlay {
        background-color: $white;
        border: none;
        margin: 0;
        padding: 0;
        opacity: 0.8;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }
}

.confirm-dialog .section-spinner {
    border-width: 0;
}

