// ghost class for drag and drop sorting
.sortable-ghost {
  opacity: 0.4;
}

// tooltip styles for widget
.dashboard-widget-tooltip-line-series {
  font-size: 9px;

  .t-chart-tooltip-content-area {
    pointer-events: none;
    padding: 2px 4px;
  }

  .t-chart-tooltip-content-item p {
    // margin: 5px 0;
    gap: 0.25em;
    font-size: inherit;
    align-items: center;
  }

  .t-chart-tooltip-content-item.title p {
    margin: 0 0 3px 0;
  }

  .t-chart-tooltip-content-item.title .label {
    text-align: left;
  }
}

// legend styles for widget
.dashboard-widget-simple-legend {
  .t-chart-simple-legend-content {
    grid-template-columns: auto auto;
  }
}

.dashboard-widget-building-data-simple-legend {
  margin-top: 10px;
  min-height: 38px;

  .t-chart-simple-legend-item {
    column-gap: 2px;
  }

  .t-chart-legend-item-text {
    div {
      display: inline;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.one-column {
    .t-chart-simple-legend-content {
      grid-template-columns: 100%;
    }

    .tooltip-legend {
      bottom: 40px;
      max-height: 130px;
      overflow-y: scroll;
    }
  }

  &.two-column {
    .t-chart-simple-legend-content {
      grid-template-columns: 50% 50%;
    }

    .tooltip-legend {
      bottom: 87px;
      max-height: 200px;
      overflow-y: scroll;
      transform: translateX(120px);
      width: fit-content;
    }
  }

  &.three-column {
    .t-chart-simple-legend-content {
      grid-template-columns: 33% 33% 33%;
    }

    .tooltip-legend {
      bottom: 110px;
      max-height: 300px;
      overflow-y: scroll;
      transform: translateX(250px);
      width: fit-content;
    }
  }
}

.div-flex {
  padding-left: 8px;
  display: flex;
  margin-left: 1px;
}

.custom-tooltip-container {
  width: 100%;
  height: 100%;

  .title {
    border-bottom: 1px solid rgba(121, 121, 121, 1);
    text-shadow: 0 0px 0px rgba(121, 121, 121, 1);
    color: rgba(0, 0, 0, 0.2);
    font-size: 9px;
    font-weight: 600;
  }

  .item-container {
    display: grid;
    flex-direction: row;
    row-gap: 1px;

    .tip-item-container {
      display: flex;
      flex-direction: row;
      margin-top: 3px;

      .icon {
        width: 0.74em;
        height: 0.74em;
        background: steelblue;
      }

      .value {
        font-weight: 600;
        margin-left: 2px;
        font-size: 9px;
      }

      .tip-item {
        margin-top: -5px;
        display: grid;
      }
    }
  }
}
