@import "src/components/legacy/common/typography.scss";
@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/grid.scss";
@import "src/components/legacy/common/custom-media-queries.scss";

.nav-dropdown {
	display: inline-block;
	position: relative;
	text-align: right;
	z-index: 2;
	height: 36px;

	&.small {
		& button[type=menu] {
			width: auto;
			padding: 0 10px;

			& span:first-child {
				display: none;
			}
			& span.icon:not(.caret) {
					display: block;
					float: left;
					font-size: 20px;
					margin: 0px;
					height: 100%;
					padding: 10px 10px 0 0;
			}
		}

		& > div {
			margin-left: -59px;
		}
		& div.menu {
			padding-top: 0;
		}

		& div.menuitem.itemheader {
			display: block;
		}

	}

	& button[type=menu] {
		/* not possible to use flex on button element because of issue in FF
			https://github.com/philipwalton/flexbugs/issues/16
		*/
		height: 100%;
		padding: 1px 16px 1px 20px;
		margin-bottom: -1px; /* for dropdown menu to overlap */

		border: none;
		background: none;
		color: $white;
		@include text-menuitem;

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background: $tba-green;
			border: 1px solid $tba-dark-grey;
			border-radius: 6px 6px 0 0;
			box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
			opacity: 0;
			transition: all 0.4s ease;
		}

		& > div {
			display: inline-flex;
		}

		& span:first-child {
			display: block;
			float: left;
			max-width: 265px;
			margin: 9px 9px 9px 0;

			@include text-ellipsis;
		}

		& span.icon:not(.caret) {
			display: none;
		}
	}

	& .caret {
		display: block;
		float: right;
		width: 10px;
		margin: 13px 0;
		font-size: 12px;
		transition: all 0.4s ease;
	}

	& > div {
		position: absolute;
		right: 0px;
		min-width: calc(100% + 20px);
		width: auto;
		text-align: initial;
		max-height: 3px;
		margin-left: -20px;
		padding-left: 20px;
		overflow: hidden;
		border: 1px solid $tba-dark-grey;
		border-radius: 6px 0 6px 6px;
		box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
		visibility: hidden;
		transition: all 0.4s ease;
	}

	& div.menu {
		padding: 10px 0 10px 0;
		margin: 0 0 0 -20px;
		background: $tba-almost2-white;
	}

	& div.menuitem {
		display: flex;
		justify-content: space-between;
		max-width: 368px;
		padding: 0 20px;
		@include text-list;
		line-height: 24px;
		color: $black;
		cursor: pointer;
		@include text-ellipsis;

		&.selected {
			color: $white;
			background: $tba-green;
		}

		&.itemheader {
			display: none;
			font-weight: bold;
			font-size: 14px;
			line-height: 24px;
			background-color: $tba-medium-green;
			color: $white;
		}

		&.disabled {
			cursor: not-allowed;
			color: $tba-medium-grey;
		}

		& a {
			color: $black;
		}

		& span[class^="icon-"] {
			font-size: 14px;
			line-height: 24px;
			color: $tba-darker-grey;
		}
	}

	@media (max-width: 567px) {
		& menuitem {
			max-width: 148px;
		}

		& button[type=menu] span:first-child {
			max-width: 70px;
		}
	}

	@include screen-max-extra-small {
		.nav-dropdown {
			z-index: 6;
		}
	}
}

.opened.nav-dropdown {
	& button[type=menu]::after {
		opacity: 1;
	}

	& .caret{
		transform: rotate(180deg);
	}

	& > div {
		visibility: visible;
	}
}
