/*
 * Importing fonts via CSS files as there is an issue with style-components.
 *
 * ref (https://github.com/react-boilerplate/react-boilerplate/issues/2552)
 *
 */

/* 
 * font-family name should match with fontFamily object in ./font-style.js 
 *
 * font-style.js is reponsible for sharing font family name with components via theme.
 * 
 * sync it with fontFamily object if you add / remove fonts.
 *
 */

/*
 * font-family name : NeueHaasUnica-Regular
 * 
 * typography fontFamily prop value : secondary
 */
@font-face {
  font-family: NeueHaasUnica-Regular;
  src: url(../../assets/fonts/neue-haas-unica_regular.woff) format('woff');
}

/*
 * font-family name : NeueHaasUnica-Bold
 * 
 * typography fontFamily prop value : secondaryBold
 */
@font-face {
  font-family: NeueHaasUnica-Bold;
  src: url(../../assets/fonts/neue-haas-unica_bold.woff) format('woff');
}

/*
 * font-family name : NeueHaasUnica-Bold
 * 
 */
@font-face {
  font-family: ArialMT;
  src: url(../../assets/fonts/arial-mt-medium.woff) format('woff');
}
