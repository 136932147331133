@import '../../denali-components/functions.scss';
@import '../../denali-components/index.scss';

// Trouble with the buttons not being the right colors.
[class*='energy-intensity-page'] {
  [class*='utility-bill-data-button'] {
    background-color: var(--amplify-colors-neutral-10);
    color: var(--amplify-colors-black-100);

    &:hover {
      background-color: var(--amplify-colors-neutral-30);
    }
  }
}

.energyIntensityChart {
  flex: 1 1 75%;

  [class*='yAxisTick'] {
    text {
      font-size: rem(14);
    }
  }

  [aria-label*='tip'] {
    font-weight: 700;
    font-size: 12px;

    p {
      font-size: 10px;
    }
  }
}

.energyIntensityChartControls {
  margin-bottom: rem(20);
}

.energyIntensitySidebar {
  position: relative;
  flex: 0 0 25%;
  margin-left: rem(32);
  max-width: 280px;

  &.collapsed {
    max-width: 96px;
    padding: 0;
    flex: 0 0 auto;

    .energyIntensitySidebarEUIToggle,
    .energyIntensitySidebarECIToggle {
      height: 48px;
      width: 48px;

      p {
        display: none;
      }
    }

    .energyIntensitySidebarEUIToggleContent {
      padding: 0;
    }

    .energyIntensitySidebarManualInputToggles {
      p {
        display: none;
      }
    }

    .energyIntensitySidebarBenchmarkToggle,
    .energyIntensitySidebarMarkerToggle {
      max-width: 48px;
    }

    .energyIntensitySidebarDivider {
      margin: 0;
      width: 48px;
    }

    .amplify-card {
      padding: rem(12);
    }
  }

  &.energyIntensitySidebarManualInputsScroll {
    .scrollContainer {
      position: relative;
      max-height: 330px;
      overflow-y: scroll;
      // Hide the scroll bar for IE/Edge/FF
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .scrollOverlayWhiteFade {
      inset: auto 0 rem(40) 0;
    }
  }

  .energyIntensitySidebarManualInputsScrollContainer {
    position: relative;
  }

  .amplify-button {
    &:hover {
      background-color: inherit;
    }
  }

  .energyIntensitySidebarToggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: rem(30);
    left: -(rem(15));
    width: rem(28);
    height: rem(28);
    border-radius: 50%;
    padding: rem(10);
    background-color: var(--amplify-colors-white);
    color: var(--amplify-colors-black-100);
    box-shadow: var(--amplify-components-card-elevated-box-shadow);
    overflow: hidden;
  }

  [class*='amplify-card'] {
    border-radius: rem(32);
    padding: rem(24);
    box-shadow: -12px 45px 100px 0px #9041f40d;
    border: 1px solid var(--amplify-colors-neutral-20);
  }

  .energyIntensitySidebarEUIToggle {
    // Using a background gradient and then have the child element set a background color
    // since borders don't have built-in gradient support.
    height: 48px;
    background: var(--amplify-colors-gradients-eui-background);
    border-radius: rem(14);
    margin-bottom: rem(12);
    padding: rem(2);
    cursor: pointer;

    .energyIntensitySidebarEUIToggleContent {
      flex: 1 1 100%;
      background: var(--amplify-colors-gradients-eui-transparentbg);
      border-radius: rem(12);
      padding: rem(9);

      p {
        margin: 0;
        font-size: rem(14);
        white-space: nowrap;
      }
    }
  }

  .energyIntensitySidebarECIToggle {
    height: 48px;
    background: var(--amplify-colors-eci-background);
    padding: rem(12);
    border-radius: rem(12);
    cursor: pointer;

    .energyIntensitySidebarECIToggleContent {
      flex: 1 1 100%;
    }

    p {
      margin: 0;
      font-size: rem(14);
      white-space: nowrap;
    }
  }

  .energyIntensitySidebarBenchmarkToggle {
    min-height: 48px;
    max-height: 64px;
    padding: rem(12);
    border: 2px solid var(--amplify-colors-blue-30);
    border-radius: rem(12);
    margin-bottom: rem(12);
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  .energyIntensitySidebarMarkerToggle {
    min-height: 48px;
    max-height: 64px;
    padding: rem(12);
    border: 2px solid var(--amplify-colors-pink-30);
    border-radius: rem(12);
    margin-bottom: rem(12);
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  .energyIntensitySidebarManualInputToggles {
    p {
      margin: 0;
    }

    [class*='amplify-button'] {
      &[class*='amplify-button--small'] {
        height: 32px;
        width: 52px;
        background-color: var(--amplify-colors-neutral-10);
        border-radius: rem(12);
        color: var(--amplify-colors-black-100);

        &:hover {
          background-color: var(--amplify-colors-neutral-30);
        }
      }
    }
  }

  .energyIntensitySidebarManualInputToggles {
    p {
      margin: 0;
    }

    [class*='amplify-button'] {
      &[class*='amplify-button--small'] {
        height: 32px;
        width: 52px;
        background-color: var(--amplify-colors-neutral-10);
        border-radius: rem(12);
        color: var(--amplify-colors-black-100);

        &:hover {
          background-color: var(--amplify-colors-neutral-30);
        }
      }
    }
  }
}

.energyIntensitySidebarDivider {
  margin: 0;
}

.energyIntensitySidebarEnergyCost {
  p {
    &[class*='amplify'] {
      margin: 0;
    }
  }

  [class*='amplify-button'] {
    &[class*='--small'] {
      background-color: var(--amplify-colors-neutral-10);
      border-radius: rem(12);
      color: var(--amplify-colors-black-100);
    }
  }
}

.energyIntensitySidebarEnergyCostSavings {
  background-color: var(--amplify-colors-green-lightest);
  color: var(--amplify-colors-green-60);
  padding: rem(12);
  border-radius: rem(12);
}

.energyIntensityDatePickerDialog {
  [class*='amplify-button--link'] {
    border: 1px solid var(--amplify-colors-neutral-20);
    color: var(--amplify-colors-neutral-90);
  }
}

.energyIntensityDatePickerDialogContent {
  input {
    border-radius: rem(12);
    background: none;
    border: 1px solid var(--amplify-colors-neutral-20);
    color-scheme: light;
  }
}
