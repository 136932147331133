@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/grid.scss";

.main-react-mobile-toolbar{
	.right-tab.handle{
		display: none;
	}

	.toolbar-buttons{
		display: flex;
		justify-content: space-evenly;
		max-width: 182px;
		float: right;
		text-align: right;
		white-space: nowrap;
	}
	@include screen-max-extra-small {
		position: relative;
		height: 35px;
		width: 100%;

		.toolbar-buttons{
			float: right;
			line-height: 28px;
			margin-right: -500px;
			transition: 0.4s ease;
			border-top: 2px solid $tba-darker-grey;
			border-bottom: 2px solid $tba-darker-grey;
			border-left: 2px solid $tba-darker-grey;
			background-color: $white;
			height: 26px;
			margin-top: 3px;
			margin-bottom: 3px;
			button{
				margin-right: 5px;
				margin-left: 5px;
				&:first-child{
					margin-left: 15px;
				}
			}
		}
		.right-tab.handle{
			float: right;
			display: block;
			margin-top: 3px;
			transition: 0.4s ease;
			height: 26px;
			width: 28px;
			margin-right: -1px;
			min-width: 28px;
			.icon{
				font-size: 16px;
			}
		}
		.wrapper{
			position: absolute;
			top: 3px;
			right: 0;
			overflow: hidden;
			width: 30px;
			transition: 0.4s ease;
			&.expanded{
				width: 290px;
				@include screen-tiny {
					width: 250px;
				}
				.toolbar-buttons{
					margin-right: 0;
				}
			}
			border-right: 2px solid $tba-darker-grey;
		}
		.tooltip {
			display: none;
		}
	}
}

.toolbar-buttons {
	.marker-icon {
		color: $tba-darker-grey;
		background-color: transparent;
		font-family: "Emerald" !important;
		font-style: normal;
		font-weight: normal;
		font-feature-settings: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		width: 28px;
		font-size: 16px;
		border: none;
	}
	.tooltip {
		padding: 0 6px;
	}
}
