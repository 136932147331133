aside.building-data-chart-legend {
  max-width: 300px;
}

.building-data-line-chart-wrapper {
  > svg {
    height: calc(100% - 60px);
  }
}

.building-data-time-slider{
  .tick .range-text{
    fill: black !important;
    font-size: 11px;
  }
}

