@import '../functions.scss';

.errorContainer {
  margin: rem(16 / 2) 0;
  padding: rem(16);
  border-radius: rem(12);
  border: 1px solid var(--amplify-colors-red-50);
  background-color: var(--amplify-colors-white-100);
}

[data-amplify-theme='denali-theme'] {
  .errorContainer {
    p {
      margin: 0;
    }
  }
}

.errorIcon {
  [data-color='red'] {
    svg {
      color: var(--amplify-colors-red-50);
    }
  }
}

.errorMessage {
  color: var(--amplify-colors-red-50);
}
