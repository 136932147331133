@import "../../common/colors.scss";
@import "../../common/grid.scss";

//variables $ mixins
	$column-width: 260px;
	$gutter-width: 20px;
	@mixin dialog-standard {
		& .table-list td .icon {
			font-size: 14px;
		}
	}

dialog.main-general-modal.modal{
	@include screen-medium {
		&.wide-modal{
			width: 860px;
		}
	}

    .modal-container{
		.confirmationText{
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			font-style: italic;
		}
		.modal-datepicker-wrapper .date-container{
			width: 51%;
		}
        &.is-gray {
            .modal-panel{
                padding: 0px;
                background-color:transparent
            }
            .content{
                border:0px
            }
            .form-panel{
                background-color: transparent;
            }
        }
        .modal-button-row .modal-button{
            &.type-unvalidated{
                background-color: #eeeeee;
                border-color: #cccccc;
                color: #cccccc;
            }
            &.type-valid:hover{
                background-color: #4c912d;
            }
            &.type-cancel{
                background-color: #bbbbbb;
                color: #ffffff;
            }
            &.type-cancel:hover{
                background-color: #999999;
            }
        }
        .inner-form-panel{
            &>div {
                margin-bottom:15px;
                position: relative;
            }
        }
        .tab-content{
            padding:16px;
            background-color: white;
			border-radius: 0px 4px 4px 4px;
			display: none;
		}
		.selected-tab-content{
			display: block;
		}
        .tab-header{
            background-color: #ccc;
            line-height: 36px;
            font-size: 14px;
            font-weight: 900;
			padding: 9px 39px 10px 17px;
			border-radius: 4px 4px 0px 0px;
			margin-right: 2px;
            .icon{
                font-size:14px;
                margin-right:0px;
            }
		}
		.selected-tab-header{
			background-color: white;
		}
        .has-error{
            color: #D52B23;
        }
        .modal-container .modal-button-row .error-message{
            display: block;
            width: 50%;
            margin-top: 2px;
            margin-bottom: 2px;
        }
	}

	.modal-panel-inner{
		max-width: 100%;
		min-height: 250px;
		@include screen-small {
			& .col-md-1 {
				width: $column-width;
			}
			& .col-md-2,
			& .col-md-3 {
				width: calc(#{$column-width} * 2 + #{$gutter-width});
			}
		}

		@include screen-medium {
			& .col-lg-1 {
				width: $column-width;
			}
			& .col-lg-2 {
				width: calc(#{$column-width} * 2 + #{$gutter-width});
			}
			& .col-lg-3 {
				width: calc(#{$column-width} * 3 + #{$gutter-width} * 2);
			}
		}
		@include screen-small {
			& {
				width: calc(#{$column-width} * 2 + #{$gutter-width} * 3);
			}
		}

		@include screen-medium {
			& {
				width: calc(#{$column-width} * 3 + #{$gutter-width} * 4);
			}
		}

		@include screen-max-extra-small {
			width: $column-width * 3 + $gutter-width * 2;
		}
	}
}

