@import "../../../../../common/scss/grid.scss";
@import "../../../../../common/scss/typography.scss";

.kpi-strip-block {
	position: relative;
	padding: 10px 20px 20px;
	background: $white;

	.additional {
		height: auto;
		min-height: 22px;
		line-height: 20px;
		margin-right: 15px;
		background-color: $tba-lighter-grey;
		transition: background-color 100ms linear;

		&:hover {
			background-color: $tba-lighter2-grey;
		}
	}

	.icon-edit {
		color: $tba-darker-grey;
	}

	.edit {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 16px;
		padding: 10px 10px 5px 5px;
	}

	.energy-metric-list-container {
		// position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.cell {
		padding: 0 20px 0 0;
		margin-top: 14px;
		min-height: 84px;
		height: auto;
	}

	.item {
		min-height: 84px;
		border-radius: 6px;
		// background-image: linear-gradient(to bottom, $blue-5 0%, $blue-6 56%, $blue-6);
		background-image: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	}

	.placeholder .item {
		text-transform: uppercase;
		text-align: center;
		font-weight: 900;
		font-size: 18px;
        width: 250px;

		.icon-energy-roi {
			// position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(0%) translateY(45%);
			color: $blue-7;
			// z-index: 1;
			font-size: 50px;
		}

		.text {
			// position: absolute;
			top: 50%;
			transform: translateY(-80%);
			width: 100%;
			text-align: center;
			// z-index: 2;
		}
	}

	.settings {
		@include text-field-label;
		min-height: 24px;
		margin-bottom: 6px;

		span {
			font-size: 14px;
		}
		> div {
			display: inline;
		}

		.period {
			margin-left: 14px;
			font-weight: 900;

			> span {
				font-weight: normal;
				font-size: inherit;
			}
		}
	}

	@include screen-max-large {.cell-5 {display: none}}
	@include screen-max-medium {.cell-4 {display: none}}
	@include screen-max-small {.cell-3 {display: none}}
	@include screen-max-extra-small {.cell-2 {display: none}}
}
