@import "src/components/legacy/common/grid.scss";
@import "src/components/legacy/common/typography.scss";

//variables
	$column-width: 260px;
	$gutter-width: 20px;
	@mixin dialog-standard {
		& .table-list td .icon {
			font-size: 14px;
		}
	}

@keyframes fadeout {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slidedown {
	from {
		opacity: 0;
		transform: translate(0, 25%);
	}
	to {
		opacity: 1;
		transform: translate(0,0);
	}
}

@keyframes slidedown-fixed {
	from {
		opacity: 0;
		transform: translate(0, 25%);
	}
	to {
		opacity: 1;
		transform: translate(0, -50%);
	}
}

@keyframes slideup {
	from {
		opacity: 1;
		transform: translate(0,0);
	}
	to {
		opacity: 0;
		transform: translate(0, -25%);
	}
}

dialog {
	position: absolute;
	width: calc(#{$column-width} + #{$gutter-width} * 2);
	margin: auto;
	padding: 0;
	border: 0;
	opacity: 1;
	background: transparent;
	overflow: visible;

	&.add-edit-building-area-safari{
		top: 40%
	}

	& label > .note {
		display: block;
		padding: 0;
		color: $tba-darker-grey;
		font-size: 11px;
		font-style: italic;
		letter-spacing: normal;
	}

	& input + label > .note {
		margin-left: -18px;
	}

	@include screen-small {
		& {
			width: calc(#{$column-width} * 2 + #{$gutter-width} * 3);
		}
	}

	&.slide {
		animation-duration: 0.4s;
		animation-name: slidedown;
		&.fixed {
			animation-name: slidedown-fixed;
		}
		&.hide {
			animation-duration: 0.4s;
			animation-name: slideup;
		}
	}

	& fieldset.valid-indicator {

		& .icon-check,
		& .icon-error,
		& .spinner {
			float: right;
			display: none;
			font-size: 19px;
			height: 18px;
			width: 18px;
			margin: 4px;
		}

		&.checking .spinner {
			display: inline-block;
		}

		&.invalid .icon-error,
		&.invalid .icon-error:hover {
			display: inline-block;
			color: $red;
			cursor: default;
		}

		&.valid .icon-check,
		&.valid .icon-check:hover {
			display: inline-block;
			color: $tba-medium-green;
			cursor: default;
		}
	}

	& fieldset.invalid {
		& .picker-trigger {
			padding: 3px;
			margin-left: -4px;
			border: 2px solid $red;
		}
		& legend {
			color: $red;
		}
		& p.error {
			visibility: visible;
		}
	}

	& .working {
		z-index: 100;
		cursor: wait;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $white;
		opacity:.80;
		border-radius: 6px;
	}

	& .container {
		position: relative;
		padding: 0;
		border: 0;
		border-radius: 6px;
		box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
		background: $white;
	}

	& + .backdrop, &::backdrop {
		background: $black;
		opacity: 0.6;
		transition: opacity 0.4s ease;
	}

	/* used animation instead of transition because FF not send transitionend event when one dialog opening and one closing */
	&.hide {
		animation-duration: 0.4s;
		animation-name: fadeout;
	}

	&.hide + .backdrop {
		opacity: 0;
	}
	/* do not merge with prev style. IE skip styles with ::backdrop selector */
	&.hide::backdrop {
		opacity: 0;
	}

	& header {
		width: 100%;
		height: 40px;
		padding: 0 10px 0 20px;
		font-weight: bold;
		font-size: 16px;
		line-height: 40px;
		border-bottom: 1px solid $tba-lighter-grey;

		& .icon {
			margin-right: 10px;
		}
	}

	& .icon-close {
		position: absolute;
		top: 14px;
		right: 12px;
		cursor: pointer;
	}

	& .dialog-buttons {
		width: 100%;
		min-height: 54px;
		padding: 0 16px;
		border-top: 1px solid $white;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		& .error-message {
			margin: 5px 0;
		}

		& button {
			margin: 12px 0 12px 12px;
			cursor: pointer;
			&.wait {
				cursor: wait;
			}
		}

		& .add-to-dashboard {
			margin: 18px 0 18px 12px;
		}

		&.dialog-error {
			& .tip {
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}

	& .heading {
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		padding: 6px;
		color: $tba-darker-grey;
	}

	& .text {
		padding: 20px;
		word-break: break-word;

		& p {
			margin: 0;
			& + p {
				margin-top: 10px;
			}
		}

		& p,
		& ul {
			font-size: 14px;
			line-height: 20px;
		}

		& li {
			word-break: break-word;
		}
		& .description {
			font-style: normal;
			letter-spacing: -.36px;
			font-size: 15px;
			margin-top: 30px;
			font-weight: 500;
		}
	}

	& .text.error {
		div {
			display: flex;
		}
		display: flex;
		color: $red;
		& .icon-error {
			flex-shrink: 0;
		}
	}
	& .icon-error {
		margin-right: 9px;
		font-size: 22px;
		color: $red;
		padding-left: 5px;
	}

	& .content {
		display: flex;
		flex-wrap: wrap;

		& th.sort .icon {
			color: $white;
			font-size: 12px;
			&:hover {
				color : $white;
			}
		}
		& h3 {
			margin: 7px 0 0 6px;
			font-weight: 900;
		}
		& .fieldset-container {
			display: flex;
			flex-wrap: wrap; /* needed for Safari */
			justify-content: space-between;
			align-items: center;
		}

		@include screen-small {
			& .fieldset-container {
				flex-wrap: nowrap; /* needed for Safari */
			}
		}

		& .inline-group .secondary {
			margin-top: 6px;

			&.small {
				height: 26px;
			}
		}

		& .inline-2-container
			.selected-label {
			max-width: 100%;
		}

		& .selectbox,
		& .selected-label {
			width: 100%; /* we cannot use flex-grow: 1 here because of a bug in IE11 */
			max-width: 236px;
		}

		@include screen-small {
			& .selectbox,
			& .selected-label {
				max-width: 248px;
			}

			& .selected-label.long {
				max-width: 520px;
			}
		}
	}

	& .panel {
		margin: 16px;
		padding: 6px 10px;
		background: $white;
		border-radius: 6px;
		width: 100%;
	}

	& .location-panel {
		width: auto !important;
	}

	& .tip {
		flex-grow: 1;
		margin-right: auto;
		margin-left: 6px;
		padding-top: 10px;
		text-align: left;
		font-style: normal;
		color: $tba-medium-grey;
	}

	& .important-note {
		font-size: 15px;
		padding: 0 6px;
		font-weight: bold;

		& a {
			color: var(--black);
			text-decoration: underline;
		}
	}

	& .dialog-body > .text,
	& .content,
	& .login-area {
		border-top: 1px solid $white;
		border-bottom: 1px solid $tba-lighter-grey;
	}

	& .table-container,
	& .table-scroll {
		overflow-y: auto;
	}

	& [hidden] {
		display: none;
	}
}

dialog.grey {
	& .container {
		background: $tba-lighter-grey;
	}

	& header {
		@include text-ellipsis;
		padding-right: 25px;
	}

	& header,
	& .content,
	& .login-area {
		border-bottom: 1px solid $tba-light-grey;
	}
}

dialog.layout-3-columns {
	width: calc(#{$column-width} + #{$gutter-width} * 2);

	.content {
		& > div {
			width: $column-width;
			margin-top: $gutter-width !important;
			margin-left: $gutter-width !important;
			margin-bottom: 0 !important;
			margin-right: 0 !important;
		}

		& > div:last-child {
			margin-bottom: $gutter-width !important;
			margin-right: $gutter-width !important;
		}

		@include screen-small {
			& .col-md-1 {
				width: $column-width;
			}

			& > div,
			& .col-md-2,
			& .col-md-3 {
				width: calc(#{$column-width} * 2 + #{$gutter-width});
			}
		}

		@include screen-medium {
			& > div {
				margin-bottom: $gutter-width !important;
			}

			& .col-lg-1 {
				width: $column-width;
			}

			& .col-lg-2 {
				width: calc(#{$column-width} * 2 + #{$gutter-width});
			}

			& > div,
			& .col-lg-3 {
				width: calc(#{$column-width} * 3 + #{$gutter-width} * 2);
			}
		}
	}

	@include screen-small {
		& {
			width: calc(#{$column-width} * 2 + #{$gutter-width} * 3);
		}
	}

	@include screen-medium {
		& {
			width: calc(#{$column-width} * 3 + #{$gutter-width} * 4);
		}
	}

	@include screen-large {
		&.additional {
			width: calc(#{$column-width} * 4 + #{$gutter-width} * 5);
		}
	}

	@include screen-extra-large {
		&.additional {
			width: calc(#{$column-width} * 5 + #{$gutter-width} * 6);
		}
	}
}

dialog.login {
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	& .icon-close {
		display: none;
	}

	.dialog-buttons .error-message {
		margin: 4px 0;
	}

	@media (max-width: 633px) {
		& {
			width: 310px;
		}
	}
}

dialog.large {
	width: auto;
	padding: 15px;
	min-width: 320px;
}

dialog.usermodel {
    display: flex;
    justify-content: center;
	width: 800px;
}
