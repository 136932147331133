@import "src/components/legacy/common/colors.scss";

#notification-settings-page {
    .page-content-wrapper {
        margin-top: 0;
    }
    .toolbar-buttons {
        button {
            margin: 0;
            min-width: 88px;
        }
        button:first-child {
            margin-right: 8px;
        }
    }
}
.notifications-wrapper {
    margin-bottom: 8px;
    .notification-header {
        background: $tba-lighter-grey;
        padding: 32px 20px;
        border-bottom: 2px solid $tba-darker-grey;
        p {
            margin: 0;
            font-size: 13px;
            a {
                text-decoration: underline;
                color: $black;
            }
            span {
                font-weight: bold;
                margin-right: 4px;
            }
            &.feedback {
                margin-bottom: 12px;
            }
        }
        .sms-input {
            display: flex;
            flex-flow: column;
            input {
                margin-left: 0;
                max-width: 240px;
            }
        }
    }
    .notification-settings {
        background: $white;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }
}

.notification-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    &:not(:last-child) {
        border-bottom: 1px solid $tba-light-grey;
    }

    .notification-description {
        padding-right: 20px;
        width: calc(100% - 250px);
        a {
            text-decoration: underline;
            color: $black;
        }
    }

    .notification-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 170px;

        .section-spinner {
            height: 26px;
            margin: 0;
            border: 1px solid $tba-dark-grey;

            .spin-wheel { font-size: 18px }
        }
    }

    .title {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.24px;
        line-height: 20px;
        margin: 0;
    }

    .text {
        font-size: 12px;
        font-weight: normal;
        min-height: 42px;
        line-height: 16px;
    }

    .link {
        color: $notification-link;
        font-size: 12px;
        font-style: italic;
        font-weight: normal;
        line-height: 16px;
        display: inline-block;
        padding-top: 10px;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media(max-width: 660px) {
    .notification-item {
        flex-direction: column;

        .notification-actions,
        .notification-description { width: 100% }

        .notification-actions { margin-top: 20px }
    }
}

.notifications-container{
    border: 1px solid $tba-light-grey;
    border-radius: 24px;
    padding: 24px;
}

.top-header-notification{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .action-button-group{
        display: flex;
        align-items: center;
        gap: 1rem;
    }

}

.notificationRow{
   display: flex;
   flex-direction: column;
   gap: .5rem;
}
