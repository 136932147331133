@import 'src/denali-components/functions.scss';

.cardDetails {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  border: 1px solid var(--amplify-components-card-details-border-color);
  border-radius: rem(38);
  max-width: 33%;
  min-width: 22%;
  padding: rem(24);

  .icon {
    background: var(--amplify-colors-black);
    color: var(--amplify-colors-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(22);
    width: rem(56);
    height: rem(56);
    margin-bottom: rem(16);

    &[data-color='blue'] {
      background: var(--amplify-colors-gradients-blue-20);
      box-shadow: var(--amplify-colors-gradients-blue-alt-shadow);
    }

    &[data-color='green'] {
      background: var(--amplify-colors-gradients-green-20);
      box-shadow: var(--amplify-colors-gradients-green-shadow);
    }

    &[data-color='orange'] {
      background: var(--amplify-colors-gradients-orange-background);
      box-shadow: var(--amplify-colors-gradients-orange-shadow);
    }

    &[data-color='gray'] {
      background: var(--amplify-colors-neutral-10);
      color: var(--amplify-colors-neutral-60);
    }

    svg {
      width: rem(24);
      height: rem(24);
    }
  }

  .title {
    color: var(--amplify-components-card-details-color);
    font-size: rem(18);
    font-weight: 500;
    line-height: rem(24);
  }

  .primary {
    display: flex;
    align-items: center;
    gap: rem(12);
    color: var(--amplify-components-card-details-color);
    font-size: rem(36);
    font-weight: 600;
    line-height: rem(48);
  }

  .disclaimer {
    color: var(--amplify-components-card-details-notes-color);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
  }

  &[data-style='energy-savings'] {
    background: var(
      --amplify-components-card-details-energy-savings-background
    );
    box-shadow: 14px 20px 30px 0px
      var(--amplify-components-card-details-energy-savings-shadow);

    .icon {
      background: var(--amplify-components-card-details-energy-savings-icon);
    }

    .title,
    .primary,
    .disclaimer {
      color: var(--amplify-colors-white);
    }
  }

  &[data-style='notes'] {
    min-width: 48%;
    max-width: unset;
    padding: rem(32);

    .icon {
      svg {
        width: rem(24);
        height: rem(24);
      }
    }

    p {
      color: var(--amplify-components-card-details-notes-color);
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(22);
      margin: rem(16) 0 rem(8);
    }
  }
}
