@import 'src/denali-components/functions.scss';

.cardHorizontal {
  overflow: hidden;
  border: 2px;
  border-radius: rem(40);
  box-sizing: border-box;
  height: 100%;
  font-size: rem(14);
  line-height: normal;
  text-align: center;

  button {
    border-radius: rem(16);
    padding: rem(16) rem(18);
    line-height: normal;
    font-size: rem(16);
    font-weight: 500;
  }

  @at-root body & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    :global(.h1),
    :global(.h2),
    :global(.h3),
    :global(.h4),
    :global(.h5),
    :global(.h6) {
      margin: rem(0) rem(0) rem(12);
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  &[data-color='lightBlue'] {
    background: var(--amplify-components-card-horizontal-light-blue-background);
    color: var(--amplify-components-card-horizontal-foreground);
    border-image-source: var(
      --amplify-components-card-horizontal-light-blue-boarder-image-source
    );
    box-shadow: var(--amplify-components-card-horizontal-light-blue-box-shadow);

    .icon {
      border-radius: rem(19.5);
      color: var(--amplify-components-card-horizontal-background);
      background: var(
        --amplify-components-card-horizontal-light-blue-icon-background
      );
      box-shadow: var(
        --amplify-components-card-horizontal-light-blue-icon-box-shadow
      );
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--amplify-components-card-horizontal-headline);
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: rem(24);
  padding: rem(60) rem(188);
  height: 100%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;

  @media (max-width: 1280px) {
    padding: rem(40) rem(100);
  }

  @media (max-width: 768px) {
    padding: rem(20) rem(40);
  }

  @media (max-width: 480px) {
    padding: rem(10) rem(20);
    font-size: rem(12);
  }
}

.media {
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(162);

  @media (max-width: 768px) {
    height: rem(100);
  }
}

.icon {
  border-radius: rem(19.5);
  width: rem(65);
  height: rem(65);
  padding: rem(16.25);
  background: var(--amplify-components-card-horizontal-icon-background);
  line-height: normal;
  font-size: rem(14);
  color: var(--amplify-components-card-horizontal-dark-foreground);

  svg {
    width: rem(32.5);
    height: rem(32.5);
  }
}
