@import "../../common/colors.scss";

.modal-text-wrapper {
	margin-bottom: 15px;
	.icon-error {
		color: #D52B23;
		position: absolute;
		right: -4px;
	}

	.has-error {
		color: #D52B23;
	}

	input.modal-text-input.has-error {
		border-width: 2px;
		border-color: #D52B23;
		background-color: #fea7aa;
	}

	.modal-text-input {
		width: 100%;
	}

	.custom-label {
		padding: 15px 0 0 15px;
	}

	.sub-title {
		padding: 0;
	}

	.section-title {
		width: 50% !important;
		margin: 0px 0px 0 15px;
	}

	.align-left {
		text-align: left;
	}

	.mini-error-text {
		font-size: 10px;
		font-style: italic;
		color: #D52B23
	}

	.tooltip-container {
		margin: 0 5px;
	}

	.units {
		font-weight: bold;
		margin-left: 5px;

		&--disabled {
			color: $tba-dark-grey;
		}
	}

	.remark {
		font-style: italic;
		margin-left: 10px;
		color: $tba-darker-grey;

		&--disabled {
			color: $tba-dark-grey;
		}
	}
}
