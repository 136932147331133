@import "../../common/colors.scss";

.video-thumbnail {
	cursor: pointer;
	width: 100%;
	height: 75px;
	margin: 15px 0;
	display: block;
	padding: 0;

	.video-thumbnail-thumbnail {
		width: 100px;
		height: 65px;
		background-position: center center;
		float: left;
		margin: 5px 15px 5px 0;
		border: 1px solid $tba-medium-grey;
		border-radius: 1px;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;

		.video-thumbnail-length {
			position: absolute;
			bottom: 0;
			right: 0;
			height: 18px;
			background: $tba-dark-grey;
			opacity: 0.6;
			color: white;
			width: 40px;
			text-align: center;
			border-radius-top-left: 5px;
			font-weight: bold;
		}
	}

	.video-thumbnail-title {
		font-size: 1.3em;
		font-weight: bold;
	}

	.video-thumbnail-description {
		color: $tba-medium-grey;
	}

	&:hover {
		.video-thumbnail-thumbnail {
			border: 1px solid $tba-darker-grey;

			.video-thumbnail-length {
				background:$tba-darker-grey;
			}
		}
	}
}
