.loaderOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: var(--amplify-colors-neutral-loader);
  pointer-events: none;

  &.visible {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }
}
