@import "../../common/colors.scss";
@import "../../common/grid.scss";


.image-gallery-slide .image-gallery-image {
	width: auto;
}

.image-gallery-slide .image-gallery-image img {
	width: 100%;
	object-fit: contain;
}

.image-gallery-wrapper .image-gallery .image-gallery-flex{
	width: 100%;
}

.image-interaction-wrapper{
	height:100%;
	div {
		height:100%;
	}
}

.image-gallery-wrapper {
	border: 1px solid $tba-light-grey;
	border-radius: 6px;
	overflow: hidden;
	.resetButton{
		
	}

	.image-gallery {
		background: $white;

		.image-gallery-content.fullscreen {
			background: $white;

			.image-gallery-slides {
				height: 100vh;
			}
			.image-gallery-flex {
				background: $black;
				width: 100%;

				.image-gallery-description {
					font-size: 16px;
				}
			}
			.image-gallery-bullets {
				display: none;
			}
			.image-gallery-play-button, .image-gallery-fullscreen-button {
				margin-bottom: 5px;
			}

		}
		.image-gallery-icon {
			color: $black2;
			filter: none;
			&:hover {
				color: rgba($black2, 0.9);
			}
		}
		.image-gallery-bullets {
			margin: 10px 0 20px;
			position: initial;
			width: 100%;
			.image-gallery-bullet {
				box-shadow: none;
				border-color: $tba-dark-grey;
				background-color: $black2;
				&.active {
					background-color: $tba-lightest2-grey;
				}
				&:hover {
					background: $black2;
				}
			}
		}
		.image-gallery-fullscreen-button, .image-gallery-play-button {
			filter: drop-shadow(0 2px 2px $black);
			height: 30px;
			margin-bottom: 13px;
			padding: 0 10px 5px;
		}
		.image-gallery-fullscreen-button .image-gallery-svg,
		.image-gallery-play-button .image-gallery-svg {
			color: $white;
			height: 30px;
			width: 30px;
		}
		.image-gallery-left-nav, .image-gallery-right-nav {
			color: $tba-darker-grey;
			padding: 50px 0;
			.image-gallery-svg {
				background-color: rgba($tba-lighter-grey, 0.81);
				height: 45px;
				width: 35px;
				padding: 0 9px;
			}
		}
		.image-gallery-slides {
			min-height: 50vh;
			display: flex;
			.image-gallery-slide {
				bottom: 0;
			}
			.center {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				position: static;
			}
		}
		.image-gallery-slide, .image-gallery-icon {
			outline: none;
		}
		.image-gallery-slide-wrapper {
			display: flex;
			flex-flow: column;
		}
		.image-gallery-flex {
			display: flex;
			flex-direction: column;
			height: 100%;

			.image-gallery-image-wrapper {
				justify-content: center;
				display: flex;
				flex: 1 1 auto;
				align-items: center;
				min-height: 80%;
				.image-gallery-image {
					max-height: 100%;
					max-width: 100%;
				}
			}
			.image-gallery-description {
				background-color: $white;
				border-top: 1px solid $tba-light2-grey;
				color: $black;
				font-size: 13px;
				text-align: left;
				position: initial;
			}
		}
		.no-image {
			flex-grow: 1;
		}

	}

	.loader {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
