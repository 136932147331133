@import "src/components/legacy/common/typography.scss";

.search-box {
	background: $tba-light-grey;
	border: 1px solid $tba-dark2-grey;
	border-radius: 2px;
	display: inline-block;
	font-size: 12px;
	height: 26px;
	line-height: 16px;
	margin-left: 0;
	padding: 3px 4px;
	position: relative;
	width: 208px;

	& input {
		@include text-search-item;
		background: $tba-lightest2-grey;
		border: 1px solid $tba-dark2-grey;
		border-radius: 9px;
		font-weight: bold;
		height: 18px;
		padding: 0 6px;
		vertical-align: top;
		width: 100%;
		&:focus {
			border-color: $tba-medium-purple;
			background: $white;
		}
	}

	& .clear {
		color: $tba-darker-grey;
		cursor: pointer;
		display: none;
		font-size: 13px;
		height: 14px;
		position: absolute;
		right: 6px;
		top: 50%;
		transform: translateY(-50%);
		width: 14px;
	}

	&.not-empty {
		& input {
			padding-right: 20px;
		}

		& .clear {
			display: block;
		}
	}

	& input:disabled {
		background-color: $tba-light-grey;
		border-color: $tba-medium-grey;
		padding-right: 6px;

		& + .clear {
			display: none;
		}
	}

	& input::placeholder {
		font-style: italic;
	}

	& input::-ms-clear {
		display: none;
	}

	&.search-box--disabled {
		border: 1px solid $tba-medium-grey;
	}
}
