@import "src/components/legacy/common/colors.scss";

.sub-notification-item {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  margin-top: 20px;
  margin-bottom: 20px;
}

.display-only {
  width: 100%;
}

.notifications-settings-dialog {
  width: 670px;

  .list{
    max-height: 730px;
    overflow-y: auto;
  }

  h2 {
    margin: 5px 0;
  }

  .rx-new-row {
    height: 10px;
  }

  .description-text {
    font-size: 12px;
    font-style: italic;

    &.grey {
      color: $notification-modal-description-grey;
    }
  }

  .delivery-method-checkbox {
    margin-top: 5px;
  }

  .modal-time-wrapper {
    margin-top: 5px;

    .custom-label {
      margin: 0;
      .time-input-label {
        span {
          display: inline-block;
          width: 55px;
        }
      }
    }

    .modal-text-input:nth-child(n+2) {
      margin-left: 10px;
    }

    .units {
      visibility: hidden;
      position: relative;
      display: inline-block;
      width: 0;
      white-space: nowrap;
      margin-top: 0;

      &:not(:last-child):after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        content: ":";
      }
    }
  }
}
