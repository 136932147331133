@import 'src/components/legacy/common/colors.scss';

// Needs to move this to style component in future, since it has to overwrite modal
// As of now can't make a styled component
.modal.automated-test-settings-edit {
  top: 100px !important;

  thead tr th {
    background-color: $tba-medium2-green;
    color: $white;
  }

  thead tr th {
    background-color: $tba-medium2-green;
    color: $white;
  }

  .tableFixHead {
    &.overflow-scroll {
      overflow: auto;
    }
    // max-height: 370px;
    box-shadow: 0px 0px 1px #646262;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .modal-panel {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background-color: #eeeeee;
    padding: 0;
  }

  .characteristic-block,
  .property-block {
    margin-top: 15px;
    overflow-y: auto;

    .ats-container {
      min-height: auto;
      margin-bottom: 10px;
    }

    .ats-title {
      font-size: 16px !important;
    }

    .ats-ch {
      display: flex;
      background: #fff;
      box-shadow: 0px 0px 1px #646262;
      margin-bottom: 10px;
    }

    .ats-no-ch {
      display: flex;
      padding: 15px;
      background: #fff;
      box-shadow: 0px 0px 1px #646262;
    }

    .btn-right {
      float: right;
    }

    .group-or-label,
    .ats-grouped-property,
    .group-container-header {
      background-color: #ffffff !important;
    }

    .group-header {
      background: rgb(238, 238, 238) !important;
    }

    .group-or-label .title,
    .group-header .title,
    .group-container-header {
      font-weight: bold;
    }

    .group-container-header td {
      padding-top: 12px;
    }
  }
}
