@import '../functions.scss';

.header {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 2;
  margin-bottom: rem(24);
}

.optionsContainer {
  flex: 1;
  display: inline-flex;
  gap: rem(12);
}

.filterMenu,
.sortMenu {
  .menuItem {
    width: 100%;
    min-width: 156px;
    padding: rem(12) rem(16);
    justify-content: flex-end;
    border-radius: rem(12);
    gap: rem(8);
    font-size: rem(14);
    cursor: pointer;

    &:hover {
      background: var(
        --amplify-components-denali-table-hover-filter-background
      );
      color: var(--amplify-components-denali-table-hover-filter-color);
    }
  }

  .menuItemLabel {
    padding-right: rem(40);
  }

  .menuIconsRow {
    width: 45px;
  }

  :global(.amplify-radio__label) {
    display: block;
    white-space: nowrap;
    padding: 0;
  }

  .sortRadio {
    padding: rem(12) rem(16);

    &.sortRadioSelected {
      background-color: var(--amplify-colors-neutral-10);
    }
  }

  .filterSubmenu {
    height: 50%;
    max-height: 400px;
    width: 280px;
  }

  .filterSubmenuScrollable {
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .scrollOverlay {
      background: var(--amplify-components-denali-table-filter-scroll);
      height: rem(80);
      inset: auto 0 0;
      pointer-events: none;
      position: fixed;
      z-index: 1;
    }
  }
}

.sort,
.filters {
  button {
    background: var(--amplify-components-denali-table-filter-background);
    color: var(--amplify-components-denali-table-filter-color);

    &:hover {
      color: var(--amplify-components-denali-table-hover-filter-button-color);
    }

    svg {
      width: rem(12);
      height: rem(12);
      margin-left: rem(6);

      path {
        fill: var(--amplify-components-denali-table-filter-color);
      }
    }
  }

  .scrollOverlay {
    background: var(--amplify-components-denali-table-filter-scroll);
    height: rem(80);
    inset: auto 0 0;
    pointer-events: none;
    position: fixed;
    z-index: 1;
  }

  .menu {
    background: var(--amplify-components-denali-table-filter-popup-background);
    border: none;
    border-radius: 30px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
    max-height: 364px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: rem(16);

    .filterOptionsWrapper {
      inset: rem(16);
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 332px;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  label {
    width: 100%;
    padding: rem(12) rem(16);
    justify-content: flex-end;
    border-radius: rem(12);
    gap: rem(8);
    font-size: rem(14);
    cursor: pointer;

    &:hover,
    &.selected {
      background: var(
        --amplify-components-denali-table-hover-filter-background
      );
      color: var(--amplify-components-denali-table-hover-filter-color);
    }
  }
}

.search,
.searchInner {
  position: relative;
  width: rem(260);

  svg {
    position: absolute;
    z-index: 2;
    top: rem(14);
    left: rem(18);
    width: rem(14);
    height: rem(14);

    path {
      fill: var(--amplify-components-denali-table-filter-color);
    }
  }

  input {
    padding-inline-start: rem(40);
  }

  input[type='search']::-webkit-search-cancel-button {
    cursor: pointer;
    filter: brightness(0) saturate(100%);
    padding: rem(1);
  }

  &.searchWithBulkActions {
    order: -1;
    margin-right: rem(12);
  }
}

.searchInner {
  margin-bottom: rem(14);

  & ~ .filterOptionsWrapper {
    max-height: 274px !important;
  }
}

.bulkActions {
  align-self: center;
  button {
    height: rem(44);
  }
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: rem(6) rem(12);

  &[data-size='small'] {
    gap: rem(4);
  }
}

.chip {
  font-size: rem(14);
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  background: var(--amplify-components-denali-table-chip-background-color);
  color: var(--amplify-components-denali-table-chip-color);
  max-width: 263px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  padding: rem(6) rem(12);
  border-radius: rem(8);
  align-items: center;

  svg:first-child {
    margin-right: rem(4);
  }

  &[data-color='blue'] {
    background: var(
      --amplify-components-denali-table-chip-blue-background-color
    );
    color: var(--amplify-components-denali-table-chip-blue-color);
  }

  &[data-color='orange'] {
    background: var(
      --amplify-components-denali-table-chip-orange-background-color
    );
    color: var(--amplify-components-denali-table-chip-orange-color);
  }

  &[data-color='purple'] {
    background: var(
      --amplify-components-denali-table-chip-purple-background-color
    );
    color: var(--amplify-components-denali-table-chip-purple-color);
  }

  &[data-color='pink'] {
    background: var(
      --amplify-components-denali-table-chip-pink-background-color
    );
    color: var(--amplify-components-denali-table-chip-pink-color);
  }

  &[data-color='green'] {
    background: var(
      --amplify-components-denali-table-chip-green-background-color
    );
    color: var(--amplify-components-denali-table-chip-green-color);
  }

  &[data-color='darkGreen'] {
    background: var(
      --amplify-components-denali-table-chip-dark-green-background-color
    );
    color: var(--amplify-components-denali-table-chip-dark-green-color);
  }

  &[data-color='black'] {
    background: var(
      --amplify-components-denali-table-chip-black-background-color
    );
    color: var(--amplify-components-denali-table-chip-black-color);
  }

  &[data-color='transparentBlack'] {
    background: var(
      --amplify-components-denali-table-chip-transparent-black-background-color
    );
    color: var(--amplify-components-denali-table-chip-transparent-black-color);
  }

  &[data-color='transparentGray'] {
    background: var(
      --amplify-components-denali-table-chip-transparent-gray-background-color
    );
    color: var(--amplify-components-denali-table-chip-transparent-gray-color);
    backdrop-filter: blur(21.200000762939453px);
  }

  &[data-color='transparentWhite'] {
    background: var(
      --amplify-components-denali-table-chip-transparent-white-background-color
    );
    border: 1px solid
      var(
        --amplify-components-denali-table-chip-transparent-white-background-color
      );
    color: var(--amplify-components-denali-table-chip-transparent-white-color);
  }

  &[data-color='transparent'] {
    background: transparent;
    color: var(--amplify-colors-neutral-50);
    padding: rem(4) rem(6);
  }

  &[data-color='gold'] {
    background: var(
      --amplify-components-denali-table-chip-gold-background-color
    );
    color: var(--amplify-components-denali-table-chip-gold-color);
  }

  &[data-color='red'] {
    background: var(
      --amplify-components-denali-table-chip-red-background-color
    );
    color: var(--amplify-components-denali-table-chip-red-color);
  }

  &[data-color='redInverse'] {
    background: var(
      --amplify-components-denali-table-chip-red-inverse-background-color
    );
    color: var(--amplify-components-denali-table-chip-red-inverse-color);
  }

  &[data-size='small'] {
    font-size: rem(12);
    line-height: normal;
    padding: rem(2) rem(6);
  }

  &[data-weight='600'] {
    font-weight: 600;
  }

  &[data-weight='500'] {
    font-weight: 500;
  }

  &[data-size='large'] {
    height: rem(32);
  }
}

.iconCell {
  display: flex;
  gap: rem(16);
  align-items: flex-start;

  .iconCellIcon {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    color: var(--amplify-colors-white);
    padding: rem(8);
    border-radius: rem(14);
    height: rem(40);
    background: var(--amplify-colors-neutral-50);

    svg {
      width: rem(24);
      height: rem(24);
    }

    &[data-size='small'] {
      height: rem(40);
      width: rem(40);

      svg {
        width: rem(18);
        height: rem(18);
      }
    }

    &[data-size='fortyFour'] {
      height: rem(44);

      svg {
        width: rem(28);
        height: rem(28);
      }
    }

    &[data-size='fortyFourAlt'] {
      height: rem(44);
      width: rem(44);

      svg {
        width: rem(22);
        height: rem(22);
      }
    }

    &[data-icon-color='purple'] {
      background: var(--amplify-colors-purple-50);
    }

    &[data-icon-color='purple-duo'] {
      background: var(--amplify-colors-purple-10);
      color: var(--amplify-colors-purple-50);
    }

    &[data-icon-color='red'] {
      background: var(--amplify-colors-red-50);
    }

    &[data-icon-color='red-duo'] {
      background: var(--amplify-colors-red-10);
      color: var(--amplify-colors-red-50);
    }

    &[data-icon-color='blue'] {
      background: var(--amplify-colors-blue-50);
    }

    &[data-icon-color='blue-duo'] {
      background: var(--amplify-colors-blue-15);
      color: var(--amplify-colors-blue-40);
    }

    &[data-icon-color='pink'] {
      background: var(--amplify-colors-pink-50);
    }

    &[data-icon-color='pink-duo'] {
      background: var(--amplify-colors-pink-10);
      color: var(--amplify-colors-pink-50);
    }

    &[data-icon-color='turquoise'] {
      background: var(--amplify-colors-turquoise-50);
    }

    &[data-icon-color='turquoise-duo'] {
      background: var(--amplify-colors-turquoise-10);
      color: var(--amplify-colors-turquoise-40);
    }

    &[data-icon-color='orange'] {
      background: var(--amplify-colors-orange-50);
    }

    &[data-icon-color='green'] {
      background: var(--amplify-colors-green-50);
    }

    &[data-icon-color='green-duo'] {
      background: var(--amplify-colors-green-10);
      color: var(--amplify-colors-green-40);
    }

    &[data-icon-color='gold-duo'] {
      background: var(--amplify-colors-gold-10);
      color: var(--amplify-colors-gold-40);
    }

    &[data-icon-color='gray-duo'] {
      background: var(--amplify-colors-neutral-10);
      color: var(--amplify-colors-neutral-50);
    }

    &[data-icon-color='gray'] {
      background: var(--amplify-colors-neutral-10);
      color: var(--amplify-colors-neutral-90);
    }
  }
}

.filter {
  ul {
    max-width: inherit !important;
    margin: 5px 0px !important;
    li {
      max-width: inherit;
      background-color: #ffffff;
      margin: 0px;
    }
  }
}

.visibilityIcon {
  display: inline-flex;
  vertical-align: middle;
  background: var(
    --amplify-components-denali-table-visibility-icon-background-color
  );
  color: var(--amplify-components-denali-table-visibility-icon-color);
  padding: rem(5);
  border-radius: 8px;
  width: rem(32);
  height: rem(32);
  justify-content: center;
  align-items: center;

  &[data-visible='off'] {
    background: var(
      --amplify-components-denali-table-visibility-icon-not-visible-background-color
    );
    color: var(
      --amplify-components-denali-table-visibility-icon-not-visible-color
    );
  }

  svg {
    width: rem(18);
    height: rem(18);
  }
}

.offsetBanner {
  background-color: var(
    --amplify-components-denali-table-offset-banner-background-color
  );
  border: 1px solid var(--amplify-components-denali-table-offset-banner-border);
  border-radius: rem(24) rem(24) 0 0;
  padding: rem(16) rem(24) rem(32);
  margin-bottom: rem(-20);
}

.tableWrapper {
  background-color: (
    var(--amplify-components-denali-table-wrapper-background-color)
  );
  border: 1px solid var(--amplify-components-denali-table-wrapper-border);
  border-radius: rem(24);
  padding: rem(24);
  position: relative;
}

.trash {
  color: var(--amplify-components-denali-table-trash-color);
  cursor: pointer;
  padding-right: rem(8);
}

.denaliTable {
  .tableList {
    overflow: auto;
  }

  .title {
    margin-bottom: rem(8);
  }

  .table {
    color: var(--amplify-components-denali-table-color);
    border: none;
  }

  &:global(.denali-table-cards) {
    thead {
      display: none;
    }

    .table {
      border-collapse: separate;
      border-spacing: 0 rem(16);

      tr,
      tr:nth-child(2n + 1),
      tr:last-child {
        td {
          color: var(--amplify-components-denali-table-color);
          font-family: var(--amplify-fonts-default-variable);
          font-size: rem(16);
          line-height: 1.25;
          font-weight: 500;
          background: none;
          border-top: 1px solid
            var(--amplify-components-denali-table-row-border-color);
          border-bottom: 1px solid
            var(--amplify-components-denali-table-row-border-color);
          padding: rem(24) rem(16);
          word-wrap: break-word;
          text-wrap: wrap;
          white-space: normal;
          max-width: none;
          overflow: visible;

          &:first-child {
            border-left: 1px solid
              var(--amplify-components-denali-table-row-border-color);
            border-top-left-radius: rem(24);
            border-bottom-left-radius: rem(24);
            padding-left: rem(32);
          }

          &:last-child {
            border-right: 1px solid
              var(--amplify-components-denali-table-row-border-color);
            border-top-right-radius: rem(24);
            border-bottom-right-radius: rem(24);
            padding-right: rem(32);
          }

          &.rowActions {
            width: 1px;
            padding-left: rem(8);
            padding-right: rem(24);

            .button {
              color: var(--amplify-components-denali-table-row-action-color);
              padding-block-start: rem(8);
              padding-block-end: rem(8);
              padding-inline-start: rem(8);
              padding-inline-end: rem(8);
            }
          }
        }

        &.skeletonTr {
          display: table-row;
        }

        td.skeletonTd:first-child {
          display: table-cell;
        }

        &.skeletonTr,
        td.skeletonTd:first-child {
          padding: 0;
          border: none;
          grid-area: auto;
        }

        @media (max-width: 1280px) {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-template-areas: 'header actions';
          border: 1px solid
            var(--amplify-components-denali-table-row-border-color);
          padding: 0 rem(32);
          margin-bottom: rem(16);
          border-radius: rem(24);

          &:hover {
            border-color: var(
              --amplify-components-denali-table-row-hover-border-color
            );
          }

          td,
          td:first-child,
          td:last-child {
            width: 100%;
            padding: rem(24) 0;
            border: none;
            border-top: none;
            border-bottom: none;
            border-radius: 0;
            border-top: 1px solid
              var(--amplify-components-denali-table-row-border-color);
          }

          td:last-child {
            grid-area: actions;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: flex-start;
          }

          td:first-child,
          td:last-child {
            border-top: none;
          }
        }
      }
    }

    .alarmNotificationsPage.table tr {
      td:first-child {
        width: rem(20px);
        padding-right: 0;
      }

      td:nth-child(2) {
        max-width: rem(300);
      }

      // In order to hide lastName and emailAddress cells
      td:nth-child(3),
      td:nth-child(4) {
        display: none;
      }

      td:last-child {
        width: rem(32px);
      }

      @media (max-width: 1280px) {
        display: flex;
        align-items: center;
        gap: rem(20px);
      }
    }

    .baselineLibraryPage.table tr {
      // In order to hide periods and exclusions and savings cells
      td:nth-child(3),
      td:nth-child(4),
      td:nth-child(5) {
        display: none;
      }

      @media (max-width: 1280px) {
        grid-template-columns: min-content;
      }

      @media (min-width: 1281px) {
        td {
          padding-bottom: rem(20);
          padding-top: rem(20);
        }

        td:first-child {
          padding-left: rem(24);
        }

        td:last-child {
          padding-left: rem(4);
        }
      }
    }

    .eventMarkersPage.table tr {
      @media (max-width: 1280px) {
        grid-template-columns: min-content;
      }
    }

    .issuesFindingsPage {
      border: none;
      border-collapse: separate;
      border-spacing: 0 rem(16);

      tr,
      tr:nth-child(2n + 1),
      tr:last-child {
        display: grid;
        grid-template-columns: 1fr 1fr min-content;
        grid-template-rows: auto auto;
        border: 1px solid
          var(--amplify-components-denali-table-row-border-color);
        padding: 0 rem(32);
        margin-bottom: rem(16);
        border-radius: rem(24);

        td,
        td:first-child,
        td:nth-child(2),
        td:last-child {
          width: 100%;
          padding: rem(24) 0;
          border: none;
          border-top: none;
          border-bottom: none;
          border-radius: 0;
          border-top: 1px solid
            var(--amplify-components-denali-table-row-border-color);
        }
        td:first-child,
        td:nth-child(2),
        td:last-child {
          border-top: none;
        }

        td {
          &:nth-child(1) {
            grid-column: span none;
            grid-row: 1;
          }
          &:nth-child(2) {
            grid-column: span none;
            grid-row: 1;
            display: flex;
            align-items: center;
          }
          &:last-child {
            grid-column: span none;
            grid-row: 1;
            display: flex;
            align-items: center;
            padding-left: rem(12);
          }
          &:nth-child(3) {
            grid-column: span 1;
            grid-row: 2;
          }
          &:nth-child(4) {
            grid-column: span 2;
            grid-row: 2;
          }
        }
      }
    }

    .notesPage {
      tr,
      tr:nth-child(2n + 1),
      tr:last-child {
        td:first-child {
          width: 50%;
        }

        @media (max-width: 1280px) {
          display: grid;
          grid-template-columns: 1fr 1fr min-content;
          grid-template-areas: 'header attachments actions';

          td:first-child {
            width: 100%;
          }

          td:nth-child(3) {
            grid-column: span 2;
            display: flex;
            justify-content: flex-end;
          }

          td:nth-last-child(2) {
            grid-area: attachments;
            border-top: none;
            padding-right: rem(12);
          }
        }
      }
    }
  }

  &:global(.denali-table-classic) {
    table {
      border: solid 1px var(--amplify-components-denali-table-row-border-color);
      border-radius: rem(20);
      font-family: var(--amplify-fonts-default);

      thead {
        tr {
          background-color: var(
            --amplify-components-denali-table-classic-header-background
          );
          border: none;
          border-radius: rem(20);
          width: 100%;

          th {
            background-color: transparent;
            border: none;
            color: var(--amplify-colors-neutral-90);
            font-family: var(--amplify-fonts-default-variable);
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(20);
            padding-right: rem(24);
            text-align: left;
            white-space: nowrap;

            &:first-of-type {
              border-radius: rem(20) 0 0;
            }

            &:last-of-type {
              border-radius: 0 rem(20) 0 0;
            }
          }
        }
      }

      tbody {
        tr {
          &:first-child {
            td {
              border-top: solid 1px
                var(--amplify-components-denali-table-row-border-color);
            }
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }

          td {
            background-color: transparent;
            border-bottom: solid 1px
              var(--amplify-components-denali-table-row-border-color);
            color: var(--amplify-colors-neutral-90);
            font-family: var(--amplify-fonts-default);
            font-size: rem(16);
            padding: rem(16);
            overflow: hidden;
            text-align: left;
            transition: background-color 0.2s;
          }
        }

        :global(.tr-clickable) {
          &:hover,
          &:focus {
            cursor: pointer;
            outline: none;

            td {
              background-color: var(--amplify-colors-neutral-10);
              border-color: var(
                --amplify-components-denali-table-row-hover-border-color
              );
            }
          }
        }
      }

      &.noControlCol {
        tbody {
          tr {
            td:last-child {
              display: none;
            }
          }
        }
      }
    }

    .noTableDataRoot {
      border-radius: 0 0 rem(20) rem(20);
    }

    ::-webkit-scrollbar {
      width: rem(8);
      height: rem(8);
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--amplify-components-denali-table-row-border-color);
      border-radius: rem(4);
    }
  }

  .table {
    tr,
    tr:nth-child(2n + 1),
    tr:last-child {
      &:global(.tr-clickable) {
        &:hover,
        &:focus {
          cursor: pointer;
          outline: none;

          td {
            border-color: var(
              --amplify-components-denali-table-row-hover-border-color
            );
          }
        }
      }
    }

    th,
    th:nth-last-child(2) /* see src/components/Table/components.js */ {
      text-align: left;
    }
  }

  .totalItems,
  .loadTime {
    color: var(--amplify-colors-neutral-50) !important;
  }

  // Inline Attachment Icons
  .inlineAttachments {
    display: flex;
    justify-content: flex-end;
    gap: 0;

    .inlineAttachmentIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--amplify-colors-neutral-10);
      border-radius: 50%;
      color: var(--amplify-colors-neutral-90);
      cursor: pointer;
      outline: solid 5px var(--amplify-colors-white);
      width: rem(36);
      height: rem(36);

      &.inlineAttachmentIconMore {
        color: var(--amplify-colors-neutral-50);
        cursor: default;
      }
    }
  }

  // Row SubData styles
  table:global(.collapsibleRowsTable) {
    thead {
      tr {
        th:first-of-type {
          border-radius: rem(20) 0 0;
          padding-left: rem(52);
        }
      }
    }

    .subDataRowSpacer {
      height: rem(75);
      padding-left: rem(38);
    }
  }

  .tableRowExpandArrow,
  .tableRowExpandArrow:active,
  .tableRowExpandArrow:focus,
  .tableRowExpandArrow:focus-visible {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: var(--amplify-colors-neutral-60);
    padding: rem(12);
  }

  .subDataRow {
    height: rem(75);
    padding-left: rem(38);
  }

  [data-sublevel='0'] {
    td:first-child {
      height: rem(75);
    }
  }

  [data-sublevel='1'] {
    td:first-child {
      height: rem(75);
      padding-left: rem(38);
    }
  }

  [data-sublevel='2'] {
    td:first-child {
      height: rem(75);
      padding-left: rem(64);
    }
  }
}

.spinner {
  animation: spin infinite 1.5s linear;
  margin-right: rem(6);
}

.skeletonRoot {
  display: flex;
  flex-direction: column;
  gap: rem(24);
  position: relative;
  overflow: hidden;
  width: 100%;

  // The "double shimmer" effect via two pseudo elements...
  // Initially had an animation applied to each row but it was extremely complicated to get the alignment right.
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: rem(180);
    height: 100%;
    background: var(--amplify-colors-white);
    filter: blur(rem(60));
    transform: skewX(-30deg);
    animation: shimmer 5s infinite linear;
    z-index: 1;
  }

  &::after {
    animation-delay: 0.2s;
  }

  .skeletonRow {
    background: var(--amplify-colors-neutral-10);
    height: rem(106);
    border-radius: rem(20);
    position: relative;
    margin-bottom: rem(20);
  }
}

.skeletonRoot[data-classic='true'] {
  margin-top: rem(24);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) skewX(-30deg);
  }
  40% {
    // Getting the exact speed was tricky. Right now 2 seconds (40% of 5s) seems just about right for the typical width of the skeleton
    transform: translateX(2000%) skewX(-30deg);
  }
  100% {
    // Stays off screen for the remaining 3s period and repeats indefinitely.
    transform: translateX(2000%) skewX(-30deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.noTableDataRoot {
  display: flex;
  flex-direction: column;
  padding: rem(120) 0;
  gap: rem(24);
  justify-content: center;
  align-items: center;
  background: var(--amplify-colors-neutral-10);
  border-radius: rem(20);

  .noTableDataTitle {
    font-size: rem(18);
    font-weight: 500;
  }

  .noTableDataIcon {
    width: rem(60);
    height: rem(60);
    color: var(--amplify-colors-neutral-30);
  }
}

.errorContainer {
  .error {
    display: inline-flex;
    align-items: center;
    background: var(--amplify-components-denali-table-error-background-color);
    border: 2px solid var(--amplify-components-denali-table-error-border-color);
    border-radius: rem(14);
    padding: rem(16);
    vertical-align: middle;
    width: 100%;

    svg {
      color: var(--amplify-components-denali-table-error-icon-color);
    }
  }

  .errorText {
    color: var(--amplify-components-denali-table-error-color);
    font-size: rem(14);
    font-style: italic;
    font-weight: bold;
    letter-spacing: 0.26px;
    line-height: rem(15);
    margin-left: rem(9);
  }
}
