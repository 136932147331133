@import 'src/denali-components/functions.scss';

.switchComponent {
  :global(.amplify-switch-track) {
    background: var(--amplify-colors-neutral-20);
    font-size: rem(16);
    padding: rem(4) rem(4) rem(4) rem(6);
  }

  :global(.amplify-switch-track--checked) {
    background: var(--amplify-components-sidebar-ui-track);
  }

  :global(.amplify-label) {
    display: flex;
    font-size: rem(14);
    line-height: normal;
    color: var(--amplify-components-sidebar-foreground);
    justify-content: space-between;
  }

  :global(.amplify-switch-label) {
    padding: 0;
  }

  :global(.amplify-switchfield) {
    width: 100%;
  }
}