@import "../../common/grid.scss";
@import "../../common/colors.scss";

dialog .inline-help-main{
	ul {
		font-weight: normal;
		font-style: normal;
		font-size: inherit;
		margin-top: 0;
	}
	.help-button-container {
		padding: 10px;
		.help-button.icon {
			width: 50px;
			font-size: 29px;
			outline: none;
			height: 50px;
			line-height: 50px;
			border-radius: 999px;
			text-align: center;
			text-decoration: none;
			box-shadow: 0 1px 2px 0 #7a869a;
			background-color: #367326;
			.help-button-contents {
				color: $white;
				font-weight: 700;
				display:inline-block;
				font-size: 29px;
				font-style: normal;
			}
		}
	}

	.fake-feedback{
		color: #fff;
		outline: none;
		height: 40px;
		border-radius: 999px;
		text-align: center;
		text-decoration: none;
		box-shadow: 0 1px 2px 0 #7a869a;
		font-weight: 700;
		cursor: pointer;
		background-color: #4C912D;
		font-size: 18px;
		white-space: nowrap;
		padding: 10px 25px;
		margin: 6px 0;
	}
	.inline-help-heading{
		font-weight: bold;
	}
	.inline-help-block{
        page-break-inside: avoid; /* Firefox */
		break-inside: avoid;
		margin-bottom: 10px;
		margin-right: 10px;
	}
	.inline-help-container{
		column-gap: 0;
	}
	.inline-help-column-1 {
		column-count: 1;
	}
	.inline-help-column-2 {
		column-count: 2;
	}
	.inline-help-column-3 {
		column-count: 3;
	}
	.video-container{
		height: 100px;
		width: 200px;
		border: 1px solid black;
	}
	.video-panel-top{
		display: flex;
		flex-wrap: nowrap;
		margin-bottom: 10px;
	}
	.video-summary{
		width: auto;
		padding: 25px;
		padding-top: 10px;
	}
	.video-title{
		font-weight: bold;
		text-transform: uppercase;
	}
	.video-title-summary{
		font-style: italic;
	}
	.further-assistance-container {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.further-assistance-text {
		margin: 6px 0;
		font-size: 14px;
		font-weight: bold;
		line-height: 1;
		b {
			margin-right: 5px;
		}
		.contact-support {
			font-weight: normal;
			a {
				cursor: pointer;
				color: $blue-2;
				text-decoration: underline;
			}
		}
	}
	h1{
		text-transform: uppercase;
		font-weight: 700;
		font-size: 21px;
		line-height: 24px;
		letter-spacing: -0.31px;
		display:block;
		margin: 0.67em 0px;
	}

	.inline-help-divider {
		height: 1px;
		background-color: $tba-light-grey;
		margin: 20px 0;
	}

	@include screen-max-extra-small {
		.inline-help-column-2,
		.inline-help-column-3 {
			column-count: 1;
		}
	}
}
