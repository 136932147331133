aside.electrical-demand-chart-legend {
  max-width: 200px;
}

.electrical-demand-line-chart-wrapper {
  > svg {
    height: calc(100% - 60px);
  }
}

.electrical-demand-time-slider{
  .tick .range-text{
    fill: black !important;
    font-size: 11px;
  }
}
