@import "src/common/scss/colors.scss";

@keyframes progress-bar-stripes {
	from  { background-position: 40px 0; }
	to    { background-position: 0 0; }
}

.progressBar {
	background-color: $tba-lightest-grey;
	height: 15px;
	border-radius: 10px;
	overflow: hidden;
	margin-top: 5px;
	margin-left: 10px;
	margin-right: 10px;

	.progressProgress {
		border-radius: 10px;
		background-image: linear-gradient(45deg, $tba-dark-grey 25%, transparent 25%, transparent 50%, $tba-dark-grey 50%, $tba-dark-grey 75%, transparent 75%, transparent);
		background-size: 40px 40px;
		background-color: $tba-light-grey;
		height: 100%;
		animation: progress-bar-stripes 2s linear infinite;
	}
}
