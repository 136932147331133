@import "../../../common/scss/colors.scss";
@import "../../../common/scss/grid.scss";

.offering-dialog.modal {
	& .modal-panel {
		background: none;
		padding: 0;
		.custom-label {
			margin-top: 6px;
		}
		& .error {
			color: $red
		}

		.view-btn-block {
			display: flex;
			justify-content: end;

			#view-btn {
				border: none;
				text-decoration: underline;
				color: $tba-darker-grey;
			}
		}
	}

	input[name=subdomain] {
		margin: 0 8px;
	}

	& .has-error {
		& .custom-label {
			color: $red
		}
		& .inline-error-message,
		& .react-picker-input-txt.hasErr {
			color: $red;
			font-size: 10px;
			font-style: italic;
			font-weight: bold;
			height: 13px;
			letter-spacing: 0.20px;
			line-height: 13px;
			width: 100%;
		}

		& input {
			background: $pink
		}

	}

	&.TSDialog {
		.datepickers {
			display: flex;
			margin-bottom: 17px;

			& > div {
				width: 191px;

				&:first-child {
					margin-right: 15px;
				}
			}
		}
	}

	&.BPEPDialog,
	&.OEDialog,
	&.BPEPApproveDialog {
		fieldset.invalid {
			.rs-toggle {
				background: $pink;
			}
			p {
				font-size: 10px;
			}
		}

		form > .error {
			margin-left: 5px;
			font-style: italic;
		}

		.building-note {
			p {
				font-weight: bold;
				display: flex;
			}
			span {
				font-style: italic;
				font-size: 13px;
				margin-right: 10px;
			}
		}

		.subscriptions {
			display: flex;
			flex-direction: column;
			align-items: stretch;

			@include screen-small {
				&  {
					flex-direction: row;
					fieldset:first-child {
						width: 67%;
					}
				}
			}
			.section-spinner {
				height: 20px;
				.icon {
					font-size: 12px;
				}
			}
		}
		.subscription-list {
			padding-bottom: 0;
		}
		.supporting-notes {
			max-height: 100px;
			display: block;
			overflow: auto;
		}
		.primary-contact {
			width: 247px;
			@include screen-small {
			}
		}
		.inline-2 {
			float: none;
			display: inline-block;
		}
		.contract-length {
			margin-top: 15px;

			@include screen-small {
				& {
					width: 34%;
				}
			}
			legend {
				margin-bottom: 3px;
			}
			.react-selectrix,
			.rs-wrapper,
			.rs-header,
			.rs-toggle {
				height: 28px;
			}
		}
		.dates-set {
			display: flex;
			align-items: unset;
			flex-direction: column;

			&.space-between {
				justify-content: space-between;
			}

			@include screen-small {
				& {
					flex-direction: row;
				}
			}
		}

		.input-tip {
			display: inline-block;
			color: $tba-darker-grey;
			font-weight: bold;
			font-style: italic;
			font-size: 12px;
			line-height: 14px;
		}

		.text-container {
			display: flex;
			flex-flow: column;
			align-items: flex-start;
			justify-content: space-between;

			.contract-ids {
				flex-flow: column;
            	width: 100%;
			}

			@include screen-small {
				& {
					flex-direction: row;

					.contract-ids {
						width: 33%;
					}

					fieldset {
						margin: 0 0 12px 0;
					}
					input {
						margin-bottom: 0;
					}
				}
			}

			.bp-ep-dialog-tip-text {
				font-size: 12px;
				line-height: 16px;
				padding: 0 8px;
				flex: 1;

				@include screen-small {
					margin-top: 15px;
				}

			}

			.has-error {
				position: relative;
				margin-bottom: 10px;

				legend {
					color: $red;
				}
				input {
					border-color: $red;
				}
			}
		}

		.contract-ids {
			display: flex;
			flex-direction: column;

			input {
				width: 100%;
				text-align: left;
			}

			@include screen-small {
				& {
					flex-direction: row;
				}
			}
		}

		.attachments {
			.doc-download {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.download-button {
				span {
					display: none;
				}
				.icon-download {
					display: block;
					color: black;
					font-size: 15px;
				}
				border: none;
				background: none;
				min-width: 0;
				padding: 10px;
			}
		}

		p {
			font-size: 13px;
			font-style: normal;
			font-weight: bold;

			&.location-details {
				margin-top: 15px;
				margin-bottom: 22px;
				padding: 0 6px;
				@include screen-small {
					margin-top: 0;
				}
			}
		}
		.buildings, .primary-contact{
			.section-spinner {
				height: 20px;
				.icon {
					font-size: 12px;
				}
			}
			@include screen-small {
				& {
					width: 66%;
				}
			}
		}

		.location-list {
			margin: 0;

			ul {
				font-size: 12px;
				font-weight: normal;
				margin: 0;
				padding: 0;
				list-style: none;
				font-style: normal;
			}
		}
		.checkbox-container {
			margin-top: 15px;
			padding: 0 6px;
			@include screen-small {
				& {
					width: 75%;
					margin-top: 0;
				}
			}
		}
		.locations-devices {
			display: flex;
			fieldset:first-child {
				width: calc(100% - 220px);
				.buildings {
					width: 100%;
				}
			}
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			.device-count {
				width: 220px;
				input {
					width: 35px;
				}
			}
			@include screen-max-extra-small {
				flex-flow: column;
				fieldset:first-child {
					width: 100%;
				}
			}
		}
	}

	&.BPEPDialog {
		.subscriptions {
			@include screen-small {
				margin-bottom: -15px;
			}
		}
	}

	&.BPEPApproveDialog {
		.subscriptions {
			margin-bottom: 10px;
		}
	}

	&.OEDialog {
		.modal-container {
			width: auto;
		}
		form .field-group {
			display: flex;
			min-height: 60px;

			.inline-3.name {
				input {
					width: 100%;
					height: 28px;
				}
			}
		}
		.select-wrapper {
			min-width: 0;
			> .control {
				width: 100%;
				position: absolute;

				.control-label {
					height: 26px;
				}
			}
			.select-dropdown {
				top: 28px;
			}
		}

		.selectrix-wrapper.selectrix-wrapper--type-autocomplete .react-selectrix .rs-header .rs-toggle {
			height: 28px;
		}

		.invalid {
			.control {
				background: $pink;
			}

			.error:not(.trane-primary-contact) {
				margin-top: 27px;
			}

			&.trane-primary-contact {
				padding-bottom: 15px;
			}
		}
		p.location-details {
				margin-top: 15px;
		}

		@include screen-max-extra-small {
			form .field-group {
				display: block;
			}
			.select-wrapper > .control {
				position: inherit;
			}
			.invalid .error:not(.trane-primary-contact) {
				margin-top: auto;
			}
		}
	}

	@include screen-max-extra-small {
		& .modal-button-row {
			justify-content: flex-end;
			padding: 0px 8px;

			& .tip {
				flex-basis: 100%;
			}

			& .modal-button.type-confirm {
				flex-basis: 56%;
			}
		}
	}
}


.cancel-contract-btn {
	width: 140px;
	color: #e7270d;
	border: none;
	background: #f5f5f5;

	.close-button {
		font-size: 10px;
		font-weight: bold;
	}
}