@import "../../common/colors.scss";

.marker-tools-bg {
  background: $tba-lighter-grey;
}
.marker-tools-active {
  background: white;
}

.marker-toolbox-button {
  svg {
    fill: $tba-darker-grey;
  }
}

.marker-tools-panel {
  background: $tba-lighter-grey;
}

.marker-image {
  max-width: 10%;
}
