// Adjust the widtha and height of the chart as per requirement.
// For testing purpose it is given as 1000 px & 300px to width and height respectively
// .engery-cost-chart-comp {
//   width: 300px;
//   height: 210px;
// }

.savings-line {
  fill: rgb(251, 143, 13);
}

.savings-text {
  font-size: 12px;
  justify-items: center;
  padding: 3px;
  > p {
    margin: 5px 0;
  }
}

.stacked-tt-item {
  text-transform: capitalize;
  > p {
    margin: 3px 0;
  }
}

.ec-tooltip {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: black;
  padding: 10px;

  p {
    margin: 5px 0;
  }

  .title {
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
  }

  .total {
    display: flex;
    justify-content: space-between;
  }

  span.value {
    text-align: right;
    flex: 1;
  }

  span.icon {
    display: inline-block;
    width: 1px;
    height: 1px;
    padding: 5px;
    margin-right: 5px;
    background: transparent;
  }

  .item {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
  }
}

.ec-tooltip-bg {
  background: #fff !important;
  -webkit-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
}
