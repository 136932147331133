.XY-chart-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1%;
}

.XY-chart-legend {
  justify-self: end;
  background-color: white;
}

// For draggable line chart
.selected-node {
  fill: steelblue;
}
