@import 'src/denali-components/functions.scss';

.cardVertical {
  height: 100%;
  border-radius: rem(30);
  background-color: var(--amplify-components-card-vertical-background);
  color: var(--amplify-components-card-vertical-foreground);
  text-align: center;
  font-size: rem(14);
  line-height: normal;
  overflow: hidden;

  button {
    font-size: rem(16);
    line-height: normal;
    font-weight: 500;
    padding: rem(16) rem(18);
    border-radius: rem(16);
  }

  @at-root body & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    :global(.h1),
    :global(.h2),
    :global(.h3),
    :global(.h4),
    :global(.h5),
    :global(.h6) {
      margin-top: rem(12);
      color: var(--amplify-components-card-vertical-headline);
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  &[data-color='darkGreen'] {
    background-color: var(
      --amplify-components-card-vertical-dark-green-background
    );
    color: var(--amplify-components-card-vertical-dark-green-foreground);

    .icon {
      color: var(--amplify-components-card-vertical-dark-green-headline);
      background: var(
        --amplify-components-card-vertical-dark-green-icon-background
      );
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--amplify-components-card-vertical-dark-green-headline);
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: rem(45);
  padding: rem(30);
  height: 100%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

.media {
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(162);
}

.icon {
  width: rem(80);
  height: rem(80);
  padding: rem(20);
  font-size: rem(34);
  line-height: normal;
  color: var(--amplify-components-card-vertical-dark-foreground);
  background: var(--amplify-components-card-vertical-icon-background);
  border-radius: rem(80);
}
