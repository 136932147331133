// print styles

@mixin hide-on-print {
	@media print {
		display: none;
	}
}

@mixin styles-on-print {
	@media print {
		@content;
	}
}