.hide-axis {
  display: none;
}

.hide-axis-line {
  path.domain {
    display: none;
  }
  g > text {
    text-transform: capitalize;
  }
}

.hide-axis-tick {
  g.tick {
    display: none;
  }
}

.hide-axis-tick-line {
  g.tick > line {
    display: none;
  }
}

.hide-axis-tick-label {
  g.tick > text {
    display: none;
  }
}

.hide-axis-tick-start {
  g.tick:first-child {
    display: none;
  }
}

.hide-axis-tick-start {
  g.tick:last-child {
    display: none;
  }
}
