.gauge-chart {
  width: 100%;
  height: calc(100% - 70px);
  flex: 1;
}

.gauge-chart-label text {
  font-size: 2em;
}

.gauge-chart-tooltip-bg {
  background: #fff !important;
  -webkit-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
}
