.t-chart-circle-center-content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.t-chart-circle-center-content .text {
  color: #fff;
}
