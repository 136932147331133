@import "src/components/legacy/common/typography.scss";
//variables
	$input-line-height: 18px;
	$textarea-rows-4: 4;
//mixins
	@mixin textarea-4 {
		height: calc(6px + 2px + #{$textarea-rows-4} * #{$input-line-height});
	}
input {
	height: 26px;
	padding: 0 8px;
	vertical-align: middle;
}
textarea {
	padding: 3px 8px;
	vertical-align: top;
	resize: none;
	@include textarea-4;
}
input,
textarea {
	background: $tba-lightest2-grey;
	border: 1px solid $tba-dark2-grey;
	border-radius: 2px;
	@include text-field-input;
	outline: none;
	&:focus {
		border-color: $tba-medium-purple;
		background: $white;
		box-shadow: 0px 1px 2px 0px $tba-light-grey;
	}
}

input:disabled,
textarea:disabled {
	background: $tba-light-grey;
	border-color: $tba-medium-grey;
	color: $tba-dark-grey;
	cursor: not-allowed;
}
input[type=number] {
	text-align: right;
}
input[type=file] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
/* for FF */
.invalid input,
.invalid textarea {
	box-shadow: none;
}
.invalid input,
.invalid textarea {
	background: $red-1;
	border: 2px solid $red;
}
