@import 'src/denali-components/functions.scss';

// Details Page
[data-amplify-theme='denali-theme'] {
  .detailSubHeader {
    display: flex;
    align-items: center;
    gap: rem(18);
    margin-bottom: rem(12);
  }

  .detailMeta {
    align-items: center;
    gap: rem(12);
  }

  .detailRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: rem(16);
    margin: rem(32) 0;
    max-width: 100%;

    &:empty {
      display: none;
    }
  }

  .detailColumn {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    margin: rem(32) 0;
    max-width: 100%;

    .detailRow {
      flex-wrap: wrap;
      margin: 0;
    }
  }

  .detailHeading {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(20);
    font-weight: 500;
    line-height: rem(26);
    text-transform: none;
  }

	.detailChart {
    display: flex;
		align-items: center;
    gap: rem(8);
    font-size: rem(16px);
    line-height: rem(22px);
    margin-top: rem(16px);

    a {
      color: var(--amplify-colors-blue-60);
    }
  }
}
