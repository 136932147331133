@import '../functions.scss';

.chartBody {
  [aria-label*='y-axis'],
  [aria-label*='x-axis'] {
    text {
      color: var(--amplify-colors-gray-60);
    }
  }

  &.noClip {
    > svg {
      overflow: visible;
    }
  }
}
