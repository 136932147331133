.contractor-company-building-wrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	.contractor-company-building-block {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.contractor-company-building-title {
		max-width: 322px;
		width: 100%;
		padding: 0 6px;
	}

	.contractor-company-selectrix-box {
		max-width: 486px;
		margin: 1px 10px 0 0;

		.ccb-description {
			margin: 4px 0;
			color: #666666;
			font-size: 11px;
			font-weight: 600;
			font-style: italic;
			letter-spacing: 0.2px;
			line-height: 12px;
		}

		.section-spinner {
			height: 27px;
			min-width: 80px;
			padding: 0;
			margin-top: 0;
			margin-bottom: 0;

			.icon {
				font-size: 20px;
			}
		}

		.selectrix-wrapper {
			width: 100%;
		}
	}

	.ccd-actions {
		display: flex;
		align-items: center;
	}

	.ccd-create-new {
		margin: 0 0 0 8px;
		color: black;
		font-size: 13px;
		font-style: italic;
		font-weight: bold;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}
