@import '../../denali-components/functions.scss';

[data-amplify-theme='denali-theme'] {
  .imageGallery {
    align-self: flex-start;
    height: rem(472);
    min-width: 50%;
    max-width: 50%;
    position: relative;

    :global(.image-gallery-content) {
      border: solid 1px var(--amplify-colors-neutral-20);
      border-radius: rem(38);
      height: 100%;
      overflow: hidden;
    }

    .imageGalleryItemWrapper {
      max-height: 472px;
    }

    img {
      object-fit: cover;
      width: unset;
      min-height: 472px;
    }

    :global(.image-gallery-slide-wrapper) {
      overflow: hidden;
      -webkit-backdrop-filter: blur(
        0
      ); // necessary to keep the description blur contained
      backdrop-filter: blur(
        0
      ); // necessary to keep the description blur contained
    }

    :global(.image-gallery-description) {
      display: flex;
      align-items: flex-end;
      flex-wrap: nowrap;
      background: var(--amplify-colors-gradients-dark-fade-up-dark);
      color: #fff;
      height: 40%;
      padding: 0 rem(30) rem(64);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .imageGalleryDescriptionWrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        gap: rem(16);
        z-index: 1;

        &:has(:only-child) {
          justify-content: flex-end;
        }
      }

      .imageGalleryDescriptionContent {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: var(--amplify-colors-white);
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(20);
        text-align: left;
      }

      .imageGalleryDescriptionTitle {
        font-size: rem(20);
        font-weight: 600;
        line-height: rem(26);
        margin-bottom: rem(8);
      }

      Button {
        background: var(--amplify-colors-neutral-60);
        font-size: rem(14);
        font-weight: 500;
        white-space: nowrap;
        align-self: flex-end;
      }
    }

    .imageGalleryBlur {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 40%;
      -webkit-mask: linear-gradient(180deg, transparent, black 100%);
      mask: linear-gradient(180deg, transparent, black 100%);

      // Removing the blur for now as it doesn't work with the mask.
      // -webkit-backdrop-filter: blur(4px);
      // backdrop-filter: blur(4px);
    }

    :global(.image-gallery-fullscreen-button) {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--amplify-colors-neutral-60);
      border-radius: rem(12);
      filter: unset;
      width: rem(40);
      height: rem(40);
      padding: rem(8);
      position: absolute;
      top: 30px;
      right: 27px;

      svg {
        border: none;
        width: rem(18);
        height: rem(18);
      }

      &:hover {
        svg {
          border: none;
          color: var(--amplify-colors-white);
          transform: unset;
        }
      }

      &::after {
        content: '';
        opacity: 0;
        transition: opacity 1s;
      }
    }

    :global(.image-gallery-bullets) {
      width: 100%;
      padding: 0 rem(30);
    }

    :global(.image-gallery-bullets-container) {
      display: flex;
      justify-content: center;
      gap: rem(8);
      padding-bottom: rem(16);
    }

    :global(.image-gallery-bullet) {
      flex-grow: 1;
      background: var(--amplify-colors-neutral-40);
      border: none;
      border-radius: 7px;
      height: 5px;
      min-width: rem(35);
      padding: unset;

      &:hover {
        background: var(--amplify-colors-neutral-60);
        border: none;
        transform: unset;
      }

      &:only-of-type {
        display: none;
      }
    }

    :global(.image-gallery-bullet.active) {
      background: var(--amplify-colors-white);
      border: none;
      transform: unset;

      &:hover {
        background: var(--amplify-colors-white);
        border: none;
        transform: unset;
      }
    }

    // No Image
    :global(.no-image) {
      min-height: 472px;
      background: var(--amplify-colors-neutral-20);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      span {
        word-break: normal;
        font-weight: 900;
        font-size: 20px;
        color: var(--amplify-colors-neutral-60);
        text-align: center;
        text-transform: uppercase;
        line-height: 30px;
      }

      & + :global(.image-gallery-description) {
        height: 10%;

        .imageGalleryDescriptionWrapper {
          justify-content: flex-end;
        }
      }
    }

    // Spinner
    .spinnerWrapper {
      background-color: var(--amplify-colors-neutral-40);
      height: 472px;
      display: flex;
      align-items: center;
      justify-content: center;

      .imageGallerySpinner {
        width: 5rem;
        height: 5rem;
      }
    }

    // Fullscreen
    :global(.image-gallery-content.fullscreen) {
      border-radius: 0;
      height: 100vh;

      :global(.image-gallery-slide-wrapper) {
        border-radius: 0;
      }

      .imageGalleryItemWrapper {
        max-height: unset;
      }

      :global(.image-gallery-image-wrapper) {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      .imageGalleryBlur {
        display: none;
      }

      :global(.image-gallery-description) {
        height: 10%;
      }

      :global(.no-image) {
        min-height: 100vh;
      }

      &:hover {
        :global(.image-gallery-fullscreen-button) {
          &::after {
            content: 'Exit full page view';
          }
        }
      }
    }

    &:hover {
      :global(.image-gallery-fullscreen-button) {
        width: rem(200);

        &::after {
          content: 'Open full page view';
          color: var(--amplify-colors-white);
          font-size: rem(14);
          font-weight: 500;
          line-height: rem(20);
          margin-left: rem(8);
          white-space: nowrap;
          opacity: 1;
        }
      }
    }
  }
}
