@import '../functions.scss';

.buildingSelectorInput {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: rem(8);
  background: var(--amplify-components-building-selector-input-background);
  border: none;
  padding: rem(12);
  border-radius: rem(16);
  text-align: left;
  margin-bottom: rem(16);
  align-items: center;
  justify-content: space-between;
  color: var(--amplify-components-sidebar-foreground);

  .inputIcon {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: rem(30);
    height: rem(30);
    padding: rem(8);
    background: var(
      --amplify-components-building-selector-input-icon-background
    );
    border-radius: rem(15);
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;

    svg {
      width: rem(14);
      height: rem(14);
    }
  }

  &:before {
    /* work-around for gradient border */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: var(--amplify-components-building-selector-input-border);
  }

  &.collapsed {
    align-items: center;
    justify-content: center;
    padding: rem(6);

    // .inputIcon {
    //   width: rem(34);
    //   height: rem(34);
    //   padding: rem(8);
    //   border-radius: rem(10);
    // }
  }

  &.selected {
    background: var(--amplify-components-building-selector-input-selected);

    .inputIcon {
      width: rem(34);
      height: rem(34);
      padding: rem(8);
      border-radius: rem(10);
      box-shadow: 0px 0px 20px 0px rgba(123, 184, 255, 0.6);
      background: var(
        --amplify-components-building-selector-input-selected-icon-background
      );
      color: var(
        --amplify-components-building-selector-input-selected-icon-foreground
      );
    }
  }
}

.placeholder {
  color: var(--amplify-components-building-selector-input-placeholder);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.inputSelectedItem {
  display: flex;
  flex-direction: column;
}

.inputSelectionName {
  font-weight: 500;
  font-size: rem(14);
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin-bottom: rem(4);
}

.inputSelectionSubTitle {
  font-size: rem(12);
  line-height: normal;
  font-weight: 500;
  color: var(--amplify-components-building-selector-selection-sub-title);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin-top: rem(4);
}

.placeholder,
.inputSelectedItem {
  flex-grow: 1;
  overflow: hidden;
}

.popupWrapper {
  /* transparent wrapper to maintain hover interactions moving mouse from root link to submenu */
  padding-left: rem(24);
}

.popup {
  background: var(--amplify-components-sidebar-background);
  border-radius: rem(30);
  padding: rem(24);
  width: rem(280);
  animation: fadeIn 200ms ease-in-out;

  a,
  button {
    padding: rem(12) rem(16);
  }

  .popupHeadline {
    font-size: rem(18);
    line-height: normal;
    font-weight: 500;
    margin-bottom: rem(16);
  }

  .popupHeadlineIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: rem(14);
    line-height: 0;
    width: rem(36);
    height: rem(36);
    padding: rem(10);
    background: var(
      --amplify-components-building-selector-headline-icon-background
    );
    color: var(--amplify-components-building-selector-headline-icon-foreground);
    border-radius: rem(36);
    margin-right: rem(8);
    vertical-align: middle;
  }

  h3.popupHeadline {
    font-size: rem(12);
    line-height: 1.33;
    font-weight: 500;
    color: var(--amplify-components-building-selector-popup-headline-color);
    margin: 0 0 rem(6);
    padding: 0;

    svg {
      margin-right: rem(6);
    }
  }

  .recentList {
    margin: 0 0 rem(16);
  }
}

.buildings,
.organizations {
  width: rem(330);
  padding: rem(16);
  background: var(
    --amplify-components-building-selector-organizations-background
  );
  color: var(--amplify-components-building-selector-organizations-foreground);

  button {
    display: flex;
    gap: rem(12);
    width: 100%;
    flex-direction: row;
    align-items: center;
    background: none;
    border: none;
    padding: rem(10);
    margin: 0 0 rem(6);
    border-radius: rem(12);
    color: var(--amplify-components-building-selector-organizations-foreground);

    &:hover,
    &:focus {
      background: var(
        --amplify-components-building-selector-organizations-focus-background
      );
    }
  }

  li:last-child > button {
    margin-bottom: 0;
  }

  .active > button {
    background: var(
      --amplify-components-building-selector-organizations-focus-background
    );
  }

  .orgWithChildren > .organizationButton {
    position: relative;
    padding-top: rem(4);
    padding-bottom: rem(4);

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: rem(24);
      top: 50%;
      bottom: 0;
      width: rem(3);
      background: var(
        --amplify-components-building-selector-organizations-child-line-color
      );
    }

    &:after {
      top: auto;
      bottom: rem(-6);
      height: rem(6);
    }
  }

  .buildingChildItem:not(.buildingLastChildItem) .buildingButtonWrapper {
    position: relative;
    padding-bottom: rem(6);

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: rem(24);
      bottom: 0;
      height: rem(6);
      width: rem(3);
      background: var(
        --amplify-components-building-selector-organizations-child-line-color
      );
    }
  }

  .buildingChildItem .buildingButton {
    padding-left: rem(54);
    margin-bottom: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: rem(24);
      top: 0;
      bottom: 0;
      width: rem(3);
      background: var(
        --amplify-components-building-selector-organizations-child-line-color
      );
    }

    &:after {
      top: calc(50% - 7px);
      bottom: auto;
      height: rem(8);
      width: rem(23);
      background-color: var(
        --amplify-components-building-selector-organizations-child-line-color
      );
      -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDI0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yIDAuNVYxQzIgMy43NjE0MiA0LjIzODU4IDYgNyA2SDI0IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg==);
      mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDI0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yIDAuNVYxQzIgMy43NjE0MiA0LjIzODU4IDYgNyA2SDI0IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg==);
    }
  }

  .buildingLastChildItem {
    .buildingButtonWrapper {
      padding-bottom: rem(6);
    }

    .buildingButton {
      &:before {
        bottom: calc(50% + 7px);
      }
    }
  }

  .active.orgWithChildren .organizationButton:before,
  .orgWithChildren .organizationButton:hover:before,
  .orgWithChildren .organizationButton:focus:before,
  .active.buildingChildItem .buildingButton:before,
  .buildingChildItem .buildingButton:hover:before,
  .buildingChildItem .buildingButton:focus:before,
  .active.buildingChildItem .buildingButton:after,
  .buildingChildItem .buildingButton:hover:after,
  .buildingChildItem .buildingButton:focus:after {
    background: var(
      --amplify-components-building-selector-organizations-child-line-color-active
    );
  }

  .details {
    flex: 1 1;
    text-align: left;
  }

  .name {
    overflow: hidden;
    max-width: 95%;
    margin: rem(-4) rem(-4);
  }

  .organizationButton .name {
    max-width: rem(222);
  }

  .buildingChildItem .name {
    max-width: rem(180);
  }

  .nameInner {
    position: relative;
    z-index: 2;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    padding: rem(4) rem(4);
  }

  .highlight {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: rem(-3);
      left: rem(-3);
      right: rem(-3);
      bottom: rem(-3);
      background: #4d3a16;
      outline: 1px solid #806124;
      border-radius: rem(6);
    }
  }

  .notHighlighted {
    position: relative;
    z-index: 2;
  }

  .count {
    margin-top: rem(8);
  }

  .address {
    font-size: rem(12);
    color: var(--amplify-components-building-selector-selection-sub-title);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    margin-top: rem(4);
  }

  .scrollContainer {
    max-height: rem(586);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.buildings {
  background: var(--amplify-components-building-selector-buildings-foreground);
  background: var(--amplify-components-building-selector-buildings-background);

  button {
    align-items: center;
    background: var(
      --amplify-components-building-selector-buildings-background
    );

    &:hover,
    &:focus {
      background: var(
        --amplify-components-building-selector-buildings-focus-background
      );
    }
  }
}

.active > .buildingButtonWrapper button {
  background: var(
    --amplify-components-building-selector-buildings-focus-background
  );
}

.searchForm {
  position: relative;
  margin-bottom: rem(16);

  input {
    width: 100%;
    padding: rem(8) rem(44) rem(8) rem(46);
    height: rem(44);
    margin: rem(0);
    border-radius: rem(44);
    background: var(--amplify-components-building-selector-search-background);
    color: var(--amplify-components-building-selector-search-foreground);
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    border: none;
    box-shadow: none;

    &:focus {
      background: var(--amplify-components-building-selector-search-background);
      color: var(--amplify-components-building-selector-search-foreground);
      border: none;
      box-shadow: none;
    }

    &::placeholder {
      color: var(--amplify-components-building-selector-search-placeholder);
    }
  }

  .searchClear {
    position: absolute;
    right: rem(16);
    width: rem(14);
    height: rem(14);
    font-size: rem(14);
    line-height: normal;
    top: 50%;
    margin: rem(-7) 0 0 0;
    padding: 0;
    color: var(--amplify-components-building-selector-search-clear-color);

    &:focus,
    &:hover {
      color: var(
        --amplify-components-building-selector-search-clear-focus-color
      );
    }
  }

  button[type='submit'] {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-top: rem(-15);
    left: rem(8);
    padding: rem(6);
    width: rem(30);
    height: rem(30);
    border-radius: rem(30);
    background: var(
      --amplify-components-building-selector-search-submit-background
    );
    color: var(--amplify-components-building-selector-search-submit-foreground);
  }
}

.buildingIcon,
.organizationIcon {
  position: relative;
  z-index: 2;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: rem(32);
  height: rem(32);
  padding: rem(6);
  border-radius: rem(8);
  justify-content: center;
  align-items: center;
  color: var(
    --amplify-components-building-selector-organizations-icon-foreground
  );
  background: var(
    --amplify-components-building-selector-organizations-icon-background
  );

  svg {
    width: rem(14);
    height: rem(14);
  }

  &[data-color='turquoise'] {
    background: var(--amplify-colors-gradients-turquoise-50);
    color: var(--amplify-colors-gradients-turquoise-inverse);
  }
  &[data-color='orange'] {
    background: var(--amplify-colors-gradients-orange-50);
    color: var(--amplify-colors-gradients-orange-inverse);
  }
  &[data-color='pink'] {
    background: var(--amplify-colors-gradients-pink-50);
    color: var(--amplify-colors-gradients-pink-inverse);
  }
  &[data-color='red'] {
    background: var(--amplify-colors-gradients-red-50);
    color: var(--amplify-colors-gradients-red-inverse);
  }
  &[data-color='green'] {
    background: var(--amplify-colors-gradients-green-50);
    color: var(--amplify-colors-gradients-green-inverse);
  }
  &[data-color='blue'] {
    background: var(--amplify-colors-gradients-blue-50);
    color: var(--amplify-colors-gradients-blue-inverse);
  }
  &[data-color='purple'] {
    background: var(--amplify-colors-gradients-altpurple-50);
    color: var(--amplify-colors-gradients-altpurple-inverse);
  }
}

.selectionDisplay {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: var(--amplify-components-building-selector-input-selected);
  border-radius: rem(24);
  padding: rem(40) rem(20) rem(20);
  margin-top: rem(20);
  margin-bottom: rem(16);

  &:before {
    /* work-around for gradient border */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: var(--amplify-components-building-selector-input-border);
  }

  & > button {
    position: relative;
    background: none;
    padding: rem(6);
    margin: rem(-6) rem(-6);
    border-radius: rem(12);
    border: none;
    display: block;
    text-align: left;
    width: calc(100% + rem(12));
    overflow: hidden;

    &:hover,
    &:focus {
      background: var(--amplify-components-sidebar-active-background);
    }

    &.orgDisplay {
      color: var(
        --amplify-components-building-selector-organizations-foreground
      );
      margin-bottom: rem(6);
    }
  }

  .floatingIconIndicator {
    .buildingIcon,
    .organizationIcon {
      position: absolute;
      top: rem(-20);
      left: rem(20);
      width: rem(48);
      height: rem(48);
      padding: rem(12);
      box-shadow: 0px 0px 20px 0px rgba(123, 184, 255, 0.6);

      svg {
        width: rem(24);
        height: rem(24);
      }
    }
  }

  &.collapsed {
    padding: rem(7);
    border-radius: rem(16);
    align-items: center;
    justify-content: center;
    gap: rem(6);
    margin: 0 0 rem(16);

    .buildingIcon,
    .organizationIcon {
      width: rem(36);
      height: rem(36);
      padding: rem(8);

      svg {
        width: rem(20);
        height: rem(20);
      }
    }

    & > button {
      margin: 0;
      padding: 0;
      border-radius: 0;
      width: auto;
    }
  }

  p,
  .buildingChips {
    margin: 0 0 rem(4);
  }

  .label {
    font-size: rem(12);
    line-height: normal;
  }
}
