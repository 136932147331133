@charset "UTF-8";
@import "./emerald-icons.scss";


[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "Emerald" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@each $element, $icon in $icons {
	.icon-#{$element}::before {
		content: $icon;
	}
}

.icon.icon-loader.icon-loader--spin {
	display: inline-block;
	animation: rotate 1.2s infinite linear;
}
