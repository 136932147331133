@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/typography.scss";

.datepicker-input-react {
	align-items: stretch;
	border: 1px solid $tba-dark-grey;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	position: relative;

	.react-picker-input {
		border: 0;
		border-right: 1px solid $tba-dark-grey;
		box-sizing: border-box;
		flex: 1 1 auto;

		.datepicker-input {
			background: $tba-lightest-grey;
			border-radius: 0;
			border: 0;
			font-size: 12px;
			font-weight: bold;
			height: 26px;
			line-height: 18px;
			outline: none;
			padding: 0 8px;
			vertical-align: middle;
			width: 100%;

			&.hasErr {
				background-color: $pink;
				border-color: $red;
				border-width: 2px;
			}
		}
	}

	.datepicker-react {
		position: static;

		.datepicker-calendar {
			&.left {
				left: 0;
				right: auto;
				top: 105%;
			}

			&.right {
				left: auto;
				right: 0;
				top: 105%;
			}

			&.top {
				bottom: 43px;
				top: auto;
			}
		}

		.icon-calendar {
			color: $white;
			font-size: 18px;
		}
	}

	&--disabled {
		border-color: $tba-medium-grey;

		.react-picker-input {
			@include text-field-input-inactive;
			border-right-color: $tba-medium-grey;
		}

		.datepicker-react {
			background: $tba-dark-grey;

			.icon-calendar {
				color: $tba-light-grey;
			}
		}
	}
}

.react-picker-input-txt.hasErr {
	color: $red;
	display: inline-block;
	font-size: 12px;
	font-style: italic;
	font-weight: 700;
	letter-spacing: 0.36px;
	line-height: 14px;
	position: relative;
}
