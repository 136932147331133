@import '../functions.scss';

.banner {
  margin: 0 rem(-30) 0 rem(-20);
  padding: rem(16) rem(60);
  background: var(--amplify-colors-neutral-10);

  &[data-background='invert'] {
    background: var(--amplify-components-page-banner-invert);
  }

  .content {
    display: flex;
    column-gap: rem(8);
    align-items: baseline;
    font-weight: 500;
    line-height: rem(18);
    color: var(--amplify-colors-neutral-90);

    .icon {
      padding: 0 rem(3);
      svg path {
        fill: url(#linear-gradient);
      }
    }

    p {
      margin: rem(1) 0;
    }

    a {
      margin-left: auto;
      color: var(--amplify-colors-neutral-90);

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .links {
    flex: 1 1 auto;
    align-self: flex-end;

    .betaUser {
      background: var(--amplify-colors-gradients-purple-text);
      background-clip: text;
      color: transparent;
      text-decoration-color: var(--amplify-colors-gradients-purple-decoration);
    }

    a {
      margin: 0;
    }
  }
}
