.vimeoEmbed {
  &[data-style='backgroundCoverLoop'] {
    position: absolute;
    top: -0.1%;
    left: 50%;
    z-index: 1;
    aspect-ratio: 16/9;
    min-width: 100%;
    min-height: 100.2%; /* prevent rounding errors creating a border */
    background: #000;

    iframe {
      position: absolute;
      top: 0;
      left: -50%;
      width: 100%;
      height: 100%;
      border: none;
      background: var(--amplify-colors-black);
    }
  }

  &[data-style='backgroundCoverPreview'] {
    iframe {
      width: 100%;
      height: 100%;
      border: none;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}
