@import "../../common/typography.scss";

//variables
$width: 54px;
$height: 27px;
$borderRadius: 14px;
$circleRadius: 23px;

.toggle-horizontal {
	display: inline-block;

	& label {
		display: flex;
		align-items: center;

		/* slider  with plate */
		& div {
			display: inline-block;
			box-sizing: content-box;
			margin: 0px 10px 0px 10px;
			width: $width;
			height: $height;
			border: 1px solid $tba-dark2-grey;
			background-color: $tba-medium-grey;
			border-radius: $borderRadius;
			cursor: pointer;
			transition: 0.4s;

			&:after {
				content: "";
				display: block;
				width: calc(#{$width} / 2);
				height: $height;
				border-radius: $circleRadius;
				background-color: $tba-lightest-grey;
				transition: 0.4s;
			}
		}

		/* text titles */
		& span {
			@include text-field-label;
			color: $tba-darker-grey;
			transition: 0.4s;
		}
	}

	& input {
		display: none;
	}

	/* active state */
	& input:checked + div {
		background-color: $tba-green;

		&:after {
			transform: translateX(100%);
		}

		& + span {
			color: $tba-green;
		}
	}

	/* disabled state */

	& input[disabled] + div {
		background-color: $tba-light-grey;
		border-color: $tba-light-grey;
		cursor: not-allowed;

		&:after {
			background-color: $tba-lighter-grey;
		}

		& + span {
			color: $tba-light-grey;
		}
	}

}
