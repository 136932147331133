@import '../functions.scss';

[data-amplify-theme='denali-theme'] {
  .denaliTabs {
    border-bottom: solid 1px var(--amplify-components-denali-tabs-border);
    gap: rem(12);
    margin-bottom: rem(24);
    padding: 0 0 rem(24);
  }

  .denaliTabItem {
    border: solid 2px var(--amplify-components-denali-tabs-tab-border);
    border-radius: rem(54);
    color: var(--amplify-components-denali-tabs-tab-color);
    height: rem(44);
    min-width: rem(140);
    width: auto;
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    padding: rem(12) rem(24);
    position: relative;
    white-space: nowrap;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: rem(6);
    }

    &[data-state='active'] {
      background: var(
        --amplify-components-denali-tabs-tab-active-background-color
      );
      border: none;
      color: var(--amplify-components-denali-tabs-tab-active-color);

      &::after {
        /* work-around for gradient border */
        content: '';
        background: var(
          --amplify-components-denali-tabs-tab-active-background-inset
        );
        border-radius: inherit;
        inset: 2px;
        position: absolute;
        z-index: -1;
      }
    }
  }

  .denaliDialogTabs {
    gap: rem(12);
    border-bottom: none;
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    margin-bottom: rem(24);
  }

  .tabHeader {
    border-bottom: solid 1px var(--amplify-components-denali-tabs-border);
    font-size: rem(22);
    font-weight: 600;
    line-height: rem(28);
    padding-bottom: rem(8);
  }

  .tabContentHeading {
    font-size: rem(18);
    font-weight: 500;
    line-height: rem(22);
    margin: rem(8) 0 rem(2);
  }
}
