@import '../functions.scss';

[data-amplify-theme='denali-theme'] {
  .equipmentSelector {
    border-radius: rem(12);

    &.equipmentSelectorError {
      border: 1px solid var(--amplify-colors-red-50);
    }

    &.equipmentSelectorSelected {
      .equipmentSelectorControlImage {
        display: none;
      }
    }

    .equipmentSelectorSelectedEquipment {
      font-weight: 500;
      padding: rem(12) rem(16);
      margin: 0;
    }

    .equipmentSelectorMultiValueWrapper {
      border-radius: var(--amplify-components-form-chip-border-radius);
      height: rem(28);

      svg {
        height: rem(15);
      }
    }

    .equipmentSelectorMultiValueRemoveWrapper {
    }

    .equipmentSelectorMultiValueLabelWrapper {
      svg {
        margin-left: rem(5);
      }
    }

    .equipmentSelectorControlImage {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--amplify-colors-blue-lightest);
      border-radius: rem(12);
      padding: rem(8);
      height: 36px;
      width: 36px;
    }

    .equipmentSelectorControlChildren {
      flex: 1 1 100%;
      padding: rem(8) 0;
    }

    .equipmentSelectorControlWrapper {
      min-height: 70px;
      padding: 0 rem(20);
    }

    .equipmentSelectorPlaceholder {
      * {
        margin: 0;
        padding: 0;
        color: var(--amplify-colors-neutral-90);
      }

      h6 {
        font-size: rem(14);
        font-weight: 500;
        line-height: 20px;
        text-transform: none;
        margin-bottom: rem(4);
      }

      p {
        font-size: rem(12);
        font-weight: 400;
        line-height: 16px;
        color: var(--amplify-colors-neutral-50);
      }
    }

    [class*='ValueContainer'] {
      padding: rem(0);
    }

    [class*='placeholder'] {
      padding: rem(8);
    }

    :global(.useColorClass) {
      &[data-icon-color='purple'] {
        background: var(--amplify-colors-purple-10);

        * {
          color: var(--amplify-colors-purple-50);
        }
      }

      &[data-icon-color='red'] {
        background: var(--amplify-colors-red-10);

        * {
          color: var(--amplify-colors-red-50);
        }
      }

      &[data-icon-color='blue'] {
        background: var(--amplify-colors-blue-15);

        * {
          color: var(--amplify-colors-blue-50);
        }
      }

      &[data-icon-color='pink'] {
        background: var(--amplify-colors-pink-10);

        * {
          color: var(--amplify-colors-pink-50);
        }
      }

      &[data-icon-color='turquoise'] {
        background: var(--amplify-colors-turquoise-10);

        * {
          color: var(--amplify-colors-turquoise-40);
        }
      }

      &[data-icon-color='orange'] {
        background: var(--amplify-colors-orange-10);

        * {
          color: var(--amplify-colors-orange-50);
          font-weight: 500;
        }
      }

      &[data-icon-color='green'] {
        background: var(--amplify-colors-green-10);

        * {
          color: var(--amplify-colors-green-50);
        }
      }

      &[data-icon-color='gold'] {
        background: var(--amplify-colors-gold-10);

        * {
          color: var(--amplify-colors-gold-50);
        }
      }

      &[data-icon-color='gray'] {
        background: var(--amplify-colors-neutral-10);

        * {
          color: var(--amplify-colors-neutral-50);
        }
      }
    }
  }
}
