@import 'src/denali-components/functions.scss';

.cardVideo {
  overflow: hidden;
  box-shadow: var(--amplify-components-card-video-box-shadow);
  border-radius: rem(30);
  height: auto;
  padding: rem(24);
  background-color: var(--amplify-components-card-video-background);

  p {
    color: var(--amplify-components-card-video-foreground);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: rem(24);
  flex: 1 0 0;

  @media (max-width: 1280px) {
    gap: rem(16);
  }
}

.image {
  border-radius: rem(20);
  width: 100%;
  max-height: 200px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  aspect-ratio: 347 / 200;
}

.tags {
  display: flex;
  justify-content: flex-end;
  padding: rem(16);
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  gap: rem(10);
  width: 100%;
}

.icon {
  border-radius: 50%;
  width: rem(36);
  height: rem(36);
  padding: rem(8);
  backdrop-filter: blur(21.2px);
  background: var(--amplify-components-card-video-icon-background);
  text-align: center;
  color: var(--amplify-components-card-video-icon-foreground);

  svg {
    padding: rem(4);
    width: rem(14);
    height: rem(14);
  }
}
