@import 'src/denali-components/functions.scss';

.cardImpact {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: rem(22);
  max-width: 23.5%;
  padding: rem(18) rem(22);
  width: 23.5%;

  &[data-count='multiRow'] {
    max-width: 32.25%;
    width: 32.25%;
  }

  .impact {
    font-size: rem(20);
    font-weight: 600;
    line-height: rem(26);
  }

  .type {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(16);
    font-weight: 400;
    line-height: rem(22);
  }

  .icon {
    width: rem(48);
    height: rem(48);
    border-radius: rem(16);
    margin-bottom: rem(10);
    color: var(--amplify-colors-white);

    svg {
      width: rem(24);
      height: rem(24);
    }
  }

  &[data-impact='low'] {
    background: var(--amplify-colors-gold-50);
    color: var(--amplify-colors-gold-100);

    .icon {
      background: var(--amplify-colors-gold-100);
    }
  }

  &[data-impact='medium'] {
    background: var(--amplify-colors-orange-lightest);
    color: var(--amplify-colors-orange-50);

    .icon {
      background: var(--amplify-colors-orange-50);
    }
  }

  &[data-impact='high'] {
    background: var(--amplify-colors-red-lightest);
    color: var(--amplify-colors-red-50);

    .icon {
      background: var(--amplify-colors-red-50);
    }
  }

  &[data-impact='specificValue'] {
    background: var(--amplify-colors-blue-lightest);
    color: var(--amplify-colors-blue-50);

    .icon {
      background: var(--amplify-colors-blue-50);
    }
  }
}
