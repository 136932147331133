.multipleBuildingName {
    max-width: 95px;
    overflow: hidden;
    white-space: nowrap;
}

.multipleEquipmentName {
    max-width: 95px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 3px;
}