@import "../../common/colors.scss";

/* { componentName } is a prop passed into Tooltip component, so each use case can create a unique class, if needed, to have custom styles */
/* NAMING CONVENTION: "{ filename of component/class in which Tooltip is being rendered }-tooltip-anchor" */
/* { this extension will be added to whatever name is passed in as the { componentName } prop and set as the parent element class } */
/* No default styles/class defined at this time, due to the uniqueness of each instance's context and style needs */

.tooltip-anchor {
    position: relative;

    .tooltip-base,
    +.tooltip-base {
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.24px;
        font-family: "Archivo Narrow";
        box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
        left: 50%;
        transform: translate(-50%, 0);
        &.left {
            transform: translate(-10%, 0);
        }
        &.right {
            transform: translate(-90%, 0);
        }

        /* TOOLTIP { type } PROP will define the class (and thereby styles) for the Tooltip element
           ****not to be confused with the anchor element styles, defined below */

        /* Tooltip { type } = TOOLTIP_TYPE_ICON styles */
        &.icon {
            background: $tba-darker-grey;
            bottom: 100%;
            box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
            position: absolute;
            padding: 6px 10px;
            border-radius: 4px;
            min-height: 24px;
            color: $white;
            display: none;
        }

        /* Tooltip { type } = TOOLTIP_TYPE_DEFAULT styles */
        &.default {
            background: $tba-lightest-grey;
            bottom: 100%;
            border: 1px solid $tba-dark-grey;
            padding: 10px;
            border-radius: 4px;
            position: absolute;
            display: none;
            height: auto;
            width: 250px;
            margin-left: auto;
            margin-right: auto;
            white-space: normal;
            z-index: 9;
        }
        &.custom-width {
            background: $tba-lightest-grey;
            bottom: 100%;
            border: 1px solid $tba-dark-grey;
            padding: 10px;
            border-radius: 4px;
            position: absolute;
            display: none;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            white-space: normal;
            z-index: 9;
        }

        &.top {
            background: $tba-lightest-grey;
            border: 1px solid $tba-dark-grey;
            padding: 10px;
            border-radius: 4px;
            position: absolute;
            display: none;
            height: auto;
            width: 250px;
            margin-left: auto;
            margin-right: auto;
            white-space: normal;
            z-index: 9;
            top: 150%;
        }

        &.responsiveControls {
            background: $tba-lightest-grey;
            bottom: 100%;
            border: 1px solid $tba-dark-grey;
            padding: 10px;
            border-radius: 4px;
            position: absolute;
            display: none;
            height: auto;
            // width: 250px;
            margin-left: auto;
            margin-right: auto;
            white-space: normal;
            z-index: 9;
        }

        .tooltip-arrow {
            position: absolute;
            box-sizing: border-box;
            box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
            transform: translate(-50%, -50%);
            left: 50%;

            &.left {
                left: 10%;
            }

            &.right {
                left: 90%;
            }

            /* Tooltip { type } = TOOLTIP_TYPE_ICON styles */
            &.icon {
                bottom: 0;
                &::after {
                    content: "";
                    position: absolute;
                    border: 5px solid black;
                    box-sizing: border-box;
                    transform-origin: 0 0;
                    transform: rotate(-45deg) translate(-50%, -50%);
                    border-color: transparent transparent $tba-darker-grey $tba-darker-grey;
                }

                &::before {
                    border-color: transparent transparent $tba-dark-grey $tba-dark-grey;
                }
            }

            /* Tooltip { type } = TOOLTIP_TYPE_DEFAULT styles */
            &.default {
                bottom: 0;
                &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-left: 1px solid $tba-dark-grey;
                    border-bottom: 1px solid $tba-dark-grey;
                    position: absolute;
                    background-color: $tba-lightest-grey;
                    box-sizing: border-box;
                    transform-origin: 0 0;
                    transform: rotate(-45deg) translate(-50%, -50%);
                }
            }

            &.top {
                bottom: 100%;
                &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-left: 1px solid $tba-dark-grey;
                    border-bottom: 1px solid $tba-dark-grey;
                    position: absolute;
                    background-color: $tba-lightest-grey;
                    box-sizing: border-box;
                    transform-origin: 0 0;
                    transform: rotate(-225deg) translate(-50%, -50%);
                }
            }
            &.responsiveControls {
                bottom: 0;
                &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-left: 1px solid $tba-dark-grey;
                    border-bottom: 1px solid $tba-dark-grey;
                    position: absolute;
                    background-color: $tba-lightest-grey;
                    box-sizing: border-box;
                    transform-origin: 0 0;
                    transform: rotate(-45deg) translate(-50%, -50%);
                }
            }
        }
    }

    &:hover {
        .tooltip-base,
        +.tooltip-base {
            display: inline-block;
        }
    }
}

/* DEFINE/ADD COMPONENT-SPECIFIC CUSTOM STYLES BELOW */

/* GRID-SUMMARY-EVENTS-TOOLTIP STYLES */
.grid-summary-events-tooltip-anchor {
    display: inline-block;
    z-index: 3;
    width: 38px;
    text-align: center;

    .tooltip-base {
        left: 80%;
        bottom: 130%;
        transform: translate(-50%, 0);
    }

    .tooltip-arrow {
        left: 33%;
    }
}

/* GRID-SUMMARY-LIST-OFFERS-TOOLTIP STYLES */
.grid-summary-list-offers-tooltip-anchor {
    display: inline-block;
    z-index: 3;
    width: 38px;
    text-align: center;

    .tooltip-base {
        left: 80%;
        bottom: 130%;
        transform: translate(-50%, 0);
    }

    .tooltip-arrow {
        left: 33%;
    }
}

/* DEVICE-EDIT-BUTTON-TOOLTIP STYLES */
.device-edit-button-tooltip-anchor {
    display: block;
    z-index: 3;
    width: 38px;
    text-align: center;
    float: right;
    clear: both;

    .tooltip-base {
        bottom: 130%;
    }
}

/* OFFER-STATUS-INDICATOR-TOOLTIP STYLES */
.offer-status-indicator-tooltip-anchor {
    display: inline;
    z-index: 3;
    text-align: center;

    .tooltip-base {
        bottom: 130%;
    };
}

.offer-status-indicator-new-tooltip-anchor {
    display: inline;
    z-index: 3;
    text-align: left;
    color: #333333;
    font-weight: normal;
    text-transform: none;
}

/* OFFER-STATUS-INDICATOR-ERROR-TOOLTIP STYLES */
.offer-status-indicator-error-tooltip-anchor {
    display: inline;
    z-index: 3;
    text-align: center;

    .tooltip-base {
        bottom: 130%;
    }
}

/* DAYAHEAD-TABLE-COLUMN-TOOLTIP STYLES */
.dayahead-table-column-tooltip-anchor {
    position: absolute;
    top: 10px;
    left: 15px;
    text-align: left;

    .tooltip-base {
        left: 80%;
        bottom: 130%;
        z-index: 9;
    }

    .tooltip-arrow {
        left: 48.5%;
    }
}

/* SCENARIO-TABLE-TOOLTIP STYLES */
.scenario-table-tooltip-anchor {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 200px;
    padding-left: 16px;

    .tooltip-base {
        width: 200px;
        margin-left: 20%;
        margin-right: none;
    }

    .tooltip-arrow {
        left: 72%;
    }

}

/* STORY-TOOLTIP STYLES */
.story-tooltip-anchor {
    display: inline;
    z-index: 3;
    text-align: center;
}

/* TAGS-TOOLTIP STYLES */
.tags-wrapper-tooltip-anchor, .tags-open-wrapper-tooltip-anchor {
	display: flex;
	.tooltip-base.default{
		width: max-content;
		max-width: 250px;
		margin-bottom: 5px;
	}

	&:hover {
		.tooltip-base {
			display: none;
		}
	}
}

.tags-open-wrapper-tooltip-anchor {
	.tooltip-base.default {
		display: inline-block;
	}
}

