.t-chart-tooltip-foreignObject {
  transition: all 0.25s;
}

.t-chart-tooltip-wrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  font-size: 12px;
}

.t-chart-tooltip-content-area {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 2px 7px;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  background-color: rgb(255, 255, 255);

  border-radius: 3px;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  overflow-y: auto;
  padding: 10px;
  pointer-events: visible;
  position: -webkit-sticky;
  position: sticky;
  width: fit-content;
  pointer-events: none;
}

.t-chart-tooltip-content-item {
  color: rgba(121, 121, 121, 1);
  font-weight: 600;
  display: flex;
  font-size: 1em;
  gap: 15px;
  line-height: 8px;
  // text-shadow: 0 0px 0px rgba(0, 0, 0, 0.55);

  &:last-child p {
    margin-bottom: 5px;
  }

  &:first-child p {
    margin-top: 5px;
  }

  &.title {
    border-bottom: 1px solid rgba(121, 121, 121, 1);
    text-shadow: 0 0px 0px rgba(121, 121, 121, 1);
    color: rgba(0, 0, 0, 0.2);
  }

  p {
    display: flex;
    flex: 1;
    gap: 5px;
    margin: 8px 0;
  }

  .icon {
    display: inline-block;
    width: 0.74em;
    height: 0.74em;
    background: steelblue;
  }

  .label {
    flex: 1;
    // color: #e6e2e2;
    font-weight: 600;
  }
  .value {
    text-align: left;
  }
}

.t-chart-tooltip-arrow {
  width: 0;
  height: 0;
  position: absolute;
}

.t-chart-tooltip-arrow-right {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.5);
}

.t-chart-tooltip-arrow-left {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgba(0, 0, 0, 0.5);
}
