@import '../functions';

[data-amplify-theme='denali-theme'] {
  .selectionWrapper {
    padding: rem(0);
  }

  .selectionWrapperSelectedItemsWrapper {
    padding: rem(20);
    border: rem(1) solid var(--amplify-colors-neutral-20);
    border-radius: rem(20);
  }

  .selectionWrapperSelectedItem {
    max-width: rem(141);
    height: rem(28);
    border-radius: rem(8);
    padding: rem(4) rem(8);
    background-color: var(--amplify-colors-neutral-10);

    svg {
      cursor: pointer;
    }
  }

  .selectionWrapperSelectedItemLabel {
    display: block;
    max-width: rem(99);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin: rem(0);
  }

  .selectionWrapperSelectedItemsHeader {
    font-size: rem(16);
    font-weight: 500;
    margin: rem(0);
  }
}
