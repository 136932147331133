.what-we-saved-chart {
  max-width: 100%;
  width: 83vw;
  height: 300px;
}

.savings-line {
  fill: rgb(251, 143, 13);
}

.savings-text {
  font-size: 12px;
  justify-items: center;
  padding: 3px;
  > p {
    margin: 5px 0;
  }
}

.stacked-tt-item {
  text-transform: capitalize;
  > p {
    margin: 3px 0;
  }
}

aside.what-we-saved-chart-legend {
  max-width: 200px;
}
