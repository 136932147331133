// Common tooltip style for ToopTip that are used from Chart Tooltip component
.action-tracking-tooltip {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  color: black;
  padding: 10px;

  p {
    margin: 5px 0;
  }
  a {
    color: #000;
    text-decoration: underline
  }

  .title {
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
  }

  .total {
    display: flex;
    justify-content: space-between;
  }

  span.value {
    text-align: right;
    flex: 1;
  }

  span.icon {
    display: inline-block;
    width: 1px;
    height: 1px;
    padding: 5px;
    margin-right: 5px;
    background: transparent;
  }

  .item {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
  }
  .name {
    width: 121px !important;
    word-wrap: break-word !important;
  }
}

.action-tracking-tooltip-bg {
  background: #f4f4f4 !important;
  -webkit-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
}
