@import '../../functions.scss';

.barChartControlsLeft {
  flex: 0 0 auto;
  align-self: flex-start;
  background-color: var(--amplify-colors-neutral-10);
  border-radius: rem(12);

  [class*='amplify-button'] {
    &[class*='amplify-button--small'] {
      border-radius: rem(12);
    }
  }
}

.barChartControlsRight {
  flex: 0 0 auto;
  align-self: flex-end;

  > [class*='amplify-button'] {
    &[class*='amplify-button--small'] {
      background-color: var(--amplify-colors-neutral-10);
      border-radius: rem(12);
      color: var(--amplify-colors-black-100);
    }
  }
}

.barChartControlsDisplayToggle {
  background-color: var(--amplify-colors-neutral-10);
  border-radius: rem(12);
  margin-left: rem(8);

  [class*='amplify-button'] {
    &[class*='amplify-button--small'] {
      height: 40px;
      border-radius: rem(12);
      padding: rem(8) rem(20);
    }
  }
}

.barChartControlsMenuToggle {
  padding: rem(8) rem(20);
}
