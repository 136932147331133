@import '../../denali-components/functions.scss';

.collapsible {
  padding: rem(20);
  height: 100%;
  border: var(--amplify-components-collapsible-border);
  border-radius: rem(20);
  align-content: center;

  p,
  h6 {
    margin: 0;
  }

  &.collapsed {
    height: var(--amplify-components-collapsible-height-default);
    transition: height 200ms ease-out;

    &.tall {
      height: var(--amplify-components-collapsible-height-tall);
    }

    .collapsibleChildren {
      height: 0px;
      opacity: 0;
      visibility: collapse;
      transition: opacity 100ms ease-in, height 50ms ease-in-out 100ms,
        visibility 100ms ease-in-out 100ms;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerContainer {
      display: flex;
      align-items: center;
      transition: all 300ms ease-in-out;
      gap: 25px;

      .header {
        font-weight: 700;
      }
    }

    .toggle {
      display: inline-block;
      align-self: center;

      :global(.amplify-switch-track--checked) {
        background: var(--amplify-components-sidebar-ui-track);
      }
    }
  }

  .collapsibleChildren {
    height: auto;
    opacity: 1;
    visibility: visible;
    transition: height 300ms ease-in-out, opacity 200ms ease-in 100ms;
    padding: 20px;
  }
}
