@charset "UTF-8";

$icons: (
	cancel-stop: "\e9c2",
	boiler: "\e9c3",
	duration: "\e9c7",
	heat-exchanger: "\e9c4",
	heating-system: "\e9c5",
	heating-plant: "\e9c6",
	tags: "\e9c8",
	indicator-arrow: "\e9c1",
	building-management: "\e9c0",
	history: "\e9bf",
	compliance: "\e9ba",
	comfort: "\e9bb",
	reliability: "\e9bc",
	performance: "\e9bd",
	energy-roi: "\e9be",
	save: "\e9b8",
	feedback: "\e9b9",
	feedback-circle: "\e9cb",
	profile:"\e9d5",
	globe: "\e9d4",
	alarm-bell-active2: "\e9a2",
	help2: "\e9a3",
	bulb-inactive: "\e9a4",
	thermostat3: "\e9a5",
	thermostat2: "\e9a6",
	thermostat1: "\e9a7",
	alarm-bell-active1: "\e9a8",
	advisory-alert: "\e9a9",
	snowflake: "\e9aa",
	bolt: "\e9ab",
	fire: "\e9ac",
	water2: "\e9ad",
	infocircle2: "\e9ae",
	critical-alert: "\e9af",
	alarm-bell: "\e9b0",
	battery-exterior: "\e9b1",
	battery-interior: "\e9b2",
	bulb-active: "\e9b3",
	first-aid: "\e9b4",
	lock-open: "\e9b5",
	lock-closed: "\e9b6",
	piggy-bank: "\e9b7",
	status-right-endcap: "\e99e",
	status-incremental: "\e99f",
	status-left-endcap: "\e9a0",
	status-deferred: "\e9a1",
	add-to-dashboard: "\e99d",
	shared: "\e99c",
	paste-from-clipboard: "\e99b",
	inactivate-user: "\e999",
	energy-star: "\e99a",
	back: "\e998",
	lan-icon: "\e995",
	bluetooth-icon: "\e996",
	trane-logo: "\e993",
	grid-services: "\e994",
	sc: "\e992",
	pdf-icon: "\e991",
	csv-icon: "\e98f",
	jpg-icon: "\e990",
	png-icon: "\e9d7",
	xls-icon: "\e9d8",
	otherfiles-icon: "\e9d6",
	repairman-avatar: "\e98a",
	bizman-avatar: "\e98b",
	engineer-avatar: "\e98c",
	technician-avatar: "\e98d",
	bizwoman-avatar: "\e98e",
	row-slider: "\e989",
	salesoffice2: "\e988",
	building-automation: "\e980",
	command-center: "\e981",
	facility-settings: "\e982",
	trane-service: "\e983",
	building-performance: "\e984",
	energy-performance: "\e985",
	financial-results: "\e986",
	opportunities: "\e987",
	enegy-consumption: "\e97f",
	reset-password: "\e97e",
	ellipse-dots: "\e97c",
	ellipse-box: "\e97d",
	email: "\e97b",
	clock: "\e979",
	delete-circle: "\e97a",
	set-up: "\e978",
	unavailable: "\e975",
	unavailable-x: "\e976",
	available: "\e977",
	launch-sc: "\e973",
	traneconnect: "\e974",
	left-caret: "\e971",
	right-caret: "\e972",
	loader: "\e970",
	tba-logo: "\e96f",
	circuit: "\e96c",
	environment-meter: "\e96d",
	pump: "\e96e",
	salesoffice: "\e96b",
	arrow-down: "\e95e",
	arrow-up: "\e95f",
	close-circle: "\e960",
	close: "\e961",
	check: "\e962",
	dash: "\e963",
	download: "\e964",
	help: "\e965",
	share: "\e966",
	upload: "\e967",
	calendar: "\e968",
	resize2: "\e969",
	text-image-link: "\e96a",
	menu: "\e95d",
	water: "\e93b",
	battery: "\e93c",
	nuclear: "\e93d",
	wind: "\e93e",
	electric: "\e93f",
	gas: "\e940",
	other: "\e941",
	solar: "\e942",
	space: "\e943",
	system: "\e944",
	binarypoint: "\e945",
	floorplan: "\e946",
	room: "\e947",
	sub-zone: "\e948",
	zone: "\e949",
	analogpoint: "\e94a",
	chilledwatersys: "\e94b",
	chillerplant: "\e94c",
	condwaterplant: "\e94d",
	distributionloop: "\e94e",
	multistatepoint: "\e94f",
	spacecomfortunit: "\e950",
	ahu: "\e951",
	fan: "\e952",
	loadvalves: "\e953",
	varfreqdrive: "\e954",
	vavbox: "\e955",
	chiller: "\e956",
	coolingtowercell: "\e957",
	coolingtower: "\e958",
	pumpbank: "\e959",
	vas: "\e95a",
	location: "\e95b",
	organization: "\e95c",
	error: "\e93a",
	gridview: "\e928",
	linechart: "\e929",
	scatterplot: "\e9d2",
	heat-map: "\e9d3",
	area: "\e9d1",
	listview: "\e92a",
	clipboard: "\e92b",
	dashboard2: "\e92c",
	magnify: "\e92d",
	nav: "\e92e",
	tools2: "\e92f",
	div: "\e930",
	edit: "\e931",
	expand: "\e932",
	target: "\e933",
	add: "\e934",
	gear2: "\e935",
	scale: "\e936",
	trash2: "\e937",
	duplicate: "\e938",
	favorite: "\e939",
	action-listcircle: "\e900",
	addcircle: "\e901",
	bar1widget: "\e902",
	bar2widget: "\e903",
	bar3widget: "\e904",
	baselineswidget: "\e905",
	chillerobject: "\e906",
	curve1widget: "\e907",
	curve2widget: "\e908",
	dashboard: "\e909",
	dialwidget: "\e90a",
	electric-meter1object: "\e90b",
	electric-meter2object: "\e90c",
	folderobject: "\e90d",
	gas-meter1object: "\e90e",
	gas-meter2object: "\e90f",
	gear: "\e910",
	general-meter1object: "\e911",
	geo-locationobject: "\e912",
	grid-view: "\e913",
	infocircle: "\e914",
	kpi1widget: "\e915",
	kpi2widget: "\e916",
	linewidget: "\e917",
	list-view: "\e918",
	locked: "\e919",
	multi-buildingobject: "\e91a",
	orbwidget: "\e91b",
	customkpiwidget: "\e91b",
	piewidget: "\e91c",
	plotwidget: "\e91d",
	single-buildingobject: "\e91e",
	solar-arrayobject: "\e91f",
	targeted: "\e920",
	tools: "\e921",
	trash: "\e922",
	vav-boxobject: "\e923",
	weatherwidget: "\e924",
	widget-type: "\e925",
	toggledown: "\e926",
	toggleup: "\e927",
	hidden: "\e9c9",
	visible: "\e9ca",
	responsive-controls: "\e9d9",
	truck: "\e9ce",
	emissions: "\e9cd",
	target-goal: "\e9cf",
	digital-maintenance: "\e9dd",
	filter: "\ea0d",
	notification: "\e9dc",
	announcement: "\ea05",
	utility-management: "\ea0e",
	smiley-face: "\ea16",
	search: "\2315"
);
