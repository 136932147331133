@import "src/components/legacy/common/typography.scss";
@import "src/components/legacy/common/loaders.scss";

.table-container {
	position: relative;
	padding-top: 24px;
	border: 1px solid $tba-dark-grey;
	overflow: hidden;
	border-radius: 2px;
	background-color: $tba-medium2-green;
}

.table-container.standard {
	& .table-list {
		& tr:nth-child(even) .toolbar-buttons .toolbar-buttons-content {
			background: $white;
		}

		& .icon {
			color: $white;
			cursor: pointer;
			&:hover {
				color : $white;
			}
		}

		& .actions button:not(.right-tab) {
			width: 28px;
			font-size: 16px;
			border: none;
			color: $tba-darker-grey;
			background-color: transparent;

			&:hover {
				color : $tba-dark-grey;
			}

			&:active {
				color : $tba-medium-grey;
			}
		}
	}
}

.table-scroll {
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 240px;
	background-color: $white;
	border-top: 1px solid $tba-dark-grey;

	&.hide-scrollbar {
		overflow-y: hidden;
	}
}

.table-list {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;

	& th {
		@include text-button;
		height: 0;
		line-height: 0;
		padding: 0;
		border: 0;
		text-align: left;
		cursor:default;
	}

	& th div {
		position: absolute;
		top: 0;
		line-height: 24px;
		margin-left: 16px;
	}

	& .ellipsis {
		@include text-ellipsis
	}

	& td {
		padding: 5px 0 5px 16px;
		line-height: 18px;
		@include text-list;
		vertical-align: top;
	}

	& tr:nth-child(even) {
		background-color: $white;
	}

	& tr:nth-child(odd) {
		background-color: $tba-lighter-grey;
	}

	/* handle row colors when drag'n'drop in progress */

	& .row-to-replace ~ tr:nth-child(even):not(.movable-row) {
		background-color: $tba-almost2-white;
	}

	& .row-to-replace ~ tr:nth-child(odd):not(.movable-row) {
		background-color: $white;
	}

	& tr.movable-row {
		background-color: $tba-lightest-grey;
		border: 1px 0px;
		border-color: $tba-light-grey;
		box-shadow: 5px 6px 6px rgba(0, 0, 0, 0.4);
	}

	& td .icon {
		color: $tba-darker-grey;
		font-size: 14px;
	}

	& .icon {
		letter-spacing: 4px;
	}

	& .sort {
		cursor: pointer;
		.icon:before {
			padding-left: 4px;
		}
	}

	& .info .icon-infocircle {
		border: 0;
		background-color: transparent;
		font-size: 16px;
		color: $tba-darker-grey;
	}

	& .link {
		@include button-link;
	}
}

.table-bounding-area {
	@include bounding-area;

	& h2 {
		margin: 0;
	}
	& .action {
		text-decoration: underline;
		cursor: pointer;
	}
}
.icon-button{
	border: none;
}