
.modal-textarea-wrapper{
    margin-bottom: 15px;
    .icon-error{
        color:#D52B23;
        position: absolute;
        right:-4px;
    }
    .has-error{
        color:#D52B23;
    }
    textarea.modal-textarea-input.has-error{
        border-width: 2px;
        border-color: #D52B23;
        background-color: #fea7aa;
        width:100%
    }
    .modal-textarea-input{
        width:100%
    }
    .mini-error-text{
        font-size:10px;
        font-style:italic;
        color:#D52B23
    }
}

.wrapperClass{
    margin-top: 10px;
}