.amplify-menu-content {
  padding: 1rem;

  .amplify-button--menu {
    color: var(--amplify-components-menu-color);

    &:hover,
    &:focus-visible {
      background: var(--amplify-components-menu-hover-background-color);
      outline: none;
    }

    &.amplify-button__actions {
      &__delete {
        color: var(--amplify-colors-red-50);
      }
    }
  }
}

.amplify-menu-content-wrapper {
  &[data-side='bottom'] {
    position: relative;
    top: 0.75rem;
  }
}

.amplify-menu-content__item,
.amplify-menu-content__item:first-child,
.amplify-menu-content__item:last-child,
.amplify-menu-content__item:not(:first-child):not(:last-child) {
  border-radius: 12px;
}
