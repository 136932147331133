@import "../../common/scss/colors.scss";
@import "../../common/scss/grid.scss";

.contracts-and-offerings-page {
	min-height: 100%;
	padding: 20px 20px 0;

	.offeringsTable {
		tr td:nth-child(2) {
			max-width: 100px;
    white-space: normal;
	word-wrap: break-word;
	word-break: break-word;
    text-overflow: unset;
    line-height: 1.5rem;
	padding-right: 4px;
		}
	}

	.header-wrapper{
		.title-wrapper{
			max-width: calc(100% - 100px);
		}
	}

	.table-heading-content {
		display: flex;
		justify-content: flex-start;
	}

	.table-column__value > div {
		display: flex;
	}

	.offering-status{
		.icon {
			margin-right: 3px;
		}
		font-style: italic;
		color:$red;
	}

	.off-status {
		width: 100px;

		.error-message {
			padding: 4px;
			display: flex;
			align-items: center;
			margin: 0 auto;
			width: fit-content;

			.icon,
			.error-text {
				font-size: 12px;
				margin: 0;
			}

			.icon {
				margin-right: 3px;
			}

			@include screen-max-small {
				& {
					display: inline-block;
				}
			}
		}
	}

	.off-actions {
		padding-right: 0;

		& > div {
			display: flex;
			flex-wrap: wrap;
		}

		@include screen-medium {
			& {
				text-align: right;
				width: 100px;
			}
		}

		button {
			background-color: $tba-darker-grey;

			&.icon-edit,
			&.icon-trash2 {
				color: $tba-darker-grey;
				margin: 0 2px;
				width: 22px;
				font-size: 16px;
				border: none;
				background-color: transparent;
			}
		}
	}

	table {

		.organization {
			width: 165px
		}

		.off-contract {
			width: 120px;
		}

		.off-actions {
			width: 185px;
		}

		.off-startDate,
		.off-subdomain,
		.off-expDate {
			width: 100px;
		}

		.location {
			a {
				color: inherit;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.off-subdomain {
		.table-column__value {
			word-break: break-all;
		}
	}

	.generic-empty-table {
		height: 100px;
	}

	.offering {
		padding: 5px 0 20px 0;
	}

	.main-react-mobile-toolbar {
		position: absolute;
		right: 20px;
		top: 64px;
		button {
			text-transform: none;
			font-weight: 200;
		}
	}

	p.information {
		font-weight: bold;
		font-size: 13px;
		max-width: 1000px;
		line-height: 19px;

		a {
			text-decoration: underline;
			color: $black;
			&:hover {
				color: $tba-darker-grey;
			}
		}
	}
}


