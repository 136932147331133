@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/emerald-icons.scss";

input[type="checkbox"] {
	position: absolute;
	opacity: 0;

	& + label {
		position: relative;
		margin: 0;
		padding: 0 0 0 20px;
		cursor: pointer;
		width:max-content;
	}
	& + label::before {
		content: "";
		display: block;

		position: absolute;
		left: 0;
		width: 14px;
		height: 14px;
		border: 1px solid $tba-dark2-grey;
		border-radius: 2px;
	}
	&:not(:checked) + label::before {
		background: $tba-lightest-grey;
	}
	&:indeterminate + label::before {
		background: $tba-light-green;
	}
	&:checked + label::before {
		background: $tba-green;
	}
	&:indeterminate + label::after,
	&:checked + label::after {
		content: "";
		position: absolute;
		top: 0;
		left: 3px;
		font-family: "Emerald" !important;
		font-size: 8px;
		font-weight: normal;
		border-radius: 2px;
		line-height: 14px;
		color: $white;
	}
	&:indeterminate + label::after {
		content: map_get($icons, dash);
	}
	&:checked + label::after {
		content: map_get($icons, check);
	}
}
fieldset[disabled] input[type="checkbox"], /* for IE */
input[type="checkbox"]:disabled {
	& + label {
		cursor: not-allowed;
	}
	&:not(:checked) + label::before {
		background: $tba-light-grey;
		border-color: $tba-medium-grey;
	}
	&:checked + label::before,
	&:indeterminate + label::before {
		background: $tba-medium-grey;
		border-color: $tba-medium-grey;
	}
}
