@import "../../common/colors.scss";

.datepicker-react {
	position: relative;
	outline: none;

	.datepicker-trigger {
		cursor: pointer;
		line-height: 14px;

		&.icon-calendar {
			font-size: 17px;
		}
		&.icon-clock {
			font-size: 18px;
	  }
	}

	.datepicker-trigger.icon {
		cursor: pointer;
		color: white;
		background-color: $tba-darker-grey;
		height: 26px;
		width: 30px;
		line-height: 26px;
		text-align: center;
		border-radius: 0 2px 2px 0;

		&.alternate {
			color: $black;
			background-color: $white;
		}
	}
	&.disabled .datepicker-trigger.icon {
		background-color: $tba-medium-grey;
		cursor: not-allowed;
	}

	.datepicker-selector, .calendar-view-container {
		display: none;

		&.is-active {
			display: block;
		}
	}

	.datepicker-calendar {
		vertical-align: top;
		text-align: left;
		position: absolute;
		top: 0;
		z-index: 13;
		display: none;
		font-size: 16px;
		background: $tba-lighter-grey;
		padding: 16px;
		border-radius: 6px;
		border-style: solid;
		border-color: $tba-dark-grey;
		border-width: 1px;
		box-shadow: 3px 4px 6px rgba(0, 0, 0, .1);

		&.right {
			right: 100%;
		}

		&.left {
			left: 100%;
		}

		&.center {
			top: 100%;
			right: 0;
		}

		&.is-open {
			display: block;
		}

		&.is-disabled {
			.bounding-box {
				padding: 16px 4px;
				background: $tba-light-grey;
				border-color: $tba-medium-grey;
			}
		}

		.datepicker-label {
			font-weight: bold;
			font-size: 13px;
			line-height: 15px;
			letter-spacing: 0.26px;
		}

		.bounding-box {
			text-align: center;
			width: 228px;
			background: $white;
			padding: 0 9px 9px 9px;
			border: 1px solid $tba-dark-grey;
			border-radius: 0 0 6px 6px;

			table {
				border-collapse: collapse;
				width: 100%;
			}
		}

		.heading-box {
			text-align: center;
			border-radius: 6px 6px 0 0;
			line-height: 40px;
			height: 40px;
			width: 100%;
			background: $tba-medium-green;
			color: $white;
			position: relative;
			border-bottom: 1px solid $tba-dark-grey;
			&::after {
				content: "";
				display: table;
				clear: both;
			}
			.datepicker-view-selector:not(.year-view) {
				cursor: pointer;
				&:hover {
					color: $tba-light-green;
				}
			}
			.range-changer {
				cursor: pointer;
				font-size: 14px;
				line-height: 40px;
				&:hover {
					color: $tba-light-green;
				}
				&.range-previous {
					float: left;
					padding-left: 9px;
				}
				&.range-next {
					float: right;
					padding-right: 9px;
				}
			}
		}

		.range-table-header {
			text-transform: uppercase;
			font-weight: bold;
			font-family: "Archivo Narrow";
			font-size: 12px;
			line-height: 19px;
			letter-spacing: 0.24px;
			color: $tba-dark-green;
			width: 100%;
			border-bottom: 1px solid $tba-dark-grey;
			th {
				width: 30px;
				height: 25px;
			}
		}

		.datepicker-spacer-row {
			height: 5px;
		}

			/* DatePicker Selector Styles */
		.datepicker-selector-row {
			td {
				height: 43px;
				border-radius: 3px;
				cursor: pointer;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;

				&:hover {
					color: $white;
					background: $tba-green;
				}

				&.datepicker-current-column {
					color: $tba-green;

					&:hover {
						color: $white;
					}
				}

				&.datepicker-selected-column {
					background: $tba-dark-green;
					color: $white;
					font-weight: 900;
				}
			}
		}

		/* Calendar View Styles */
		.datepicker-week {
			td {
				height: 30px;
				width: 30px;
				background: none;
				border-radius: 3px;
				cursor: pointer;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;

				&:hover {
					background: $tba-green;
					color: $white;
				}

				&.range-today {
					color: $tba-green;

					&:hover {
						color: $white;
					}
				}

				&.range-in-band {
					background: $tba-green;
					color: $white;
					border-radius: 0;

					&:hover {
						background: $green-3;
					}
				}

				&.range-other-month {
					color: $tba-darker-grey;
				}

				&.range-day-disabled {
					cursor: not-allowed;
					color: $tba-light-grey;
					pointer-events: none;
				}

				&.range-extreme {
					background: $tba-dark-green;
					color: $white;
					font-weight: 900;
					border-style: none;

					&:hover {
						background: $tba-green;
					}

					&.range-start {
						border-radius: 3px 0 0 3px;
					}

					&.range-end {
						border-radius: 0 3px 3px 0;
					}
				}
			}
		}
	}
}
