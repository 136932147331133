@import "src/components/legacy/common/grid.scss";
@import "src/components/legacy/common/custom-media-queries.scss";

.toolbar-search {
	display: flex;
	margin: 3px 0;
	flex-direction: column;
	align-items: flex-start;

	@include hide-on-print;

	.organization-picker, .sort-block, .filter-block {
		width: 100%;
		margin: 7px 0;
		padding: 0;
	}
	.filter-block{
		padding-left: 10px;
	}


	.organization-picker {
		.active-item-container {
			> label {
				line-height: 15px;
			}
		}
	}

	.sort-block, .filter-block {
		display: flex;
		align-items: center;
	}
	.sort-block {
		padding-right: 10px;
	}

	.select-wrapper {
		width: 160px;
		min-width: 0;

		> .control, > .select-dropdown {
			width: 100%;
		}

		+ .search-box {
			margin-left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.search-box {
		max-width: 130px;
		width: 100%;
	}

	.counter {
		font-size: 13px;
		line-height: 18px;
		font-weight: bold;
		font-style: italic;
		padding-left: 10px;
		display: none;
		text-transform: lowercase;
	}

	.custom-filter {
		margin-left: 8px;

		@include screen-max-extra-small {
			margin: 8px 0 6px;
		}
	}

	.tooltip-container {
		display: none;
		padding-left: 5px;
		.tooltip {
			max-width: 220px;
			white-space: normal;
			width: max-content;
			line-height: 1;
			padding-top: 8px;
			padding-bottom: 8px;
			word-break: break-word;
		}
	}

	@include screen-small {
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;

		.organization-picker, .sort-block {
			width: auto;
		}
		.organization-picker {
			margin-right: 20px;
		}
		.search-box {
			width: 180px;
		}
	}

	@include screen-medium {
		margin: 20px 0;
		flex-wrap: nowrap;

		.organization-picker, .sort-block, .filter-block {
			margin-top: 0;
			margin-bottom: 0;
		}

		.filter-block {
			width: auto;
		}
	}
	@include screen-large {
		.counter, .tooltip-container {
			display: block;
		}
	}

	@include screen-max-extra-small {
		.filter-component {
			flex-wrap: wrap;
			margin-bottom: 6px;
			width: 100%;
			.select-wrapper {
				flex: 100%;
			}

			.search-box {
				flex: auto 1 1;
				max-width: 100%;
				margin-top: 4px;
			}
		}
	}
}
