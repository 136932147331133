.horizontal-bar-chart-dimention {
  width: inherit;
  height: 300px;
}

.view-pdf-horizontal-bar-chart-dimention {
  width: inherit;
  height: 300px;
  .axis-text{
    font-size: 16px;
  }
}
.small-view-horizontal-bar-chart-dimention {
  width: inherit;
  height: 300px;
  .axis-text{
    transform: translate(-10px,2px) rotate(300deg);
    text-anchor: end;
  }
}