@import "src/common/scss/typography.scss";

label {
	@include text-field-label;
	display: inline-block;
}
fieldset[disabled] label,
fieldset[disabled] legend,
label.disabled,
legend[disabled] {
	color: $tba-dark-grey;
}
label.secondary {
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.36px;
	vertical-align: middle;
}
label + input {
	margin-left: 9px;
}
label.secondary + input,
input + label.secondary {
	margin-left: 5px;
}
.inline-group input[type="radio"] + label.secondary:not(:last-child) {
	margin-right: 22px;
}
p.error,
.tip,
p.error,
fieldset p.tip,
fieldset p.error {
	margin: 0;
	@include text-field-item;
	color: $tba-dark-grey;
}

.tip .underline {
	text-decoration: underline;
	cursor: pointer;
}

p.error,
fieldset p.error,
legend.invalid {
	color: $red;
}
