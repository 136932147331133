@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/typography.scss";
@import "src/components/legacy/common/emerald-icons.scss";

.selectrix-wrapper {
	position: relative;

	.selectrix-wrapper__close-helper {
		cursor: pointer;
		display: none;
		height: auto;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 24px;
		z-index: 100;
	}

	&.selectrix-wrapper--custom-no-results {
		.react-selectrix .rs-no-results {
			display: none;
		}
	}
	.rs-toggle-wrapper{
		padding:0;
		color: #000;
		& .rs-toggle-button{
			color: #000;
			padding:7px 8px;
			background-color: inherit;
			display: flex;
			flex: 100%;
			font-weight: bold;
			text-align: left;
		}
	}
	&.selectrix-wrapper--type-autocomplete {

		.react-selectrix {

			.rs-header {
				border: none;

				.rs-reset-wrapper {
					display: none;
				}

				.rs-toggle,
				.rs-searchable {
					background: $tba-lightest-grey;
					border: 1px solid $tba-dark2-grey;
					border-radius: 2px;
					color: $tba-dark-grey;
					font-family: Lato, sans-serif;
					font-size: 12px;
					font-weight: bold;
					height: 26px;
					letter-spacing: 0.24px;
					line-height: 18px;
					outline: none;
					padding: 0 6px;
					width: 100%;

					&.rs-selected {
						color: $black;
						padding-right: 30px;
						@include text-ellipsis;
					}

					&.rs-focused {
						box-shadow: none;
					}

					&::placeholder {
						color: $black;
						opacity: 1;
					}

					&::-webkit-input-placeholder {
						color: $black;
						opacity: 1;
					}

					&::-ms-input-placeholder {
						color: $black;
						opacity: 1;
					}
				}

				.rs-toggle {
					line-height: 26px;
				}

				.rs-arrow-wrapper {
					align-items: center;
					border-left: 1px solid $tba-dark2-grey;
					display: flex;
					justify-content: center;
					right: 0;
					top: 0;
					width: 30px;

					&::before {
						content: none;
					}

					.rs-arrow-indicator {
						background-color: transparent;
						border: none;
						color: $black;
						cursor: pointer;
						display: block;
						font-family: "Emerald" !important;
						font-size: 14px;
						font-style: normal;
						font-variant: normal;
						font-weight: normal;
						height: auto;
						line-height: 1;
						speak: none;
						text-transform: none;
						transition: transform .4s ease;
						width: 16px;

						&::before {
							content: "\E932";
						}
					}
				}

				&:after {
					content: none;
				}
			}

			.rs-body {
				background-color: $tba-lightest-grey;
				border: 1px solid $tba-dark2-grey;
				box-shadow: none;
				height: auto;
				margin-top: -1px;
				opacity: 1;
				transform: scaleY(1);
				transform-origin: top;
				transition: transform 0.2s ease-in-out;
				visibility: visible;
				z-index: 2;

				&.hidden {
					display: block;
					transform: scaleY(0);
					transform-origin: top;
					transition: transform 0.2s ease-in-out;
				}

				.rs-option,
				.rs-no-results,
				.rs-tag-wrapper {
					background-color: $tba-lightest-grey;
					color: $black;
					font-size: 12px;
					font-style: normal;
					font-weight: bold;
					line-height: 14px;
					min-height: 26px;
					padding: 7px 8px;
					text-align: left;
					white-space: pre-line;
					word-wrap: break-word;
				}

				.rs-option {
					height: auto;
				}

				.rs-no-results {
					font-style: italic;
				}

				.rs-tag-wrapper {
					box-shadow: none;
					text-decoration: underline;
				}

				.rs-tag-wrapper:not(.disabled).focused,
				.rs-option:not(.disabled).focused {
					background-color: $green;
					color: $white;
				}

				&.rs-base-open .rs-searchable {
					display: block;
				}

				.rs-option.rs-searchable-box,
				.rs-option.rs-searchable-box.focused {
					background: $tba-light-grey;
					border-bottom: 1px solid $tba-medium-purple;
					height: 26px;
					padding: 4px;
					text-overflow: clip;
					white-space: nowrap;
					word-wrap: normal;

					input {
						border-radius: 9px;
						box-shadow: none;
						color: $black;
						font-family: "Archivo Narrow", sans-serif;
						font-size: 12px;
						font-weight: bold;
						height: 18px;
						letter-spacing: 0.24px;
						line-height: 14px;
						padding: 0 6px;
						vertical-align: center;
						width: 100%;

						&::placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}

						&::-webkit-input-placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}

						&::-ms-input-placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}
						&::-ms-clear {
							display: none;
						}
					}

					.rs-reset-option {
						box-sizing: border-box;
						font-family: Emerald;
						font-weight: normal;
						right: 17px;
						top: 0;

						.rs-reset {
							background-color: $tba-darker-grey;
							border-radius: 50%;
							color: $tba-almost-white;
							cursor: pointer;
							display: inline-block;
							font-size: 13px;
							height: 13px;
							line-height: 13px;
							text-align: center;
							vertical-align: middle;
							width: 13px;
						}
					}
				}
			}

			&.rs-base-open {
				.rs-searchable {
					&::-webkit-input-placeholder {
						opacity: 0;
					}

					&::placeholder {
						opacity: 0;
					}
				}
			}

			&.rs-base-disabled {
				opacity: 1;

				.rs-wrapper .rs-header {
					.rs-arrow-wrapper {
						border-color: $tba-medium-grey;

						.rs-arrow-indicator {
							color: $tba-dark-grey;
						}
					}

					.rs-toggle {
						background: $tba-light-grey;
						border-color: $tba-medium-grey;
						color: $tba-dark-grey;
						cursor: not-allowed;
					}
				}
			}
		}

		.filterable-select-spinner {
			padding: 3px 0;
		}
	}

	&.selectrix-wrapper--type-tags {
		.selectrix-wrapper__close-helper {
			top: -20px;
			bottom: auto;
		}

		.react-selectrix {
			border-top: 1px solid $tba-medium-green;

			&.rs-base-multiple .rs-selection {
				background-color: $tba-almost2-white;
				border-color: $tba-dark-grey;
				border-radius: 6px;
				color: $black;
				cursor: default;
				font-family: "Archivo Narrow", sans-serif;
				font-weight: 800;
				margin: 5px 20px 5px 0;

				.rs-remove {
					border-right: medium none;
					color: $black;
					cursor: pointer;
					font-family: "Emerald" !important;
					font-size: 14px;
					font-style: normal;
					font-variant: normal;
					font-weight: normal;
					line-height: 1;
					margin: 4px;
					text-transform: none;
					span {
						display: none;
					}

					&::before {
						content: map_get($icons, close-circle);
					}

					&:hover {
						background-color: transparent;
						color: $tba-dark-grey;
					}

					speak: none;
				}
			}

			&.rs-base-multiple:not(.rs-base-empty):not(.rs-base-commaseperated) .rs-toggle {
				padding: 0;
			}

			.rs-header {
				border-color: transparent;

				.rs-reset-wrapper {
					display: none;
				}

				.rs-toggle {
					color: $black;
					max-height: 105px;
					overflow: auto;
					padding: 0;
					&.rs-focused {
						box-shadow: none;
					}
				}

				.rs-searchable {
					background-color: $white;
					border: 1px solid $tba-dark-grey;
					border-radius: 6px;
					bottom: -36px;
					color: $black;
					display: none;
					left: 2%;
					padding: 0 8px;
					width: 96%;
					z-index: 2;
				}

				.rs-arrow-wrapper {
					border-radius: 6px;
					color: $tba-darker-grey;
					font-size: 12px;
					font-weight: 800;
					height: auto;
					margin-bottom: 12px;
					padding-left: 6px;
					position: absolute;
					right: 0;
					top: -26px;
					width: auto;

					&:hover {
						color: $tba-dark-grey;
					}
				}

				.rs-arrow-indicator {
					border-width: 0;
					cursor: pointer;
					font-family: "Emerald" !important;
					font-feature-settings: normal;
					font-style: normal;
					font-variant: normal;
					font-weight: normal;
					height: auto;
					line-height: 1;
					padding: 0 6px;
					speak: none;
					text-transform: none;
					width: auto;
					&:before {
						content: map_get($icons, expand);
					}
				}
			}

			.rs-body {
				background-color: $tba-lightest-grey;
				border: 1px solid $tba-dark-grey;
				box-shadow: none;
				height: auto;
				margin-top: -1px;
				opacity: 1;
				transform: scaleY(1);
				transform-origin: top;
				transition: transform 0.2s ease-in-out;
				visibility: visible;

				&.hidden {
					display: block;
					transform: scaleY(0);
					transform-origin: top;
					transition: transform 0.2s ease-in-out;
				}

				.rs-option,
				.rs-no-results {
					background-color: $tba-lightest-grey;
					color: $black;
					font-size: 12px;
					font-style: normal;
					font-weight: bold;
					line-height: 14px;
					min-height: 26px;
					padding: 7px 8px;
					text-align: left;
					white-space: pre-line;
					word-wrap: break-word;
				}

				.rs-option:not(.disabled).focused {
					background-color: $green;
					color: $white;
				}

				.rs-option.rs-searchable-box,
				.rs-option.rs-searchable-box.focused {
					background: $tba-light-grey;
					border-bottom: 1px solid $tba-dark-grey;
					height: 26px;
					padding: 4px;
					text-overflow: clip;
					white-space: nowrap;
					word-wrap: normal;

					input {
						border-radius: 9px;
						box-shadow: none;
						color: $black;
						font-family: "Archivo Narrow", sans-serif;
						font-size: 12px;
						font-weight: bold;
						height: 18px;
						letter-spacing: 0.24px;
						line-height: 14px;
						padding: 0 6px;
						vertical-align: center;
						width: 100%;

						&::placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}

						&::-webkit-input-placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}

						&::-ms-input-placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}
						&::-ms-clear {
							display: none;
						}
					}

					.rs-reset-option {
						box-sizing: border-box;
						font-family: Emerald;
						font-weight: normal;
						right: 17px;
						top: 0;

						.rs-reset {
							background-color: $tba-darker-grey;
							border-radius: 50%;
							color: $tba-almost-white;
							cursor: pointer;
							display: inline-block;
							font-size: 13px;
							height: 13px;
							line-height: 13px;
							text-align: center;
							vertical-align: middle;
							width: 13px;
						}
					}
				}
			}

			&.rs-base-open .rs-searchable {
				display: block;
			}
		}
	}
	&.selectrix-wrapper-multi-select {
		.react-selectrix {
			.rs-wrapper .rs-header .rs-toggle {
				height: auto;
				padding: 0 32px 0 6px;
				line-height: 24px;
			}

			&.rs-base-multiple {
				.rs-selection {
					display: inline-flex;
					align-items: center;
					height: 20px;
					padding: 0 4px;
					margin: 1px;
					border: 1px solid $tba-dark-grey;
					border-radius: 6px;
					font-size: 10px;
					background-color: $tba-almost2-white;
					color: $black;
					cursor: auto;
				}

				.rs-remove {
					position: static;
					width: 12px;
					height: 12px;
					margin-right: 2px;
					color: $tba-lighter-green;
					background-color: $black;
					border-right: none;
					border-radius: 50%;
					line-height: 0;
					cursor: pointer;
					transition: 0.2s background-color ease-in-out;

					&:hover {
						background-color: $tba-dark-grey;
					}
				}
			}
		}
	}

	&.selectrix-wrapper--single-select {

		.react-selectrix {

			.rs-header {
				border: none;

				.rs-reset-wrapper {
					display: none;
				}

				.rs-toggle,
				.rs-searchable {
					background: $tba-lightest2-grey;
					border: 1px solid $tba-dark2-grey;
					border-radius: 2px;
					color: $tba-dark-grey;
					font-family: Lato, sans-serif;
					font-size: 12px;
					font-weight: bold;
					height: 27px;
					letter-spacing: normal;
					line-height: 18px;
					outline: none;
					padding: 0 8px;
					width: 100%;

					&.rs-selected {
						color: $black;
						padding-right: 30px;
						@include text-ellipsis;
					}

					&.rs-focused {
						box-shadow: none;
					}

					&::placeholder {
						color: $black;
						opacity: 1;
					}

					&::-webkit-input-placeholder {
						color: $black;
						opacity: 1;
					}

					&::-ms-input-placeholder {
						color: $black;
						opacity: 1;
					}
				}

				.rs-toggle {
					line-height: 26px;
				}

				.rs-arrow-wrapper {
					align-items: center;
					border-left: 1px solid $tba-dark2-grey;
					display: flex;
					justify-content: center;
					right: 0;
					top: 0;
					width: 30px;

					&::before {
						content: none;
					}

					.rs-arrow-indicator {
						position: relative;
						top: 2px;
						background-color: transparent;
						border: none;
						color: $black;
						cursor: pointer;
						font-family: "Emerald" !important;
						font-size: 14px;
						font-style: normal;
						font-variant: normal;
						font-weight: normal;
						height: auto;
						transition: transform .4s ease;
						width: auto;

						&::before {
							content: "\E932";
						}
					}
				}

				&:after {
					content: none;
				}
			}

			.rs-body {
				background-color: $tba-lightest-grey;
				border: 1px solid $tba-dark2-grey;
				box-shadow: none;
				height: auto;
				margin-top: -1px;
				opacity: 1;
				transform: scaleY(1);
				transform-origin: top;
				transition: transform 0.2s ease-in-out;
				visibility: visible;
				z-index: 2;

				&.hidden {
					display: block;
					transform: scaleY(0);
					transform-origin: top;
					transition: transform 0.2s ease-in-out;
				}

				.rs-option,
				.rs-no-results,
				.rs-tag-wrapper {
					background-color: $tba-lightest-grey;
					color: $black;
					font-size: 12px;
					font-style: normal;
					font-weight: bold;
					line-height: 14px;
					min-height: 26px;
					padding: 7px 8px;
					text-align: left;
					white-space: pre-line;
					word-wrap: break-word;
				}

				.rs-option {
					height: auto;
				}

				.rs-no-results {
					font-style: italic;
				}

				.rs-tag-wrapper {
					box-shadow: none;
					text-decoration: underline;
				}

				.rs-tag-wrapper:not(.disabled).focused,
				.rs-option:not(.disabled).focused {
					background-color: $green;
					color: $white;
				}

				&.rs-base-open .rs-searchable {
					display: block;
				}

				.rs-option.rs-searchable-box,
				.rs-option.rs-searchable-box.focused {
					background: $tba-light-grey;
					border-bottom: 1px solid $tba-medium-purple;
					height: 26px;
					padding: 4px;
					text-overflow: clip;
					white-space: nowrap;
					word-wrap: normal;

					input {
						border-radius: 9px;
						box-shadow: none;
						color: $black;
						font-family: "Archivo Narrow", sans-serif;
						font-size: 12px;
						font-weight: bold;
						height: 18px;
						letter-spacing: 0.24px;
						line-height: 14px;
						padding: 0 6px;
						vertical-align: center;
						width: 100%;

						&::placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}

						&::-webkit-input-placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}

						&::-ms-input-placeholder {
							color: $tba-medium-dark-grey;
							font-style: italic;
							opacity: 1;
						}
						&::-ms-clear {
							display: none;
						}
					}

					.rs-reset-option {
						box-sizing: border-box;
						font-family: Emerald;
						font-weight: normal;
						right: 17px;
						top: 0;

						.rs-reset {
							background-color: $tba-darker-grey;
							border-radius: 50%;
							color: $tba-almost-white;
							cursor: pointer;
							display: inline-block;
							font-size: 13px;
							height: 13px;
							line-height: 13px;
							text-align: center;
							vertical-align: middle;
							width: 13px;
						}
					}
				}
			}

			&.rs-base-open {
				.rs-searchable {
					&::-webkit-input-placeholder {
						opacity: 0;
					}

					&::placeholder {
						opacity: 0;
					}
				}
			}

			&.rs-base-disabled {
				opacity: 1;

				.rs-wrapper .rs-header {
					.rs-arrow-wrapper {
						border-color: $tba-medium-grey;

						.rs-arrow-indicator {
							color: $tba-dark-grey;
						}
					}

					.rs-toggle {
						background: $tba-light-grey;
						border-color: $tba-medium-grey;
						color: $tba-dark-grey;
						cursor: not-allowed;
					}
				}
			}
		}

		.filterable-select-spinner {
			padding: 3px 0;
		}
	}
}
