.heat-map {
  width: 82vw;
  height: 600px;
}

.heat-map-tooltip {
  font-family: inherit;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: black;
  padding: 5px;

  .title {
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    margin: 0 0 5px 0;
  }

  span.value {
    text-align: right;
    flex: 1;
  }

  span.icon {
    display: inline-block;
    width: 1px;
    height: 1px;
    padding: 5px;
    margin-right: 5px;
    background: transparent;
  }

  .item {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 5px;
  }
}

.heat-map-tooltip-bg {
  background: #fff !important;
  -webkit-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
}

.heap-map-visual-legend-axis .tick {
  color: #666666;
  cursor: default;
  font-size: 11px;
  fill: #666666;
  font-weight: 500;
}

.ed-y-label-block {
  width: 25px;
  position: relative;
}

.ed-y-label {
  transform-origin: 0 -50px;
  transform: rotate(270deg);
  position: absolute;
  top: 50%;
  white-space: nowrap;
  font-weight: 100;
}
