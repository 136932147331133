@import '../functions.scss';

.wrapper {
  background-color: var(--amplify-colors-neutral-10);
  border-radius: rem(20);
  color: var(--amplify-colors-neutral-90);
  padding: rem(64);
  position: absolute;
  inset: 18% 4% 10%;
  z-index: 2;
  display: flex;
  justify-content: flex-start;

  .icon {
    color: var(--amplify-colors-red-50);
    height: rem(44);
    width: rem(44);
    margin-right: rem(16);
  }

  .message {
    font-size: rem(32);
    font-weight: 700;
    line-height: rem(40);
  }
}
