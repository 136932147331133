@import "src/components/legacy/common/grid.scss";
@import "src/components/legacy/common/typography.scss";
@import "src/components/legacy/common/controls/table.scss";

//Variables & Mixins
	@mixin small-panel-size {
		width: 300px;
	}
	@mixin large-panel-size {
		width: 474px;
	}
	@mixin extra-small-panel-size {
		width: 223px;
	}
	@mixin large-screen-panel {
		@include large-panel-size;
		flex-wrap: nowrap;
		.full-select-label {
			display: inline-block;
		}
		& .table-container th div {
			margin-left: 0;
		}
		& .panel-container {
			border: 1px solid yellow;
			@include large-panel-size;
		}
		& .item-filter {
			padding: 3px 0 5px 0;
			align-items: center;
			margin-bottom: 0;
			& label {
				display: inline-block;
				@include text-list;
			}
			& .pagination {
				display: inline-block;
				padding-left: 10px;
				margin: 0;
				width: auto;
				vertical-align: middle;
				@include text-list;
				& > * {
					font-weight: bold;
				}
			}
		}
		& .full-offsets {
			display: inline-block;
		}
		& .small-offsets {
			display: none;
		}
		& .item-name {
			width: 250px;
		}
		& .bottom-pagination-box .pagination-control {
			padding-left: 5px;
			padding-right: 5px;
		}
		button.primary {
			height: 30px
		}
		& hr {
			display: inline-block;
			height: 2px;
			color: $tba-darker-grey;
			background: $tba-darker-grey;
			border: 0;
		}
	};

	@mixin large-screen-container {
		padding: 0 16px 0 0;
		margin-bottom: 0;
	};



.select-block {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 20px;
	& > * {
		display: block;
	}
	& .custom-label {
		line-height: 22px;
	}
	.active-item-container {
		@include screen-max-extra-small {
			flex-flow: column;
			align-items: flex-start;
		}
		& .panel-container {
			display: inline-block;
			@include screen-max-extra-small {
				display: block;
			}
		}
	}

	.active-item-container label {
		line-height: 22px;
		.active-item-name {
			font-weight: 900;
			display: inline-block;
			white-space: nowrap;
			max-width: 24ch;
			.tooltip-container {
				cursor: pointer;
			}
		}
	}

	&.disabled {
		color: $tba-light-grey;

		&:hover, &:active {
			color: $tba-light-grey;
		}
		button {
			background-color: $tba-lighter-grey;
			border-color: $tba-light-grey;
			color: $white;
			&:hover, &:active {
				background-color: $tba-lighter-grey;
				border-color: $tba-light-grey;
				color: $white;
			}
		}
	}

	label {
		padding-right: 6px;
	}

	.select-item-panel {
		@include small-panel-size;
		position: absolute;
		z-index: 11;
		padding: 10px 20px 20px 20px;
		background-color: $tba-lighter-grey;
		border-style: solid;
		border-color: $tba-dark2-grey;
		border-width: 1px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;

		box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);

		& hr {
			display: none;
		}
		& .tooltip-container > div {
			overflow: hidden;
		}
		& .item-name {
			text-transform: uppercase;
			text-overflow: ellipsis;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			width: 110px;
		}
		& .heading {
			@include text-field-label;
		}
		& .pagination-control {
			cursor: pointer;
			&.current-offset {
				cursor: default;
				color: $tba-medium-grey;
			}
		}
		& .item-filter {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 5px;

			& label {
				display: none;
			}
			& .filter-container {
				display: inline-block;
			}
			& .pagination {
				display: none;
			}
		}
		& .table-container {
			overflow: visible;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			tr.active {
				background-color: $tba-green;

				.item-name {
					color: $white;
					font-weight: bold;
				}
				button.primary {
					background-color: $tba-almost2-white;
					color: $tba-lighter-grey;
				}
			}

			& .disabled {
				color: $tba-light-grey;
				font-weight: bold;
			}
			& th {
				padding-left: 10px;
			}
			& td:nth-child(2) {
				text-align: right;
			}
			& .table-list td {
				padding: 2px 0 2px 4px;
			}
		}
		& .bottom-pagination-box {
			height: 28px;
			line-height: 20px;
			background: $white;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-style: solid;
			border-color: $tba-dark2-grey;
			border-width: 1px;
			border-radius: 2px;
			border-top: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			padding-top: 4px;
			vertical-align: top;
			& > * {
				display: block;
			}
			& .pagination-control {
				@include text-list;
				padding-left: 2px;
				padding-right: 2px;
				font-weight: bold;
			}
		}
		& button.primary {
			height: 24px;
		}
		& .full-select-label {
			display: none;
		}
		& .table-container th div {
			margin-left: 0;
		}
		& .table-container td:nth-child(2) {
			padding-right: 5px;
		}
		& .full-offsets {
			display: none;
		}
		& .small-offsets {
			display: inline-block;
		}

		.tooltip {
			pointer-events: none;
		}

		@include screen-medium {
			@include large-screen-panel;
		}

		@include screen-max-extra-small {
			@include extra-small-panel-size;
			padding: 10px;
		}
	}

	&.filterable-select-error {
		.select-error-message {
			font-size: 12px;
			font-style: italic;
			font-weight: 700;
			color: $red;
			line-height: 14px;
			letter-spacing: 0.36px;

		}
		.item-label,
		.custom-label,
		.active-item-name {
			color: $red;
			font-weight: 900;
		}
		.button-error {
			background-color: $red-1;
			border-color: $red;
		}
	}

	.slide-in-enter {
		transform: scaleY(0);
		&.slide-in-enter-active {
			transform-origin: top;
			transform: scaleY(1);
			transition: transform 0.2s ease-in-out;
		}
	}

	.slide-in-leave {
		transform: scaleY(1);
		&.slide-in-leave-active {
			transform-origin: top;
			transform: scaleY(0);
			transition: transform 0.2s ease-in-out;
		}
	}

	.no-results-text {
		font-weight: 500;
	}

	.filterable-select-spinner {
		height: auto;
		margin: 0;
		padding: 0;
		border: none;

		.spin-wheel {
			font-size: 20px;
		}
	}
}
