@import 'src/denali-components/functions.scss';

[data-amplify-theme='denali-theme'] {
  .ytdContainer {
    position: absolute;
    right: rem(40);

    .ytd {
      color: var(--amplify-colors-neutral-50);
    }

    .ytdTotal {
      color: var(--amplify-colors-black);
      font-size: rem(24);
      font-weight: 600;
      line-height: rem(48);
      margin-right: rem(6);
    }
  }
}
