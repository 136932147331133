@import "src/common/scss/colors.scss";
@import "src/common/scss/emerald-icons.scss";
@import "src/common/scss/mixins.scss";
@import "src/common/scss/grid.scss";
@import "src/components/legacy/controls/label.scss";

$bnItemsMaxHeight: 481px;

.picker-flex-wrapper {
	display: inline-flex;
	/* align-items: flex-end; */
	/* justify-content: space-between; */
  width: 100%;
}

.picker-no-history-error {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-left: 6px;
	max-width: 240px;
	font-weight: bold;
	color: red;
}

.bn-wrapper {
	position: relative;
	max-width: 330px;
	margin-bottom: 20px;
	z-index: 2;
	width: 100%;

	&.loading {
		pointer-events: none;
	}

	&.small {
		width: 310px;
	}

	@include screen-max-small {
		&.small {
			width: 198px;
		}
	}

	#react-app > main > & {
		width: auto;
		height: auto;
		margin: 0 0 0 20px;
		z-index: 5;
	}

	@include screen-tiny {
		max-width: 240px;
	}


	.slide-in-enter {
		transform: scaleY(0);
		&.slide-in-enter-active {
			transform-origin: top;
			transform: scaleY(1);
			transition: transform 0.2s ease-in-out;
		}
	}

	.slide-in-leave {
		transform: scaleY(1);
		&.slide-in-leave-active {
			transform-origin: top;
			transform: scaleY(0);
			transition: transform 0.2s ease-in-out;
		}
	}
}

.missing-recent {
	position: relative;
	left: 26px;
}

.bn-header {
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;
	border: 1px solid $tba-dark2-grey;
	background-color: $tba-lightest-grey;
	cursor: pointer;
	z-index: 1;
	border-radius: 0 0 8px 8px;
	transition: border-radius 20ms linear 200ms;

	.bn-wrapper.small & {
		height: 36px;
		border-radius: 0 0 4px 4px;

		.arrow {
			padding: 11px;
		}
	}
	.bn-wrapper.opened & {
		border-radius: 0;
		transition-delay: 0ms;
	}

	.label {
		padding-left: 15px;
		padding-right: 50px;
		width: 100%;
		font-size: 13px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		& > div {
			display: flex;
		}

		.title-block {
			max-width: 100%;
			overflow: hidden;

			&.empty {
				font-weight: bold;
				color: $tba-darker-grey;
			}

			> * {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.subtitle {
			font-style: italic;
		}

		.icon {
			display: inline-block;
			margin-right: 5px;
			font-size: 16px;
		}
	}

	.arrow {
		position: absolute;
		top: 0;
		right: 0;
		padding: 13px;
		border-left: 1px solid $tba-dark2-grey;

		> .icon {
			display: block;
			font-size: 12px;
			@include dropdownArrowAnimation(".bn-wrapper.opened &");
		}
		.section-spinner {
			margin: -4px;
			padding: 0;
			height: 20px;
			width: 20px;
			border: 0;
			background-color: transparent;
			font-size: 16px;

			.icon {
				font-size: 14px;
			}
		}
	}
}

.bn-body {
	border-radius: 0 0 8px 8px;
	box-shadow: 5px 5px 5px rgba($tba-dark2-grey, 0.35);
	border: 1px solid $tba-dark2-grey;
	background-color: $tba-lightest-grey;
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: -1px;
	width: 100%;
	z-index: 10;

	@include dropdownAnimation(".bn-wrapper.opened &", 690px);

	> .section-spinner {
		margin: 0;
		padding: 0;
		border: 0;
		flex-grow: 1;
		height: $bnItemsMaxHeight;
	}
	&-sort-by {
		display: flex;
		padding: 10px 15px;
		align-items: center;

		label {
			margin-right: 6px;
		}

		+ .bn-body-items {
			border-top: 1px solid $tba-dark2-grey;
		}

		select {
			background-color: transparent;
			border: 0;
			flex-grow: 1;
			padding: 10px 0;
			margin: -10px 0;
			font-weight: bold;

			-moz-appearance: none;
			-webkit-appearance: none;
			&::-ms-expand {
				display: none;
			}
		}
	}
	&-group-by {
		padding: 10px 15px;
		border-top: 1px solid $tba-dark2-grey;
		border-bottom: 1px solid $tba-dark2-grey;
	}
	&-filter {
		padding: 0;
		background-color: $tba-light-grey;

		.search-box {
			padding: 7px 13px;
			border: 0;
			height: auto;
			width: 100%;

			input {
				height: 21px;
			}
			.clear {
				right: 16px;
			}
		}
	}
	&-items {
		max-height: $bnItemsMaxHeight;
		overflow-y: auto;
		overflow-x: hidden;
		color: $black;

		.item {
			cursor: pointer;
			font-size: 13px;
			padding: 4px 5px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			height: 44px;
			position: relative;

			&.odd {
				background-color: $white;
			}
			&.even {
				background-color: $tba-lightest-grey;
			}

			&:not(.inactive) {
				&:hover, &:focus, &:active, &.selected {
					color: $white;
				}
				&:hover, &:focus {
					background-color: $tba-medium-green;
				}
				&:active {
					background-color: $tba-dark-green;
				}
				&.selected {
					background-color: $tba-green;
				}
			}

			&.inactive {
				pointer-events: none;
				color: $tba-light-grey;

				&.selected {
					background-color: $tba-light-grey;
					color: $white;
				}
			}

			&.disabled {
				color: $tba-darker-grey;
				font-style: italic;
				letter-spacing: .5px;
			}

			&.all {
				display: flex;

				.label {
					width: auto;
					padding-right: 10px;

					& + .subtitle {
						font-style: italic;
					}
				}
			}

			> .label, > .secondary {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				padding-left: 18px;
			}
			> .label {
				font-weight: bold;

				&.no-icon {
					padding-left: 0;
				}
				.icon {
					position: absolute;
					margin-left: -18px;
					margin-top: 1px;
				}

			}
			> .secondary {
				margin-top: -4px;
			}
		}

		.level {
			&-0 {
				padding-left: 15px;
				> .label, > .secondary {
					padding-left: 22px;
				}
				> .label {
					.icon {
						font-size: 16px;
						margin-left: -22px;
					}
				}
			}
			&-1 {
				padding-left: 30px;
			}
			&-2 {
				padding-left: 45px;
			}
		}
	}

	&-pager {
		border-top: 1px solid $tba-dark2-grey;
		padding: 15px;

		.pager-items {
			padding: 0;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			justify-content: center;
			font-size: 14px;
			font-weight: bold;
			line-height: 18px;

			li {
				padding: 0 6px;
				margin: 5px 0;
				position: relative;
				cursor: pointer;

				&:after {
					content: '|';
					position: absolute;
					right: -2px;
					color: $tba-light-grey;
				}
				&:last-child {
					&:after {
						content: none;
					}
				}
			}
		}
	}

	.bn-wrapper.small & {
		min-width: 220px;
		border-radius: 0 4px 4px 4px;

		&-items {
			.item {
				height: 30px;
			}
		}
	}
}

.nested-select-dialog {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2147483647 !important;
	position: fixed;

	.bn-wrapper {
		max-width: none;
		width: 100%;
		height: 100%;
		margin: 0;

		.bn-body {
			max-height: none;
		}
	}

	.bn-header, .bn-body {
		border-radius: 0;
	}
	.bn-body {
		top: 40px;
		height: calc(100% - 40px);
		display: flex;
		flex-direction: column;

		&-items {
			-webkit-overflow-scrolling: touch;
		}

		&-sort-by {
			min-height: 38px;
			font-size: 16px;

			label, select {
				font-size: inherit;
			}

			&:after {
				content: map_get($icons, 'right-caret');
				font-family: "Emerald";
				position: absolute;
				right: 15px;
				pointer-events: none;
			}

			label {
				color: $tba-medium-dark-grey;
			}
		}
		&-filter {
			.search-box {
				padding: 4px 10px;
				input {
					font-size: 16px;
					line-height: 24px;
					height: 24px;
				}
			}
		}
		&-pager {
			padding: 5px 15px;

			.pager-items {
				li {
					margin: 5px 0 2px;
				}
			}
		}
	}
	.bn-body-items {
		max-height: none;
		flex-grow: 1;
	}
}
