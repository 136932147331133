@import '../functions.scss';

.dialog {
  background: var(--amplify-components-dialog-background);
  padding: rem(30) rem(40) rem(40);
  border-radius: rem(40);
  font-size: rem(14);
  line-height: normal;
  border: none;
  width: 600px;
  max-width: 90%;
  overflow: auto;

  &.withDivider {
    .title {
      border-bottom: solid 1px var(--amplify-colors-neutral-20);
      margin-bottom: rem(16);
      padding-bottom: rem(12);
    }
  }
}

.dialogRow {
  display: flex;
  flex-direction: row;
  gap: rem(10);
  justify-content: space-between;
  align-items: center;
  color: var(--amplify-colors-neutral-50);
  font-size: rem(16);
  font-weight: 400;
  line-height: rem(22);
  margin: rem(10) 0;
  width: 100%;

  .rowLabel {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(18);
    font-weight: 500;
    line-height: rem(24);
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: rem(10);
    align-items: center;
    padding-top: rem(16);
    width: 100%;
  }

  .denaliInput,
  .denaliSelect {
    border-radius: var(--amplify-components-fieldcontrol-input-border-radius);
    color: var(--amplify-components-fieldcontrol-color);
    font-size: var(--amplify-components-fieldcontrol-input-font-size);
    text-align: left;
  }
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: rem(24);

  .titleHeading {
    flex-grow: 1;

    .titleHeadline {
      font-weight: 500;
      margin: 0;
    }
  }
}

.titleButtons {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  gap: rem(16);
  align-items: center;

  button {
    font-size: rem(14);
    line-height: normal;
    padding: rem(12) rem(16);
    font-weight: 400;
  }
}

button.closeButton {
  padding: rem(12);
  border-radius: 9999px;
}

.subHeader {
  color: var(--amplify-colors-neutral-90);
  font-size: rem(16);
  font-weight: 500;
  line-height: rem(20);
}

.message {
  color: var(--amplify-colors-neutral-50);
  font-size: rem(16);
  font-weight: 500;
  line-height: rem(22);
  font-style: italic;
}

.required {
  color: var(--amplify-colors-neutral-40);
  font-size: rem(14);
  font-style: italic;
  font-weight: 400;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: rem(24);
  gap: rem(16);
}

.welcome {
  display: flex;
  width: 1000px;
  height: 718px;
  max-height: 100vh;

  .welcomeLeft {
    width: 40%;
    padding-right: rem(40);

    button {
      width: 100%;
    }

    p {
      margin-bottom: 0;
    }
  }

  .welcomeRight {
    position: relative;
    width: 600px;
    flex-grow: 1;
    overflow: hidden;
    margin: rem(-30) rem(-40) rem(-40) 0;
    background: #000;
    border-top-right-radius: rem(40);
    border-bottom-right-radius: rem(40);
  }

  .welcomeRightCopy {
    position: absolute;
    z-index: 2;
    left: rem(40);
    bottom: rem(60);
    margin-right: rem(40);
    max-width: rem(300);
    font-size: rem(16);
    line-height: normal;
    font-weight: 500;
    color: var(--amplify-colors-white);
  }

  .welcomeContinue {
    padding-top: rem(16);
    padding-bottom: rem(16);
  }

  .playPause,
  .close,
  .replay {
    background: var(--amplify-colors-light-4);
    color: var(--amplify-colors-light-40);
    mix-blend-mode: difference;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus {
      background: var(--amplify-colors-light-40);
      color: var(--amplify-colors-white);
      border: none;
      box-shadow: none;
    }
  }

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: rem(20);
    right: rem(20);
    width: rem(32);
    height: rem(32);
    padding: 0;
    margin: 0;
    border: 0;
    font-size: rem(12);
    line-height: normal;

    svg {
      width: rem(12);
    }
  }

  .replay,
  .playPause {
    position: absolute;
    z-index: 2;
    bottom: rem(60);
    right: rem(40);
    font-size: rem(15);

    svg {
      width: rem(14);
      height: rem(14);
      margin-right: rem(9);
    }
  }

  .replay {
  }

  .featureButtons {
    button {
      padding: rem(12);
      background: transparent;
      color: var(--amplify-colors-font-primary);

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border-color: transparent;
      }
    }

    .featureButtonIcon {
      font-size: rem(24);
      margin-right: rem(16);
      border-radius: rem(16);
      display: flex;
      width: rem(48);
      height: rem(48);
      justify-content: center;
      align-items: center;
    }

    .featurePink {
      .featureButtonIcon {
        color: var(--amplify-colors-gradients-pink-inverse);
        background: var(--amplify-colors-gradients-pink-70);
      }

      &.active,
      &:hover {
        background: var(--amplify-colors-pink-20);
      }
    }

    .featureRed {
      .featureButtonIcon {
        color: var(--amplify-colors-gradients-pink-inverse);
        background: var(--amplify-colors-gradients-red-70);
      }

      &.active,
      &:hover {
        background: var(--amplify-colors-red-20);
      }
    }

    .featureBlue {
      .featureButtonIcon {
        color: var(--amplify-colors-gradients-pink-inverse);
        background: var(--amplify-colors-gradients-blue-70);
      }

      &.active,
      &:hover {
        background: var(--amplify-colors-blue-20);
      }
    }
  }
}

.video {
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  border-radius: 40px;
  width: 90vw;
  max-width: 1040px;
  padding: rem(36) rem(36) rem(40);

  .player {
    z-index: 901;
    aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio for the video */
    width: 100%;
    max-height: 550px;

    iframe {
      border-radius: rem(24);
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr auto; /* Text on the left, buttons on the right */
    column-gap: rem(160);

    p {
      margin: 0;
      font-weight: 400;
      color: var(--amplify-colors-neutral-50);
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: rem(6);
  }

  .buttons {
    display: flex;
    z-index: 901;
    flex-direction: column;
    justify-content: center;
    gap: rem(4);
    padding: rem(4) 0;

    button {
      justify-content: left;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: auto;
  }

  .closeButton {
    position: absolute;
    top: 1px;
    right: 10px;
    background: none;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    pointer-events: auto;
    color: var(--amplify-colors-neutral-40);

    &:hover,
    &:focus,
    &:active {
      color: var(--amplify-colors-neutral-50);
    }
  }
}
