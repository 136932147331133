@import "src/components/legacy/common/colors.scss";

.terms-and-conditions-dialog {
	width: 860px;
	.terms-conditions-wrapper{
		margin-bottom: 8px;		
		font-weight: 700;
		h4 {	
			margin: 0;
		}
		ul{
			list-style-type: auto;
			padding-left: 14px;
		}
		ul > li {
			font-size:12px;
			margin-bottom: 20px;
            .highlighted-terms, a{
                font-size: 12px;
                color: $tba-darker-grey;
                text-decoration: underline;
				margin-left:3px;
            }
		}
		.checkbox-container  label{
			font-weight: bolder;
		}
	}	
}