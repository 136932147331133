aside.engery-load-analysis-chart-legend {
  max-width: 200px;
}

.load-analysis-time-slider{
  .tick .range-text{
    fill: black !important;
    font-size: 11px;
  }
}
