@import '../functions.scss';
@import './button.scss';
@import './menu.scss';
@import './fieldcontrol.scss';
@import './checkbox.scss';

input,
textarea,
[data-popper-reference-hidden] li {
  /* browser defaults to white on white with system dark mode set but dark theme not defined yet. */
  color: var(--amplify-colors-font-primary);
}
