html,
body {
  height: 100%;
}
body {
  min-width: 320px;
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #000;
}

input,
textarea {
  outline: none;
}

[data-amplify-theme="tc-theme"] label {
  display: inline-block;
  font-size: 13px !important;
  font-weight: 700 !important;
  letter-spacing: 0.26px;
  line-height: 15px !important;
}

.input_errro_border{
  border:1px solid rgb(213, 43, 30)
}

.disable-control{
  pointer-events: none;
  opacity: 0.4;
}