  @import "src/components/legacy/common/colors.scss";

.create-equipment-dialog {
    width: 880px;
  
    .edit-view {
      .edit-view__field {
        height: auto;
      }
  
      .select-wrapper {
        max-width: unset;
      }
    }
  }
.edit-view {

    .select-wrapper {
      max-width: unset;
    }
 }


.side-spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 10px;
  width: 50px;
}

.inputHolder{
  display: flex;
  align-items: center;
  gap: 10px;
}

.default-data-source,.default-data-source-type{
  width: -webkit-fill-available
}

.side-space{
  padding-left: 10px;
}
  
.left-column {
      .selectrix-container {
        width: 100%;
  
        .checkbox-container {
          display: inline-block;
  
          label {
            font-size: 12px;
          }
        }
  
        .icon-infocircle {
          padding-left: 5px;
        }
  
        .selectrix-wrapper {
          margin-top: 5px;
          // Commented it, until the "multiple" option is enabled.
          /*.react-selectrix:not(.rs-base-empty) {
            .rs-wrapper {
              .rs-header {
                .rs-toggle {
                  padding: 6px 32px 6px 6px;
  
                  .rs-selection {
                    margin: 6px 15px 6px 0;
                  }
                }
              }
            }
          }*/
        }
      }
  }
  
.right-column {
      border: 1px solid $tba-light-grey;
      border-radius: 3px;
      .selectrix-container,.parent-equipment {
        margin-left: -6px;
      }
      .info-text {
        padding: 0 0 10px 8px;
      }
  
      .info-text,
      .equipment-name-example {
        .custom-label {
          font-size: 12px;
          font-style: italic;
          color: $tba-darker-grey;
          letter-spacing: 0.02px;
          padding: 0;
        }
      }
  
      .copy-from {
        .from-equipment, .edit-view__error {
          padding-left: 20px;
        }
      }
  
      .append-prepend-text {
        label {
          letter-spacing: 0;
        }
      }
  
      .override-equipment-name-group, .override-parent-equipment-name-group {
        .checkbox-container {
          display: flex;
          align-items: center;
        }
      }
  }
    
.equipment-created-info {
        h2 {
          margin: 0;
        }
    
        .equipment-list {
          font-size: 12px;
          font-style: normal;
          line-height: 15px;
          padding-left: 20px;
        }
    
        .info-text {
          font-size: 12px;
          font-style: normal;
          font-weight: normal;
        }
  }
