@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/typography.scss";

.conditional-tooltip {
	display: flex;
	position: relative;

	.conditional-tooltip__children-wrapper {
		flex: 0 1 auto;
		max-width: 100%;
		min-width: 0%; // ie11 fix
		position: relative;

		.conditional-tooltip__ellipsis-wrapper {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			
			.conditional-tooltip__children--content {
				display: inline;
				
			}			
		}
	}
}

.conditional-tooltip__portal {
	pointer-events: none;
	position: fixed;
	z-index: 1000000;

	.conditional-tooltip__base {
		@include text-list-condensed;

		border-radius: 4px;
		box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
		display: block;
		line-height: 14px;
		margin: 0;
		position: absolute;
		white-space: nowrap;
		z-index: 9;

		&.conditional-tooltip__base--type- {
			&icon {
				@include text-field-label-secondary;

				background: $tba-darker-grey;
				color: $white;
				min-height: 24px;
				padding: 6px 10px;
				max-width: 350px;
			}

			&default {
				background: $tba-lightest-grey;
				border: 1px solid $tba-dark-grey;
				padding: 7px 9px;
			}
		}
		.conditional-tooltip__wrapper {
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: capitalize;
		}

		.conditional-tooltip__arrow {
			box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
			box-sizing: border-box;
			position: absolute;

			&::after {
				box-sizing: border-box;
				content: "";
				position: absolute;
				transform-origin: 0 0;
			}

			&.icon {
				&::after {
					border: 5px solid;
					border-color: transparent transparent $tba-darker-grey $tba-darker-grey;

				}
			}

			&.default {
				&::after {
					background-color: $tba-lightest-grey;
					border-bottom: 1px solid $tba-dark-grey;
					border-left: 1px solid $tba-dark-grey;
					height: 10px;
					width: 10px;
				}
			}
		}

		&.conditional-tooltip__base--multiline {
			white-space: normal;
			width: 260px;
			word-break: normal;
		}

		&.conditional-tooltip__base--position- {
			&bottom {
				bottom: auto;
				margin-top: 5px;
				top: 100%;

				.conditional-tooltip__arrow {
					bottom: auto;
					top: 0;

					&::after {
						transform: rotate(135deg) translate(-50%, -50%);
					}
				}
			}
			&left {
				left: auto;
				margin-right: 5px;
				right: 100%;

				.conditional-tooltip__arrow {
					left: auto;
					right: 0;

					&::after {
						transform: rotate(-135deg) translate(-50%, -50%);
					}
				}
			}
			&right {
				left: 100%;
				margin-left: 5px;
				right: auto;

				.conditional-tooltip__arrow {
					left: 0;
					right: auto;

					&::after {
						transform: rotate(45deg) translate(-50%, -50%);
					}
				}
			}
			&top {
				bottom: 100%;
				margin-bottom: 5px;
				top: auto;

				.conditional-tooltip__arrow {
					bottom: 0;
					top: auto;

					&::after {
						transform: rotate(-45deg) translate(-50%, -50%);
					}
				}
			}

			&center {
				&.conditional-tooltip__base--align- {
					&start {
						left: 0;
						right: auto;
						top: 50%;
						transform: translate(0, -50%);

						.conditional-tooltip__arrow {
							left: auto;
							right: 0;
							top: 50%;

							&::after {
								transform: rotate(-135deg) translate(-50%, -50%);
							}
						}
					}
					&center {
						left: 50%;
						right: auto;
						top: 50%;
						transform: translate(-50%, -50%);

						.conditional-tooltip__arrow {
							display: none;
						}
					}
					&end {
						left: auto;
						right: 0;
						top: 50%;
						transform: translate(0, -50%);

						.conditional-tooltip__arrow {
							left: 0;
							right: auto;
							top: 50%;

							&::after {
								transform: rotate(45deg) translate(-50%, -50%);
							}
						}
					}
				}
			}

			&left,
			&right {
				&.conditional-tooltip__base--align- {
					&start {
						bottom: auto;
						top: -10px;
						transform: translate(0, 0);

						.conditional-tooltip__arrow {
							bottom: auto;
							top: 15px;
						}
					}
					&center {
						bottom: auto;
						top: 50%;
						transform: translate(0, -50%);

						.conditional-tooltip__arrow {
							bottom: 50%;
							top: auto;
						}
					}
					&end {
						bottom: -10px;
						top: auto;
						transform: translate(0, 0);

						.conditional-tooltip__arrow {
							bottom: 15px;
							top: auto;
						}
					}
				}
			}

			&bottom,
			&top {
				&.conditional-tooltip__base--align- {
					&start {
						left: 0;
						right: auto;
						transform: translate(0, 0);

						.conditional-tooltip__arrow {
							left: 15px;
							right: auto;
						}
					}
					&center {
						left: 50%;
						right: auto;
						transform: translate(-50%, 0);

						.conditional-tooltip__arrow {
							left: 50%;
							right: auto;
						}
					}
					&end {
						left: auto;
						right: 0;
						transform: translate(0, 0);

						.conditional-tooltip__arrow {
							left: auto;
							right: 15px;
						}
					}
				}
			}
		}

		&.conditional-tooltip__base--hidden {
			display: none;
		}
	}
}
