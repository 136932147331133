@import "src/components/legacy/common/colors.scss";

input[type="radio"] {
	position: absolute;
	opacity: 0;
	left: 0;
	visibility: hidden;

	& + label {
		position: relative;
		margin: 0;
		padding: 0 0 0 20px;
		cursor: pointer;
	}
	& + label::before {
		content: "";
		position: absolute;
		left: 0;
		width: 14px;
		height: 14px;
		border: 1px solid $tba-dark2-grey;
		border-radius: 50%;
	}
	&:not(:checked) + label::before {
		background: $tba-lightest-grey;
	}

	&:checked + label::before {
		background: $tba-green;
	}
	&:checked + label::after {
		content: "";
		position: absolute;
		top: 5px;
		left: 5px;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: $white;
	}
}
fieldset[disabled] input[type="radio"], /* for IE */
input[type="radio"]:disabled {
	& + label {
		color: $tba-dark2-grey;
		cursor: not-allowed;
	}
	&:not(:checked) + label::before {
		background: $tba-light-grey;
		border-color: $tba-medium-grey;
	}
	&:checked + label::before {
		background: $tba-medium-grey;
		border-color: $tba-medium-grey;
	}
}
