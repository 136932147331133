

.widget-preview-main {
    height: 330px;
}
.widget-preview-modal-container {
    max-width: 380px;
}

.widget-list-preview {
    min-height: 0px !important;
}

.widget-list-preview-container {
    height: 0px !important;
}