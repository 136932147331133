@mixin betaImage($top, $left, $background) {
    height: 21px;
    width: 41px;
    position: absolute;
    left: $left;
    top: $top;
    transform: skew(-17deg);
    background: $background;
    & + img {
        position: absolute;
        left: $left - 5px;
        top: $top - 15;
        height: 51px;
        width: 51px;
    }
}

@mixin dropdownArrowAnimation($openedClassName, $animationDuration: 200ms) {
    transition: $animationDuration transform ease-in-out;

    #{$openedClassName} {
        transform: rotate(180deg);
    }
}

@mixin dropdownAnimation($openedClassName, $maxHeight, $animationDuration: 200ms) {
    transition: max-height $animationDuration ease-in-out, opacity 20ms linear $animationDuration;
    overflow: hidden;
    max-height: 0;
    opacity: 0;

    #{$openedClassName} {
        max-height: $maxHeight;
        opacity: 1;
        transition-delay: 0ms, 0ms;
    }
}
