@import '../functions.scss';

.userNameBubble {
  display: flex;
  gap: rem(12);
  align-items: center;
  width: 100%;

  .text {
    overflow: hidden;
  }

  .name {
    margin-bottom: rem(4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subLine {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(14);
    line-height: 1.28571;
    font-style: normal;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.onDark .subLine {
    color: var(--amplify-colors-neutral-40);
  }

  &.isSmall {
    .name {
      font-size: rem(14);
    }

    .subLine {
      font-size: rem(12);
    }
  }

  &.iconOnly {
    width: auto;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    background: var(--amplify-colors-gradients-turquoise-50);
    color: var(--amplify-colors-gradients-turquoise-inverse);
    font-size: rem(14);
    line-height: 1.28571;
    font-style: normal;
    font-weight: 500;
    width: rem(36);
    height: rem(36);
    border-radius: 100px;
    position: relative;

    &[data-color='turquoise'] {
      background: var(--amplify-colors-gradients-turquoise-50);
      color: var(--amplify-colors-gradients-turquoise-inverse);
    }
    &[data-color='orange'] {
      background: var(--amplify-colors-gradients-orange-50);
      color: var(--amplify-colors-gradients-orange-inverse);
    }
    &[data-color='pink'] {
      background: var(--amplify-colors-gradients-pink-50);
      color: var(--amplify-colors-gradients-pink-inverse);
    }
    &[data-color='red'] {
      background: var(--amplify-colors-gradients-red-50);
      color: var(--amplify-colors-gradients-red-inverse);
    }
    &[data-color='green'] {
      background: var(--amplify-colors-gradients-green-50);
      color: var(--amplify-colors-gradients-green-inverse);
    }
    &[data-color='blue'] {
      background: var(--amplify-colors-gradients-blue-50);
      color: var(--amplify-colors-gradients-blue-inverse);
    }
    &[data-color='purple'] {
      background: var(--amplify-colors-gradients-altpurple-50);
      color: var(--amplify-colors-gradients-altpurple-inverse);
    }
  }

  .assignedTo {
    background-color: var(--amplify-colors-neutral-10);
    border-radius: 50%;
    color: var(--amplify-colors-neutral-90);
    height: rem(24);
    width: rem(24);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: rem(-8);
    right: rem(-8);

    svg {
      width: rem(16);
      height: rem(16);
    }
  }
}
