@import "./typography.scss";

//variables and mixins
	$tooltip-border-width: 1px;
	$tooltip-arrow-height: 7px;
	$tooltip-arrow-shift: 5px;

.tooltip-container {
	position: relative;

	& .tooltip {
		display: none;
		opacity: 0;
		transition: opacity 0.4s ease;
	}

	&:hover .tooltip, &:focus .tooltip:not(.dark) {
		display: block;
		opacity: 1;
	}

	&:hover[disabled] .tooltip {
		display: none;
	}

	&.tooltip-container--multiline {
		position: static;

		.tooltip {
			&.right {
				top: auto;
				left: auto;
				margin-left: 20px;
				margin-top: -6px;
				white-space: normal;
			}
		}
	}
}


.tooltip {
	@include text-list-condensed;
	position: absolute;
	z-index: 20;
	white-space: nowrap;
	color: $black;
	padding: 10px;
	border-radius: 4px;
	line-height: 14px;
	text-align: left;
	background-color: $tba-lightest-grey;
	border: $tooltip-border-width solid $tba-dark2-grey;
	box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);

	&.medium {
		width: 260px;
		white-space: normal;
		word-wrap: break-word;
	}

	&.dark {
		color: $white;
		background-color: $tba-darker-grey;
		padding: 0 10px;
		line-height: 24px;
		border-color: $tba-darker-grey;

		&::after {
			display: none;
		}
	}

	& .wrap-text {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	& .tooltip_section + .tooltip_section { margin-top: 10px; }

	& .tooltip_section,
	& .tooltip_section > * { display: block; }

	& .tooltip_section_hd {
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: bold;
	}

	&::after, &::before {
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&::after {
		border-width: $tooltip-arrow-height - $tooltip-border-width;
	}

	&::before {
		border-width: $tooltip-arrow-height;
	}


	/* Top arrows /\  /\  /\ */

	&.left-top, &.right-top, &.center-top {
		bottom: 100%;
		margin-bottom: $tooltip-arrow-shift;
	}

	&.left-top {
		right: 50%;
		margin-right: -$tooltip-arrow-shift - $tooltip-arrow-height;

		&::after, &::before {
			top: 100%;
			right: $tooltip-arrow-shift;
		}

		&::after {
			border-top-color: $tba-lightest-grey;
			margin: 0 $tooltip-border-width;
		}

		&::before {
			border-top-color: $tba-dark2-grey;
		}
		&.dark::before {
			border-top-color: $tba-darker-grey;
		}
	}

	&.right-top {
		left: 50%;
		margin-left: -$tooltip-arrow-shift - $tooltip-arrow-height;

		&::after, &::before {
			top: 100%;
			left: $tooltip-arrow-shift;
		}

		&::after {
			border-top-color: $tba-lightest-grey;
			margin: 0 $tooltip-border-width;
		}

		&::before {
			border-top-color: $tba-dark2-grey;
		}
		&.dark::before {
			border-top-color: $tba-darker-grey;
		}
	}

	&.center-top {
		left: 50%;
		transform: translate(-50%, 0);

		&::after, &::before {
			top: 100%;
			left: 50%;
		}

		&::after {
			border-top-color: $tba-lightest-grey;
			margin-left: -$tooltip-arrow-height + $tooltip-border-width;
		}

		&::before {
			border-top-color: $tba-dark2-grey;
			margin-left: -$tooltip-arrow-height;
		}
		&.dark::before {
			border-top-color: $tba-darker-grey;
		}
	}

	/* Left/right arrows < > */

	&.left, &.right {
		top: 50%;
		transform: translate(0, -50%);
	}

	&.left {
		right: 100%;
		margin-right: $tooltip-arrow-shift;

		&::after, &::before {
			top: 50%;
			left: 100%;
		}

		&::after {
			border-left-color: $tba-lightest-grey;
			margin-top: -$tooltip-arrow-height + $tooltip-border-width;
		}

		&::before {
			border-left-color: $tba-dark2-grey;
			margin-top: -$tooltip-arrow-height;
		}
		&.dark::before {
			border-left-color: $tba-darker-grey;
		}
	}

	&.right {
		left: 100%;
		margin-left: $tooltip-arrow-shift;

		&::after, &::before {
			top: 50%;
			right: 100%;
		}

		&::after {
			border-right-color: $tba-lightest-grey;
			margin-top: -$tooltip-arrow-height + $tooltip-border-width;
		}

		&::before {
			border-right-color: $tba-dark2-grey;
			margin-top: -$tooltip-arrow-height;
		}
		&.dark::before {
			border-right-color: $tba-darker-grey;
		}
	}

	/* Bottom arrows \/  \/  \/ */

	&.left-bottom, &.right-bottom, &.center-bottom {
		top: 100%;
		margin-top: $tooltip-arrow-shift;
	}

	&.left-bottom {
		right: 50%;
		margin-right: -$tooltip-arrow-shift - $tooltip-arrow-height;

		&::after, &::before {
			bottom: 100%;
			right: $tooltip-arrow-shift;
		}

		&::after {
			border-bottom-color: $tba-lightest-grey;
			margin: 0 $tooltip-border-width;
		}

		&::before {
			border-bottom-color: $tba-dark2-grey;
		}
		&.dark::before {
			border-bottom-color: $tba-darker-grey;
		}
	}

	&.right-bottom {
		left: 50%;
		margin-left: -$tooltip-arrow-shift - $tooltip-arrow-height;

		&::after, &::before {
			bottom: 100%;
			left: $tooltip-arrow-shift;
		}

		&::after {
			border-bottom-color: $tba-lightest-grey;
			margin: 0 $tooltip-border-width;
		}

		&::before {
			border-bottom-color: $tba-dark2-grey;
		}
		&.dark::before {
			border-bottom-color: $tba-darker-grey;
		}
	}

	&.center-bottom {
		left: 50%;
		transform: translate(-50%, 0);

		&::after, &::before {
			bottom: 100%;
			left: 50%;
		}

		&::after {
			border-bottom-color: $tba-lightest-grey;
			margin-left: -$tooltip-arrow-height + $tooltip-border-width;
		}

		&::before {
			border-bottom-color: $tba-dark2-grey;
			margin-left: -$tooltip-arrow-height;
		}
		&.dark::before {
			border-bottom-color: $tba-darker-grey;
		}

		&.right-asset {
			&::before {
				margin-left: 0;
			}

			&::after {
				margin-left: 1px;
			}
		}
	}
}
