@import "../../../common/scss/typography.scss";
@import "../../../common/scss/grid.scss";

.user-management {
	min-height: 100%;
	padding: 20px 20px 50px 20px;

	.user-management-table {
		.ellipsis-row {
			.table-column__value {
				white-space: nowrap;
				overflow-x: hidden !important;
				text-overflow: ellipsis;
			}
		}

		.user-list-action-wrapper {
			display: flex;
		}
		& .last-name {
			width: 14%;
		}
		& .first-name,
		& .organizations,
		& .sales-offices {
			width: 13%;
		}
		& .email,
		& .locations {
			width: 13%;
		}
		& .roles,
		& .status {
			width: 8%;
		}
		& .created-on {
			width: 11%;
		}
		& .organizations,
		& .locations,
		& .sales-offices {
			display: table-cell;
		}
		& .actions {
			width: 115px;
		}
	}

	.user-management-table-small {
		.ellipsis-row {
			white-space: nowrap;
			overflow-x: hidden !important;
			text-overflow: ellipsis;
		}
	}
	.tooltip-overflow{
		position:absolute;
		margin-top:3px;
	}
	.tooltip-wrapper {
		position: relative;
		&:hover {
			.tooltip-base {
				display: inline-block;
			}
		}
		.tooltip-anchor {
			white-space: nowrap;
			overflow-x: hidden !important;
			text-overflow: ellipsis;
		}

		button {
			display: block;
			color: $tba-green;
			font-weight: 700;
			text-decoration: underline;
			cursor: pointer;
			border: none;
		}
	}

	.form-email-input {
		padding: 20px 0px;
		display: flex;
		align-items: center;
  		justify-content: center;
		.label {
			font-size: 15px;
			margin-right: 10px;
		}


		input {
			margin-right: 20px;
			width:500px
		}

		@media (max-width: 768px) {
			input {
				margin-right: 20px;
				width:300px
			}
		}
		@media (max-width: 600px) {
			input {
				margin-right: 5px;
				width:180px
			}
		}
		@media (max-width: 375px) {
			flex-direction: column;
			align-items: flex-start;
			input {
				margin-right: 5px;
				width:80%
			}
			div {
				width: 100%;
				padding-bottom: 5px;
			}
		}
	}

	.header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title-wrapper {
			.title {
				white-space: normal;
			}
		}
	}

	hr {
		height: 2px;
		border: none;
		margin: 0;
		background-color: $tba-darker-grey;
	}

	.filter-component.users-filter {
		padding: 20px 0;
		.search-box {
			width: 294px;
		}
	}

	.tiny {
		margin: 0 5px;
		color: $tba-darker-grey;
		background-color: transparent;
		width: 28px;
		font-size: 16px;
		border: none;
	}

	.tiny[disabled] {
		color: $tba-light-grey;
	}

	.user-management-list-footer {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-top: 10px;
		padding-right: 10px;
	}

	@include screen-max-extra-small {
		.filter-component.users-filter {
			.filter-component__description {
				display: none;
			}
			.search-box {
				width: 100%;
			}
			.select-wrapper {
				min-width: 100px;
			}
		}

		.tooltip-wrapper {
			.tooltip-base {
				width: 150px;
			}
		}
		.conditional-tooltip__portal {
			max-width: 150px;
		}

		.tiny {
			margin: 0 2px;
			width: 23px;
			font-size: 15px;
		}
	}

	@include screen-small-to-max-small {
		.tooltip-wrapper {
			.tooltip-base {
				width: 200px;
			}
		}

		.tiny {
			margin: 0 3px;
			width: 26px;
		}
	}
}


.user-dialog {
	.active-tab-content {
		height: 500px;
		padding: 0;
		.tab-wrapper {
			height: 100%;
			padding: 10px;
			overflow-y: auto;
			overflow-x: hidden;

			.user-data-tab {
				display: flex;
				.column {
					flex: 50%;
					fieldset:nth-child(2n) {
						padding-bottom: 40px;
					}
					fieldset.valid-indicator input[type=email] {
						width: 100%;
					}
					.employee-radio-input {
						padding: 15px 40px;
					}
				}
			}

			.object-picker-tab {
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: row;

				> .column {
					padding: 0 20px;
					width: 50%;
					h2 {
						margin-bottom: 0;
						span {
							color: $tba-medium-green;
						}
					}
					p {
						margin-top: 0;
						font-style: italic;
						span {
							color: $red;
						}
					}
				}

				.object-picker {
					height: 350px;
					min-height: 350px;
				}
				.permission-object-picker{
					width: 260px;
					margin: 0 auto;
					.select-by-section{
						margin: 8px 0 20px;
					}
				}

				.roles {
					border-right: 1px solid $tba-light-grey;
					display: flex;
					flex-direction: column;

					.roles-picker {
						overflow-y: auto;
						p {
							font-style: normal;
							font-weight: bold;
						}
					}
				}

				.roles-selector-container {
					overflow-y: auto;
					max-height: 400px;
					position: relative;
					
					h3 {
						margin: 10px 0;
						font-weight: bold;
					}

					.roles-selector {
						.list-selector {
							border-radius: 2px;
							background-color: $white;

							ul {
								margin: 0;
								padding: 0;
								list-style: none;

								li {
									min-height: 20px;

									label {
										@include text-list-condensed
									}

									div {
										padding-left: 20px;
										@include text-list-condensed
									}
								}
							}
						}

						.disabled {
							.list-selector {
								background-color: $tba-light-grey;
								border-color: $tba-medium-grey;
							}

							.secondary {
								display: none;
							}

							legend {
								color: $tba-dark-grey;
							}
						}

						&.invalid {
							& .list-selector {
								background-color: $red-1;
								border-width: 2px;
								border-color: $red;
							}

							& ul li:nth-child(even) {
								background-color: $red-1;
							}

							& legend {
								color: $red;
							}
						}

						p.error {
							visibility: visible;
							height: auto;
						}
					}
				}
			}
		}
	}
	.edit-user {
		padding: 20px;
		border-top: 1px solid $white;
		border-bottom: 1px solid $tba-light-grey;
	}

	.selectbox {
		width: 100%;
	}

	.icon-close {
		right: 0;
		font-size: 12px;
	}

	& dialog {
		fieldset.valid-indicator.valid .spinner {
			display: inline-block;
		}
	}

	.tabgroup input[type=radio].tab-checkbox + .tab-button {
		cursor: pointer;
	}

	.dialog-buttons .create-another {
		margin-left: 12px;
		line-height: 54px;
	}

	.dialog-buttons .save-notification {
		width: 100%;
		margin-top: -15px;
		text-align: right;

		.notification-text {
			margin-left: 9px;
			@include text-form-error;
			color: $tba-medium-green;
		}

		.icon {
			color: $tba-green;
			font-size : 22px;
		}
	}

	@include screen-max-small {
		.active-tab-content {
			.tab-wrapper {
				.object-picker-tab {
					flex-wrap: wrap;

					.column {
						width: 100%;
					}

					.roles{
						border-right: none;
					}

					.roles-selector-container {
						max-height: 100%;
						overflow-y: unset;
					}
				}

			}
		}
	}
}

@include screen-max-extra-small {
	dialog.user-dialog {
		width: 320px;
		
		.edit-user {
			padding: 0;

			.active-tab-content {
				height: auto;

				.tab-wrapper {
					.user-data-tab {
						display: block;

						.column .employee-radio-input {
							padding-left: 0;
						}
					}

					.object-picker-tab .permission-object-picker {
						width: 200px;
					}
				}
			}
		}

		.tabgroup input[type=radio].tab-checkbox + .tab-button {
			flex: 0 36px;
		}

		.dialog-buttons .create-another {
			line-height: 34px;
		}
		.dialog-buttons .save-notification {
			margin-top: 5px;
		}

	}
}

.applications-and-roles-card {
	padding-bottom: 10px;
}
