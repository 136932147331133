@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/grid.scss";
@import "src/components/legacy/common/custom-media-queries.scss";

.kpi,
.kpi-edit {
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 10.65px;
		bottom: 0;
		left: 0;
		border-radius: 0 0 6px 6px;
		background: $grey;
	}
}
.kpi {
	min-width: 140px;
	padding: 20px 20px 26px;
	font-size: 14px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: space-between;
	align-content: space-between;
	flex: auto;
	background: $white;
	border-radius: 6px;
	border: 1px solid $tba-light-grey;
	position: relative;
	@include styles-on-print {
		width: 32.6% !important;
	}

	.kpi-icons {
		margin: 20px 0;
	}

	.priority {
		font-weight: bold;
		padding: 0;
		text-align: center;
		display: block;
		border: none;
		font-size: 24px;
	}

	.name {
		text-align: center;
		word-break: normal;
		text-overflow: ellipsis;
		max-width: 100%;
	}
}
.kpi-block {
	padding: 0;
}

// EDIT MODE
.list-kpi-metric {
	&.edit-mode {
		.kpi-edit {
			min-height: 94px;
			padding: 16px 34px 16px;
			display: flex;
			flex-flow: column;
			position: relative;
			background: $white;
			border-radius: 6px;
			border: 1px solid $tba-light-grey;

			.checkbox-container {
				margin-bottom: 10px;
			}

			.impact, .custom-name {
				margin-bottom: 3px;
			}

			input {
				margin: 0;
				width: 100%;
			}

			.select-wrapper {
				align-self: flex-start;
				min-width: 100%;
				width: 100%;

			}

			.spefic-value {
				display: flex;
				margin-top: 15px;

				> div:first-child {
					display: flex;
					@include screen-max-extra-small {
						margin-top: 15px;
					}
				}
				@include screen-max-extra-small {
					flex-direction: column-reverse;
				}

				label {
					align-self: center
				}

				input[type=number] {
					width: 100%;
					margin-right: 5%;
					margin-left: 3%;
					@include screen-max-extra-small {
						margin-right: 0;
					}
				}

				.select-wrapper {
					min-width: 95px;
					@include screen-max-extra-small {
						width: 100%
					}
				}
			}

			@include screen-max-small {
				padding: 16px 10px;
			}
		}

		fieldset {
			margin: 0;
			padding: 0 0 10px;
			border:none;
		}

		@include screen-large {
			.kpi-edit {
				padding: 16px 25px;

				.select-wrapper {
					.control {
						width: 100%;
					}

					.select-dropdown {
						width: 170px;
					}
				}
			}
		}

		@include screen-max-extra-small {
			.kpi-edit {
				.select-wrapper,
				.control,
				input,
				label {
					min-width: auto;
					width: 100%;
				}
			}
		}
	}
}


@include screen-max-small {
	.list-kpi-metric {
		margin-bottom: 30px;

		.kpi {
			min-height: 84px;
			flex: none;

			.priority {
				width: 100%;
			}
		}
	}
}

@include screen-medium-to-max-medium {
	.kpi {
		min-height: 84px;
		.priority {
			width: 100%;
		}
	}
}
