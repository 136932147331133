@import '../functions.scss';

[data-amplify-theme='denali-theme'] {
  .attachedFiles,
  .filesError {
    width: 100%;

    section {
      border: 0;
      padding: 0;
      flex-grow: 1;
    }
  }

  .dropZoneLabel {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(18);
    font-weight: 500;
    line-height: rem(22);
    margin-bottom: rem(8);
  }

  .dropZone {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 2px dashed var(--amplify-colors-neutral-20);
    border-radius: rem(16);
    cursor: pointer;
    min-height: rem(195);
    text-align: center;
    vertical-align: middle;

    & + .dropZoneLabel {
      margin-top: rem(16);
    }

    & + div[class*='fileBadge'] {
      margin-top: rem(12);
    }

    svg {
      color: var(--amplify-colors-black);
      width: rem(24);
      height: rem(24);
      margin-bottom: rem(12);
    }

    .dropZoneText {
      color: var(--amplify-colors-neutral-90);
      font-size: rem(16);
      font-weight: 500;
      line-height: rem(22);
    }

    .fileExtensions {
      color: var(--amplify-colors-neutral-50);
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
    }

    .clickHere {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  :global(.amplify-dropzone--accepted) {
    .dropZoneText {
      color: var(--amplify-colors-neutral-90);
    }

    svg {
      color: var(--amplify-colors-neutran-90);
    }
  }

  :global(.amplify-dropzone--rejected) {
    .dropZoneText {
      color: var(--amplify-colors-red-50);
    }

    svg {
      color: var(--amplify-colors-red-50);
    }
  }

  .filesError {
    .dropZone {
      border-color: var(--amplify-colors-red-50);

      .dropZoneText {
        color: var(--amplify-colors-red-50);
      }

      svg {
        color: var(--amplify-colors-red-50);
      }
    }
  }

  .error,
  .duplicateError {
    background-color: var(--amplify-colors-red-lightest);
    border-radius: rem(16);
    color: var(--amplify-colors-neutral-90);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
    margin-bottom: rem(12);
    padding: rem(12) rem(24);
    display: flex;
    justify-content: flex-start;

    svg {
      color: var(--amplify-colors-red-50);
      margin-right: rem(8);
    }
  }

  .descriptionField {
    display: block;
  }

  .descriptionFieldHidden {
    display: none;
  }
}
