@import 'src/denali-components/functions.scss';

.title {
	margin-bottom: rem(8);
}

.dateContainer {
	display: flex;
	flex-direction: row;
	gap: rem(32);
}

.dateInfo {
	display: flex;
	flex-direction: column;
	gap: rem(4);

	.subline,
	.text {
		margin: 0;
	}
}

.subline {
	color: var(--amplify-colors-font-secondary);
	font-size: rem(14);
	line-height: 1.28571;
}

.statusRow {
	display: flex;
	gap: rem(12);
	justify-content: flex-end;
	width: 100%;
}

.assigned {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	gap: rem(4);
	overflow: hidden;

	.subline,
	.text {
		margin: 0;
	}

	.text {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.userBubble {
	display: flex;
	gap: rem(12);
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	background: var(--amplify-colors-gradients-turquoise-50);
	color: var(--amplify-colors-gradients-turquoise-inverse);
	font-size: rem(14);
	line-height: 1.28571;
	font-style: normal;
	font-weight: 500;
	width: rem(36);
	height: rem(36);
	border-radius: 100px;

	&[data-color='turquoise'] {
		background: var(--amplify-colors-gradients-turquoise-50);
		color: var(--amplify-colors-gradients-turquoise-inverse);
	}

	&[data-color='orange'] {
		background: var(--amplify-colors-gradients-orange-50);
		color: var(--amplify-colors-gradients-orange-inverse);
	}

	&[data-color='pink'] {
		background: var(--amplify-colors-gradients-pink-50);
		color: var(--amplify-colors-gradients-pink-inverse);
	}

	&[data-color='red'] {
		background: var(--amplify-colors-gradients-red-50);
		color: var(--amplify-colors-gradients-red-inverse);
	}

	&[data-color='green'] {
		background: var(--amplify-colors-gradients-green-50);
		color: var(--amplify-colors-gradients-green-inverse);
	}

	&[data-color='blue'] {
		background: var(--amplify-colors-gradients-blue-50);
		color: var(--amplify-colors-gradients-blue-inverse);
	}

	&[data-color='purple'] {
		background: var(--amplify-colors-gradients-altpurple-50);
		color: var(--amplify-colors-gradients-altpurple-inverse);
	}
}

.quickAddText {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.quickAddForm {
	position: relative;
	padding-bottom: rem(20);

	.quickAddFormElements {
		// This combo of margin/padding is to make sure the border does not touch
		// the left side of the component but it SHOULD touch the right side. Margin
		// affects borders and padding does not.
		padding-top: rem(20);
		padding-right: rem(20);
		margin: 0 0 0 rem(20);
		border-top: 1px solid var(--amplify-colors-neutral-20);

		.quickAddFormRow {
			width: 100%;
		}
	}

	textarea {
		min-height: 135px;
	}

	select {
		height: 44px;
	}

	sup {
		vertical-align: text-top;
		font-size: rem(16);
	}

	[class*='amplify-input'] {
		height: 44px;
		font-size: rem(14);

		&::placeholder {
			color: var(--amplify-colors-neutral-50);
		}
	}

	// Overlay elements don't have borders and we cannot clip because the equipment selector will get cut off.
	[class*='denaliFormDisabledOverlay'],
	[class*='loaderOverlay'] {
		border-bottom-right-radius: rem(20);
		border-bottom-left-radius: rem(20);
	}
}

.quickAddCollapsible {
	margin-bottom: rem(40);

	.quickAddHeaderButtons {
		.quickAddSaveButton {
			&[class*='amplify-button--small'] {
				background-color: var(--amplify-colors-neutral-100);
				color: var(--amplify-colors-white);
			}
		}
	}
}

.fileWrapperOverride {
	width: 100%;

	.fileUploadContainer {
		display: flex;
		flex-direction: column;
		gap: rem(12);

		.topRow {
			display: flex;
			flex-direction: row;
			border: 1px solid var(--amplify-colors-neutral-20);
			border-radius: rem(12);
			padding: rem(16);
			gap: rem(12);
			align-items: center;

			.chooseFile {
				padding: rem(12) rem(24);
				border: 1px solid var(--amplify-colors-neutral-20);
				border-radius: rem(12);
				margin-right: auto;
				min-width: max-content;
				color: var(--amplify-colors-neutral-90);
				font-size: rem(14);
				font-weight: 500;
				line-height: rem(20);
				user-select: none;
				cursor: pointer;
			}
		}

		.descriptionLabel {
			color: var(--amplify-colors-neutral-90);
			font-size: rem(14);
			font-weight: 500;
			line-height: rem(20);
		}
	}
}

.dialogWrapper {
	overflow: hidden;

	.searchContainer {
		width: 100%;
		position: relative;
		display: inline-flex;

		svg {
			position: absolute;
			z-index: 2;
			top: rem(14);
			left: rem(14);
			width: rem(14);
			height: rem(14);

			path {
				fill: var(--amplify-components-denali-table-filter-color);
			}
		}

		input.searchInput {
			// display: inline-flex;
			padding-inline-start: rem(40);
		}
	}

	.scrollContainer {
		margin-top: rem(12);
		padding: 0 rem(4);
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		gap: rem(12);
		max-height: rem(400);

		.userOptionWrapper {
			// link variation looks good so far...
		}
	}
}

button.buttonWrapper {
	background: var(--amplify-colors-white);
	color: var(--amplify-colors-black);
	border: 1px solid var(--amplify-colors-neutral-20);
	padding-left: rem(16);
	padding-right: rem(16);
}

button.buttonWrapper>*+* {
	display: inline-flex;
	justify-content: space-between;
}

.spin {
	animation-name: spin;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.quickAddText {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.quickAddForm {
	position: relative;
	padding-bottom: rem(20);

	.quickAddFormElements {
		// This combo of margin/padding is to make sure the border does not touch
		// the left side of the component but it SHOULD touch the right side. Margin
		// affects borders and padding does not.
		padding-top: rem(20);
		padding-right: rem(20);
		margin: 0 0 0 rem(20);
		border-top: 1px solid var(--amplify-colors-neutral-20);

		.quickAddFormRow {
			width: 100%;
		}
	}

	textarea {
		min-height: 135px;
	}

	select {
		height: 44px;
	}

	sup {
		vertical-align: text-top;
		font-size: rem(16);
	}

	[class*='amplify-input'] {
		height: 44px;
		font-size: rem(14);

		&::placeholder {
			color: var(--amplify-colors-neutral-50);
		}
	}

	// Overlay elements don't have borders and we cannot clip because the equipment selector will get cut off.
	[class*='denaliFormDisabledOverlay'],
	[class*='loaderOverlay'] {
		border-bottom-right-radius: rem(20);
		border-bottom-left-radius: rem(20);
	}
}

.quickAddCollapsible {
	margin-bottom: rem(40);

	.quickAddHeaderButtons {
		.quickAddSaveButton {
			&[class*='amplify-button--small'] {
				background-color: var(--amplify-colors-neutral-100);
				color: var(--amplify-colors-white);
			}
		}
	}
}

.addToOpportunityModal {
	[class*='dialog_title_'] {
		margin-bottom: rem(16);
	}

	[class*='opportunities-table'] {
		header {
			border-top: rem(1) solid var(--amplify-colors-neutral-20);
			border-bottom: none;
			padding: rem(16) 0 0 0;
			height: auto;
		}

		table {
			thead {
				tr {
					// To account for the checkbox.
					th {
						&:first-child {
							padding-left: rem(40);
						}
					}
				}
			}
		}
	}
}