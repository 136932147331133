@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/emerald-icons.scss";

.dropdown-tree-wrapper,
.checkbox-tree-nested-select {
	.react-dropdown-tree-select {
		.tag {
			display: inline-flex;
			flex-direction: row-reverse;
			align-items: center;
			height: auto;
			padding: 4px;
			margin: 1px;
			line-height: 10px;
			border: 1px solid $tba-dark-grey;
			border-radius: 6px;
			font-size: 10px;
			background-color: $tba-almost2-white;
			color: $black;
			cursor: auto;

			&:focus-within {
				background-color: #e9e9e9;
				border-color: #a0a0a0;
			}
		}
		.tag-remove {
			position: relative;
			flex: 1;
			min-width: 13px;
			height: 13px;
			margin-right: 3px;
			border: none;
			outline: none;
			font-size: 0;

			&:after {
				content: "×";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				line-height: 12px;
				color: $tba-lighter-green;
				background-color: $black;
				border-radius: 50%;
				font-size: 16px;
				font-weight: bold;
				visibility:visible;
				transition: background-color 0.2s ease-in-out;
			}

			&:hover {
				&:after {
					background-color: $tba-dark-grey;
				}
			}

			&.disabled,
			&.readOnly {
				cursor: not-allowed;
			}
		}

		.node {
			display: flex;
			padding: 0;

			& > label {
				position: relative;
				margin: 0;
				padding: 0 0 0 20px;
				cursor: pointer;

				&::before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					width: 14px;
					height: 14px;
					border: 1px solid $tba-dark2-grey;
					border-radius: 2px;
				}

				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 3px;
					font-family: "Emerald" !important;
					font-size: 8px;
					font-weight: normal;
					border-radius: 2px;
					line-height: 14px;
					color: $white;
				}
			}

			&.checked {
				& > label::before {
					background-color: $tba-green;
				}

				& > label::after {
					content: map_get($icons, check);
				}
			}
		}
		.tag-list {
			display: inline-block;
			width: calc(100% - 28px);
			min-height: 24px;
			margin: 0 7px 0 0;
			padding: 1px 3px 1px 1px;
			border-right: 1px solid $tba-dark2-grey;
		}
		.tag-item {
			display: inline-block;
			margin: 1px;

			.placeholder {
				margin: 0 6px;
				font-size: 12px;
				font-weight: bold;
				color: $tba-dark-grey;
			}
		}
		.tag-item .search {
			border: none;
			border-bottom: 1px solid $tba-light-grey;
			outline: none;
		}
		.tag-item:last-child {
			margin-right: 4px;
		}

		.node {
			list-style: none;
			padding: 4px;
		}
		.node.leaf.collapsed {
			display: none;
		}
		.node.disabled > * {
			color: $tba-medium-dark-grey;
			cursor: not-allowed;
		}
		.node.match-in-children.hide .node-label {
			opacity: .5;
		}
		.node.focused {
			background-color: $tba-lightest-grey;
		}
		.toggle {
			white-space: pre;
			margin: 0 4px;
			cursor: pointer;
			font-style: normal;
			transition-duration: 400ms;
			transition-property: transform;

			&:focus {
				outline: none;
			}
			&:after {
				content: map_get($icons, toggledown);
				font-size: 10px;
				font-family: "Emerald" !important;
			}
			&.expanded {
				transform: rotate(-180deg);
			}
		}
		.searchModeOn .toggle {
			display: none;
		}
		.checkbox-item,
		.radio-item {
			vertical-align: middle;
			margin: 0 4px 0 0;
		}
		.checkbox-item.simple-select,
		.radio-item.simple-select {
			display: none;
		}

		.hide:not(.match-in-children) {
			display: none;
		}
		.dropdown {
			position: relative;
			display: table;
			width: 100%;

			.dropdown-trigger {
				max-height: 200px;
				display: inline-flex;
				align-items: center;
				overflow: auto;
				width: 100%;
				padding: 0;
				line-height: 20px;
				background: $tba-lightest-grey;
				border: 1px solid $tba-dark2-grey;
				border-radius: 2px;
				color: $tba-dark-grey;

				&:focus {
					outline: none;
				}

				&.arrow {
					cursor: pointer;
					vertical-align: middle;

					&:after {
						content: map_get($icons, expand);
						display: inline-block;
						transition-duration: 400ms;
						transition-property: transform;
						transform: rotate(0);
						color: $black;
						font-family: "Emerald" !important;
					}

					&.top {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;

						&::after {
							transform: rotate(180deg);
						}
					}

					.disabled {
						cursor: not-allowed;
						.bottom:after {
							color: #b9b9b9;
						}
					}
				}
			}

			.dropdown-content {
				width: 100%;
				height: auto;
				min-height: 36px;
				max-height: 340px;
				overflow: auto;
				position: absolute;
				padding: 4px 0;
				background-color: $tba-lightest-grey;
				border: 1px solid $tba-dark2-grey;
				border-top: none;
				z-index:1;

				.search {
					width: 100%;
					height: 22px;
					margin: 4px 0 6px;
					padding: 0 6px;
					line-height: 14px;
					outline: none;
					box-shadow: none;
					color: $black;
					font-size: 12px;
					font-weight: 700;
					letter-spacing: 0.24px;
					vertical-align: center;
					border: none;
					border-bottom: 1px solid $tba-light-grey;
					border-radius: 9px;
				}

				ul {
					margin: 0;
					padding: 0;
				}

				.infinite-scroll-component {
					overflow: hidden !important;
				}
			}
		}
	}

	.dropdown-tree-spinner {
		height: 27px;
		margin: 0;
		padding: 0;
		background: $tba-lightest-grey;
		border: 1px solid $tba-dark2-grey;
		border-radius: 2px;

		.spin-wheel {
			font-size: 20px;
		}
	}

	.selected-field {
		margin: 0;
		color: $tba-darker-grey;
		font-size: 12px;
		font-style: italic;
		font-weight: bold;
	}

	&.hide-search {
		.search {
			display: none;
		}
	}

	&.hide-tags {
		// hide all children except las one
		.tag-list {
			& > *:not(:last-child) {
				display: none;
			}

			.placeholder {
				color: $black;
			}
		}
	}
}


.dropdown-tree-wrapper {
	.dropdown-content {
		.select-all {
			margin-bottom: 4px;
			padding: 6px 0;
			border-bottom: 1px solid $tba-dark2-grey;
		}
	}
}

.checkbox-tree-nested-select {
	.checkbox-tree-header {
		display: flex;
		justify-content: space-between;

		h4 {
			margin: 0;
			font-size: 13px;
			letter-spacing: unset;
		}
		span {
			font-size: 12px;
			font-style: italic;
			color: $tba-darker-grey;
		}
	}

	.tag-list {
		// hide placeholder if some of items selected
		.tag-item + .tag-item .placeholder {
			display: none;
		}
	}
}

.tag-search {
	.tag-list {
		.tag-item:last-child {
			width: 100%;

			.search {
				width: 100%;
			}
		}
	}
}
