@import '../functions.scss';

.tooltipContent {
  border-radius: rem(12);
  padding: rem(12) rem(16);
  animation: fadeIn 200ms ease-in-out;
  width: max-content;
  max-width: 350px;
  // table component where this is used has a z-index of 2 so we need to be higher...
  z-index: 300;
  font-size: rem(14);
  line-height: rem(20);
  background: white;
  color: var(--amplify-colors-neutral-90);
  box-shadow: 0px 0px 24px 0px #0000001a;
}

.floatingArrow {
  fill: white;
}
