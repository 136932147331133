.orb-circle {
  width: 100%;
  height: calc(100% - 70px);
  flex: 1;

  &.font-size-large {
    font-size: 1.3em;
  }
}
.orb-circle-content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  font-size: 1em;
  padding-top: 16px;

  .title {
    font-size: 2em;
    font-weight: bold;
  }
  .sub-title {
    font-size: 1.5em;
    padding-top: 10px;
  }
}
