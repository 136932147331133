@import "src/common/scss/colors.scss";
@import "src/common/scss/grid.scss";

.notification-list-dropdown {
    button[type="menu"] {
        padding-left: 10px;
        padding-right: 19px;
    }
    .notification-filter{
        display: flex;
    align-items: center;
    .filterCount{
        padding-left: 8px;
        font-weight: bold;
        color:red;
    }
    }
    &.opened {
        & > div,
        div.menu {
            height: auto;
        }
        .notification-list .notifications {
            height: 503px;
        }
        .notification-list{
            height: 646px;
            box-shadow: 3px 4px 6px 2px rgba($black, .2);
            &.empty {
                height: 200px;
            }
        }
        .notification-list {
            .notifications-header {
                padding: 20px;
                border-bottom: 1px solid $tba-dark-grey;
                height: auto;
                overflow: visible;
            }
            .clear-all {
                background: white;
                height: auto;
                padding: 17px;
                overflow: visible;
                border-top: 1px solid $tba-dark-grey;
            }
        }
        .no-notifications {
            height: 100px;
            overflow: visible;
            padding: 20px;
        }
    }
    & > div {
        visibility: visible;
        border: none;
        overflow: visible;
        @include screen-max-extra-small {
            right: -45px;
        }
    }
    div.menu {
        display: block;
        background: $white;
        width: 520px;
        max-height: 646px;
        padding: 0;
        height: 0;
        @include screen-max-extra-small {
            width: 260px;
        }
    }

    button[type="menu"] {
        span:first-child {
            margin-right: 15px;
        }
    }

    .notification-list {
        width:520px;
        max-height: 646px;
        height: 0;
        &.empty {
            height: 0;
        }
        p {
            margin: 0;
        }

        .notification-count {
            position: absolute;
            width: fit-content;
            padding: 1px 3px;
            height: 18px;
            background: $red;
            border: 1px solid $white;
            margin: 9px 0 9px -3px;
            border-radius: 4px;
            color: $white;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            top:-34px;
            left: calc(100% - 25px);
            cursor: pointer;

            @include screen-max-extra-small {
                left: calc(100% - 100px);
            }
        }

        .notifications {
            background: white;
            overflow-y: auto;
            max-height: 590px;
            height: 0;
        }

        .notifications-header {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 0;
            padding: 0;
            border: none;
            overflow: hidden;
            p {
                font-size: 16px;
                color: $tba-dark-grey;
                font-weight: bold;
                padding-bottom: 8px;
            }

            a {
                color: $blue-2;
                font-size: 13px;
                letter-spacing: 0.37px;
            }
        }

        .clear-all {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $blue-2;
            height: 0;
            overflow: hidden;
            border: none;
            cursor: pointer;
            p {
                margin: 0;
            }
        }
    }

    .no-notifications {
        height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tba-dark-grey;
        font-weight: bold;
        overflow: hidden;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
    }
}

.denali-notifications {
  .notification-list {
    position: static;
    height: auto;
    box-shadow: none;

    .no-notifications,
    .notifications {
      width: 500px;
      height: 450px;
      overflow: auto;
    }

    .no-notifications {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .notification-count,
    .notifications-header {
      display: none;
    }

    .icon-close {
      position: static;
    }

    .clear-all {
      background: #fff;
      border-top: 1px solid #999;
      height: auto;
      overflow: visible;
      padding: 17px;
      align-items: center;
      border: none;
      color: #6c97bf;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }
}
