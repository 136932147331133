@import 'src/denali-components/functions.scss';

.titleContainer {
  display: flex;

  .title {
    margin-bottom: rem(4);
    max-width: rem(350);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 1280px) {
      max-width: rem(650);
    }
  }
}

.titleCentered {
  align-items: center;
  max-width: rem(550);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chipRow {
  display: flex;
  gap: rem(8);
  flex-wrap: wrap;

  @media (max-width: 1280px) {
    flex-wrap: nowrap;
  }
}

.chipContainer {
  display: flex;
  gap: rem(8);
  justify-content: flex-end;
}

.descriptionContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: rem(2);

  .descriptionTitle {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(20);
    margin: 0;
  }

  .description {
    max-width: rem(250);
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}