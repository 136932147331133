@import '../functions.scss';

.sidebar {
  position: relative;
  z-index: 13;
  overflow-x: visible;
  text-align: left;
  background: var(--amplify-components-sidebar-background);
  font-size: rem(14);
  line-height: 1.285;
  font-weight: 500;
  color: var(--amplify-components-sidebar-foreground);
  display: flex;
  flex-direction: column;

  @media (min-height: 999px) {
    font-size: rem(16);
    line-height: 1.25;
    padding: rem(50) rem(16);
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a,
  button {
    &:focus-visible {
      outline: 1px solid var(--amplify-colors-blue-50);
      outline-offset: 2px;
    }
  }

  .bottomDivider {
    padding-bottom: rem(14);
    margin-bottom: rem(14);
    border-bottom: 1px solid var(--amplify-components-sidebar-divider-color);
  }

  .topDivider {
    padding-top: rem(14);
    margin-top: rem(14);
    border-top: 1px solid var(--amplify-components-sidebar-divider-color);
  }
}

.copyright {
  font-size: rem(12);
  line-height: normal;
  text-align: center;
  padding-top: rem(8);
  padding-bottom: rem(8);
  color: var(--amplify-components-sidebar-hint);
}

.sidebarInner {
  overflow: hidden;
  height: 100%;
  width: 78px;
  transition: width 100ms ease-out;
  padding: rem(16) rem(16) 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  direction: rtl;
}

.sidebarReset {
  direction: ltr;
}

.footerSection {
  padding: 0 rem(16) rem(24);
  width: 78px;
  transition: width 100ms ease-out;
}

.open {
  width: 298px;

  .logoSection {
    svg {
      width: rem(150);
      height: rem(50);
    }
  }
}

.navSection {
  margin-bottom: rem(16);

  @media (min-height: 999px) {
    margin-bottom: rem(30);
  }
}

.highlightSection {
  a {
    background: var(--amplify-components-sidebar-highlight-background);
  }
}

.logoSection {
  padding-left: rem(3);

  a {
    padding: 0;
  }

  svg {
    width: rem(40);
    height: rem(40);

    path {
      fill: var(--amplify-colors-red-50);
    }

    @media (min-height: 999px) {
      width: rem(50);
      height: rem(50);
    }
  }
  padding: rem(24) rem(16) 0 rem(19);
}

.tooltip {
  background: var(--amplify-components-sidebar-active-background);
  border-radius: rem(12);
  padding: rem(12) rem(16);
  text-wrap: nowrap;
  animation: fadeIn 200ms ease-in-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 9px;
    height: 16px;
    top: 50%;
    transform: translateY(-8px);
    left: -9px;
    background: var(--amplify-components-sidebar-active-background);
    -webkit-mask-image: url('~/src/assets/images/tooltip-dark-left.svg');
    mask-image: url('~/src/assets/images/tooltip-dark-left.svg');
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toggle {
  position: absolute;
  top: rem(32);
  right: rem(-12);
  background: var(--amplify-components-sidebar-accent);
  padding: rem(4);
  line-height: 0;
  border-radius: 9999px;
  border: none;

  @media (min-height: 999px) {
    top: rem(59);
    right: rem(-16);
    padding: rem(6);
  }

  svg {
    width: rem(16);
    height: rem(16);

    @media (min-height: 999px) {
      width: rem(20);
      height: rem(20);
    }

    path {
      fill: var(--amplify-components-sidebar-foreground);
    }
  }
}

.icon {
  margin-right: rem(14);
  line-height: 0;
}

.title {
  flex-grow: 1;
  animation: fadeIn 200ms;
}

.mainNavLink {
  & > button[data-selected='true'] {
    background: var(--amplify-components-sidebar-active-background);
  }
}

.fadeIn {
  animation: fadeIn 200ms;
}

.popupWrapper {
  /* transparent wrapper to maintain hover interactions moving mouse from root link to submenu */
  padding-left: rem(32);
}

.popup {
  background: var(--amplify-components-sidebar-background);
  border-radius: rem(30);
  padding: rem(24);
  width: rem(280);
  animation: fadeIn 200ms ease-in-out;

  ul {
    max-height: calc(95vh - rem(48));
    overflow-y: auto;
  }

  a,
  button {
    padding: rem(12) rem(16);
  }
}

.mainNavigation {
  flex-grow: 1;
}

.uiMenu,
.userMenu,
.mainNavigation {
  a,
  button {
    display: flex;
    width: 100%;
    text-align: left;
    padding: rem(12) rem(16);
    background: transparent;
    border: none;
    border-radius: rem(12);
    color: var(--amplify-components-sidebar-foreground);
    text-decoration: none;
    align-items: center;
    text-wrap: nowrap;
    justify-content: flex-start;
    text-wrap: balance;
  }

  li + li {
    margin-top: rem(6);
  }

  li:hover > a,
  li:focus > a,
  li:hover > button,
  li:focus > button,
  a:hover,
  a:focus,
  button:hover,
  button:focus {
    background: var(--amplify-components-sidebar-active-background);
  }

  .selectedRoute {
    background: var(--amplify-components-sidebar-active-background);
  }

  svg {
    width: rem(18);
    height: rem(18);

    path {
      fill: var(--amplify-components-sidebar-foreground);
    }

    @media (min-height: 999px) {
      width: rem(24);
      height: rem(24);
    }
  }
}

.userMenuButtons {
  display: flex;
  flex-direction: row;
  gap: rem(12);
  align-items: center;
  width: 100%;

  .userButton {
    flex: 1;
    margin-left: rem(-16);
    margin-right: rem(-16);
    max-width: 206px;
  }

  .notificationButton,
  .helpButton {
    flex: 0 0;
    border-radius: 9999px;
    padding: rem(8);
  }

  svg {
    width: rem(18);
    height: rem(18);
  }
}

[data-open='false'] .userMenuButtons {
  flex-direction: column-reverse;
  justify-content: center;

  .notificationButton {
    width: auto;
  }

  .userButton {
    margin-left: rem(-16);
    margin-right: rem(-16);
    width: auto;
  }

  .helpButton {
    display: none;
  }
}
