@import "../../common/colors.scss";

.modal-select-wrapper{
    margin-bottom: 15px;
    .icon-error{
        color:#D52B23;
        position: absolute;
        right:-4px;
    }
    .select-wrapper{
        display: inline-block;
        width:100%;
        .modal-select-input{
            width:50%;
        }
    }
    .has-error.modal-select-input{
        border-width: 2px;
        border-color: #D52B23;
        background-color: #fea7aa;
    }
    .has-error{
        color:#D52B23;
    }
    .mini-error-text{
        font-size:10px;
        font-style:italic;
        color:#D52B23
	}
	.modal-select-input{
		height: 26px;
		line-height: 26px;
	}
    &.disabled .custom-label{
        color: $tba-dark-grey;
    }
}