@import 'src/denali-components/functions.scss';

.alarmNotificationContainer {
  .infoContainer {
    align-items: center;
    border: 1px solid var(--amplify-colors-neutral-20);
    border-radius: rem(16);
    display: flex;
    justify-content: center;
    gap: rem(12);
    padding: rem(12) rem(20);

    .infoTitle {
      display: flex;
      gap: rem(8);
      align-items: center;
    }
  }

  .subscribedContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: rem(2);
    text-align: left;

    .subscribedTitle {
      color: var(--amplify-colors-font-secondary);
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(20);
      margin: 0;
    }
  }
}