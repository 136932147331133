@import "../legacy/common/grid.scss";
@import "../legacy/common/colors.scss";

.data-source-pickers {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 20px;
	max-width: 100%;

	> * {
		margin-right: 20px;
		padding-left: 0;
		margin-bottom: 10px;
	}

	.single-text-name {
		display: block;
		font-weight: bolder;
		font-size: 13px;
		line-height: 15px;
		letter-spacing: 0.26px;
	}

	.auto-scroll{
		.rs-header{
			overflow-y: scroll;
			height: 300px;
		}
	}
	

	.multiple-tis-Object {
		width: auto;
		max-width: 100%;

		.equipment {
			min-width: 200px;
			width: max-content;
			max-width: 100%;
			.rs-base-multiple .rs-selection:nth-child(n+5):last-child {
				background-color: $tba-darker-grey;
				color: $white;
				text-transform: uppercase;
				&:hover {
					text-decoration: underline;
				}
				.rs-remove {
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 0;
					opacity: 0;
				}
			}
			.filterable-select-spinner {
				height: 26px;
				margin: 0;

				.icon.spin-wheel {
					font-size: 18px;
				}
			}
			@include screen-max-small {
				width: min-content;
			}
		}

		legend {
			.item-selected {
				font-weight: normal;
				font-style: italic;
			}
		}
	}
	.organization-picker {
		display: flex;
		flex-flow: column;
		.organizations {
			width: 200px;
		}
	}

	.building-picker {
		display: block;
		.section-spinner {
			height: auto;
			span {
				font-size: 14px;
			}
		}
		.buildings {
			width: 200px;
		}
	}
}

.active-item-container{
	display: flex;
	align-items: flex-end;

	label {
		display: flex;
		flex-flow: column;
	}
}

.data-source {
	display: flex;
	flex-wrap: wrap;
	> div {
		margin-bottom: 8px;
		> span.icon {
			font-size: 14px;
			margin-right: 4px;
		}

		> span:nth-child(2n) {
			margin-right: 8px;
		}
	}

	@include screen-max-extra-small {
		display: block;
		> div:not(:last-child) {
			margin-bottom: 8px;
		}
		> div:last-child {
			margin-bottom: 25px;
		}
	}
}

