@import "../../../common/colors.scss";
@import "../../../common/grid.scss";

.image-block-container {
	position: relative;
	border: none;
	border-top: 1px solid $tba-light-grey;
	padding: 5px 10px;
	background-color: $white;

	@include screen-max-extra-small {
		padding: 35px 5px 5px 5px;
	}

	button.remove-block {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	fieldset input[name="title"] {
		width: 190px;
	}
}


