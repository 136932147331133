@import "../../../common/colors.scss";
@import "../../../common/grid.scss";

.images-list {
	display: flex;
	flex-direction: column;
	background-color: $white;
	flex: 35%;
	min-width: 465px;
	padding: 0 0 15px;

	
	header {
		padding: 10px;
		margin-top: 5px;
		padding-bottom: 10px;
   	    display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;

		legend{
			margin-top: 10px;
		}

		button {
			&.add-finding {
				margin-right: 8px;
			}
		}
	}

	@include screen-max-medium {
		flex: 50%;
		min-width: 0;
		header {
			padding: 10px;
			margin-top: 5px;
			padding-bottom: 10px;
			display: flex;
			-webkit-box-pack: justify;
			justify-content: space-between;
			button {
				&.add-finding {
					margin-right: 8px;
				}
			}
		}
	}

	@include screen-max-small {
		flex: 100%;
		max-width: 100%;
	}

	> button.bottom {
		margin: 10px 15px 0 0;
		align-self: flex-end;
	}

	.image-control{
		margin-top: 8px;
	}
}
