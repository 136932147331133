@import 'src/denali-components/functions.scss';

.cardHighlightActions {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  border-radius: rem(30);
  height: 100%;
  padding: rem(38) rem(31);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--amplify-components-card-highlight-actions-background);
  background-position: center center;
  color: var(--amplify-components-card-highlight-actions-foreground);

  header {
    position: relative;
    z-index: 2;
    padding-top: rem(100);
  }
}

.alignTitleAndActions {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backgroundFade {
  position: absolute;
  top: 0;
  left: rem(-31);
  right: rem(-31);
  bottom: rem(-38);
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    display: block;
    height: rem(170);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  &:after {
    content: '';
    display: block;
    flex-grow: 1;
    background: #000;
  }
}

.title {
  width: 50%;
  padding-right: rem(20);
}

.subTitle {
  position: relative;
  z-index: 2;
}

.actions {
  button {
    text-wrap: nowrap;
  }
}

.tags {
  position: relative;
  z-index: 2;
}
