/* --------------------------- container & animation --------------------------- */

$min-size: 19px;

.t-chart-aside-legend-container {
  display: flex;
  flex-direction: column;
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  max-width: 340px;
  overflow: hidden;
  transition: width 0.2s linear;
  width: 340px;
  height: fit-content;
  max-height: 100%;
}

.t-chart-legend-wrapper {
  border-radius: 3px;
  border-right: transparent;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  overflow-x: hidden;
  transition: transform 0.3s, border-right 0.3s ease 0.6s;
}

.t-chart-legend-header {
  display: flex;
}

.t-chart-legend-toggle-wrapper {
  background: #f5f6fa;
  padding: 0 5px 5px 0;
  position: relative;
  // display: flex; // to strech the toggle icon with content
}

.t-chart-add-on-cuve-el {
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  bottom: -1px;
  left: 0;
  margin: 5px 0 0 5px;
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 1;
}

.t-chart-legend-toggle {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #cccccc;
  cursor: pointer;
  display: flex;
  flex: auto;
  justify-content: center;
  min-height: 46px;
  position: relative;
  width: 35px;
  z-index: 2;
  &:hover {
    background: #f7f7f7;
  }
}

.t-chart-legend-title {
  align-items: center;
  background: inherit;
  border-radius: 8px;
  border: 1px solid #cccccc;
  color: #1e1e1e;
  display: flex;
  flex: 1;
  padding: 10px;

  // override
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left: none;
  border-bottom-color: transparent;
}

.t-chart-legend-content {
  // flex: 1; // check whether required in any scenario's

  background: inherit;
  border-radius: 8px;
  border-top-right-radius: 0;
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 18px;
  transition: all 0.22s;

  // override
  border-top: none;
}

.t-chart-minimize {
  border-right: 1px solid #cccccc;
  width: $min-size;

  .t-chart-legend-wrapper {
    transform: translate(calc(100% - 17px));
  }

  .t-chart-legend-title,
  .t-chart-legend-content {
    overflow: hidden;
    white-space: nowrap;
  }
}

.t-chart-maxmize {
  width: 100%;

  .t-chart-legend-wrapper {
    transform: translate(0);
  }
}

/* --------------------------- arrow --------------------------- */
.t-chart-arrow {
  border: solid #206fcf;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.55s ease;
}

.t-chart-right {
  transform: rotate(-45deg) translate(-2px, -1px);
  -webkit-transform: rotate(-45deg) translate(-2px, -1px);
}

.t-chart-left {
  transform: rotate(135deg) translate(5px, 4px);
  -webkit-transform: rotate(135deg) translate(5px, 4px);
}

/* --------------------------- legend --------------------------- */

.t-chart-legend-item-group {
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  &:not(:only-child):not(:last-child) {
    margin-bottom: 22px;
  }
}

.t-chart-legend-item {
  font-size: 14px;
  display: flex;
  column-gap: 8px;
}

.t-chart-legend-item-icon {
  width: 1.25em;
  height: 1.25em;
  // background: rgb(0, 148, 228);
  // border: 1px solid rgb(216, 216, 216);
  border-radius: 2px;
  flex: 0 0 auto;
  align-self: flex-start;
}

.t-chart-legend-item-text {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.t-chart-legend-item-group-title {
  font-weight: bold;
}

.t-chart-clickable-item-true {
  cursor: pointer;
}

.t-chart-clickable-item-false {
  cursor: default;
  pointer-events: none;
}

.t-chart-disabled-item {
  filter: grayscale(100%) opacity(0.3);
}

/* --------------------------- Simple legend wrapper style --------------------------- */

.t-chart-simple-legend {
  width: 100%;
  min-height: 1em;
  font-size: 12px;
}

.t-chart-simple-legend-content {
  font-size: inherit;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: row;
  gap: 0.5em;
  justify-content: center;
  align-items: flex-start;
}

.t-chart-simple-legend-item {
  display: flex;
  column-gap: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip-legend {
    position: absolute;
    bottom: 10px;
    padding: 5px 3px;
    background: #f0f0f0;
    box-shadow: 0px 1px 5px #d4d4d4;
    border: 1px solid grey;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 1px;
    overflow-y: scroll;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
}