@import "src/components/legacy/common/typography.scss";
@import "src/components/legacy/common/colors.scss";

.edit-view {
	padding: 0 8px;

	&.edit-view--group-type {
		padding: 0 8px;
	}

	&.read-mode {
		& .edit-view__value {
			display: block;
		}

		& .edit-view__field {
			display: none;
		}
	}

	&.validation-error:not(.edit-view--group-type) {
		.edit-view__label {
			@include text-field-label-error;
		}

		.edit-view__field {
			.edit-view__input {
				@include text-field-input-error;
			}

			.select-wrapper {
				.control {
					@include text-field-input-error;
				}
			}

			.select-block {
				.panel-container {
					.panel-container__button {
						@include text-field-input-error;
					}
				}
			}
		}
	}

	&.validation-error {
		.selectrix-wrapper.selectrix-wrapper--type-autocomplete, .selectrix-wrapper.selectrix-wrapper--single-select {
			div.rs-toggle {
				background: $pink;
				border: 2px solid $red;
			}
		}
		.officeSelectInput,.orgSelectInput{
			background: $pink;
				border: 2px solid $red;
		}
	}

	.edit-view__error {
		color: $red;
		font-size: 10px;
		font-style: italic;
		font-weight: bold;
		height: 16px;
		letter-spacing: 0.20px;
		line-height: 16px;
		width: 100%;
	}

	&.edit-mode.required {
		.edit-view__label:after {
			content: "*";
		}
		.checkbox-container:after {
			content: "*";
		}
	}

	
	.edit-view__label {
		display: block;
		height: 16px;
		width: 100%;
	}

	.edit-view__value {
		border: 1px solid transparent;
		display: none;
		font-size: 12px;
		font-weight: normal;
		height: 26px;
		line-height: 26px;
		vertical-align: middle;
		white-space: nowrap;
		width: 100%;
	}

	.edit-view__field {
		display: block;
		height: 26px;
		width: 100%;

		.edit-view__input {
			display: block;
			width: 100%;
		}
	}

	.edit-view__fields {
		display: flex;
		width: 100%;

		& > .edit-view {
			padding: 0;
		}
	}

	&.edit-view--disabled {
		cursor: not-allowed;

		.edit-view__label {
			@include text-field-label-inactive;
		}

		.edit-view__field {
			pointer-events: none;
			@include text-field-label-inactive;
		}

		label {
			@include text-field-label-inactive;
		}

		.checkbox-label::before {
			@include check-box-inactive;
		}

		input[type=text],
		input[type=search],
		input[type=number],
		textarea {
			@include text-field-input-inactive;
		}
	}

	&.edit-view {

		.disabled.edit-view__label {
			cursor: not-allowed;
			@include text-field-label-inactive;
		}

		.disabled edit-view__field {
			pointer-events: none;
			@include text-field-label-inactive;
		}

		.disabled label {
			@include text-field-label-inactive;
		}

		.disabled input[type=text],
		.disabled input[type=search],
		.disabled input[type=number],
		.disabled textarea {
			@include text-field-input-inactive;
		}
	}
}

.remove_arrow::-webkit-outer-spin-button, .remove_arrow::-webkit-inner-spin-button {
	-webkit-appearance: none;
	 margin: 0;
}