@import '../../denali-components/functions.scss';

.dashboards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(30);
  border-radius: rem(30);
  font-size: rem(14);
  line-height: normal;
  color: var(--amplify-colors-neutral-50);
  background: radial-gradient(
      133.48% 133.01% at 55.08% -107.02%,
      rgba(255, 255, 255, 0) 0%,
      #fff 100%
    ),
    conic-gradient(
      from 241deg at 44.92% -44.48%,
      rgba(255, 55, 55, 0.5) 0deg,
      rgba(109, 255, 73, 0.5) 72.0000010728836deg,
      rgba(60, 173, 255, 0.5) 144.0000021457672deg,
      rgba(204, 60, 255, 0.5) 216.00000858306885deg,
      rgba(255, 153, 60, 0.5) 288.0000042915344deg
    ),
    linear-gradient(179deg, rgba(255, 255, 255, 0) 0.94%, #fff 48.4%), #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--amplify-colors-black);
  }

  img {
    width: rem(58);
    margin-bottom: rem(28);
  }

  button {
    font-size: rem(16);
    line-height: normal;
    font-weight: 500;
    padding: rem(16) rem(18);
    border-radius: rem(16);
    margin-top: rem(12);
  }
}

.weather {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem(30);
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    138deg,
    rgba(229, 240, 252, 0.84) 5.64%,
    rgba(255, 255, 255, 0.84) 75.84%
  );
  padding: rem(30);
  min-height: 190.5px;

  img {
    max-width: 100%;
  }

  .weatherContainer {
    width: 100%;
    height: 100%;
  }

  .weatherTemp {
    font-size: rem(70);
    line-height: normal;
  }

  .weatherIcon {
    font-size: rem(32);
    line-height: normal;
    font-weight: 500;
    color: var(--amplify-colors-blue-50);

    svg path {
      fill: url(#weatherGradient);
    }
  }

  .weatherDescription {
    font-size: rem(18);
    line-height: normal;
    font-weight: 600;
  }

  .weatherLocation {
    font-size: rem(16);
    line-height: normal;
    font-weight: 500;
    color: var(--amplify-colors-font-secondary);
  }

  .weatherMetrics {
    color: var(--amplify-colors-font-secondary);

    svg {
      width: rem(20);
      height: rem(20);
      margin-right: rem(8);
      vertical-align: middle;
    }
  }
}
