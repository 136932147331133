@import "src/components/legacy/common/typography.scss";

div > div.menu {
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;

    

    & .icon-div {
        font-size: 18px;
        color: $white;

        
    }

    .nav-dropdown:first-child {
        button {
            span:first-child {
                font-weight: bold;
            }
        }
    }
}

.menu-item-divider {
    border-top: 2px solid #bbb;
    width: 100%;
}

.divider-item-menu {
    width: 100%;
    padding: 0px 0px !important;
}

.version-text {
    opacity: 0.55;
}