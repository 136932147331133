@import "src/components/legacy/common/grid.scss";
@import "src/components/legacy/common/typography.scss";

.upload-bounding-area {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 140px;
	padding: 0 8px;
	background-color: $tba-lightest-grey;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid $tba-dark2-grey;
	border-radius: 2px;
	cursor: pointer;

	&.loading {
		cursor: progress;
	}

	&.loading-error {
		background: $red-1;
		border: 2px solid $red;

		+ p.error {
			visibility: visible;
		}
	}

	&.disabled {
		background-color: $tba-light-grey;
		border-color: $tba-medium-grey;
		color: $tba-dark-grey;
		cursor: not-allowed;
		& .bg {
			opacity: 0.5;
		}
	}

	& .bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	& h2 {
		margin: 0;
	}

	& span.instruction {
		@include text-field-input;
	}

	& input[type=file] {
		visibility: hidden;
	}

	& .remove-icon {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	& .edit-icon {
		position: absolute;
		top: 10px;
		right: 50px;
		background-color: $tba-darker-grey;
		&:hover {
			background-color: $tba-dark2-grey;
		}
		&:active {
			background-color: $tba-medium-grey;
		}
	}

	& .spinner {
		height: 100px;
		font-size: 100px;
	}

	/* temporary solution until progress indicator component is implemented */
	& .spinner-with-progress {
		position: relative;

		& .progress {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 18px;
		}
	}

	& .paste-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		& .icon-error {
			color: $red;
		}

		& .paste-image {
			width: 90%;
			display: flex;
			align-items: center;
			margin-top: 10px;
			border: 2px dashed $tba-darker-grey;
			padding: 5px;
			position: relative;
			z-index: 2;

			@include screen-tiny {
				width: 100%;
			}

			& .icon-paste-from-clipboard {
				padding-right: 6px;
				font-size: 14px;
			}

			& div {
				height: 38px;
				border: none;
				font-size: 14px;
				line-height: 18px;
				font-weight: bold;
				padding: 0;
				overflow: hidden;
				cursor: default;
				width: 100%;
			}

			& div:focus {
				outline: none;
				cursor: text;
			}

			& [placeholder]:empty:before {
				content: attr(placeholder);
				color: $tba-darker-grey;
				line-height: 18px;
				display: block;
				cursor: text;
			}
			& [placeholder]:empty:focus:before {
				content: "";
				padding-top: 1em;
			}

		}
	}

	@include screen-max-medium {
		& .paste-container {
			width: 100%;

			& .paste-image {
				padding: 2px;
				margin-top: 5px;
				& div {
					height: auto;
					font-size: 14px;
				}
			}
		}
	}

	@include screen-max-small {
		& .paste-container {
			width: 90%;
		}
	}
	@include screen-max-extra-small {
		height: 200px;
		& .paste-container {
			width: 100%;
		}
	}
}
