@import "../../../../components/legacy/common/typography.scss";

.equipment-characteristics {
    font-size: 13px;

    .toolbar-buttons {
        display: flex;
        padding-bottom: 10px;
        padding-left: 6px;

        &.left {
            justify-content: flex-end;
        }

        &.atsPage {
            justify-content: space-between;
        }

        button {
            margin-left: 14px;
        }
    }

    @media (max-width: 900px) {
        .rx-col-6 {
            width: 100%;

            &:first-child {
                padding-bottom: 0;
            }

            &:last-child {
                padding-top: 0;
            }
        }
    }
    .state-title{
        font-size: 14px;
        font-weight: bold;
        padding: 0px 20px;
    }
    .state-btn-wrapper{
        [class^="icon-"]{
            height:26px;
            padding: 6px 10px;
        }
        button:first-child{
            margin-right:6px;
        }
    }
    .param__value {
        margin-top: 5px;

        .fields-group {
            display: flex;

            .input-group, .select-wrapper, .reactDatePickerInput {
                flex-grow: 1;
                min-width: 200px;
                max-width: unset;
                width: auto;
            }

            .input-group {
                display: flex;
                align-items: center;

                input {
                    flex-grow: 1;
                    text-align: left;
                }

                .uom {
                    padding-left: 5px;
                }
            }

            .datepicker-input {
                background: $tba-lightest2-grey;
            }

            .value-type {
                align-self: center;
                margin-left: 10px;
                font-size: 12px;
                font-style: italic;
                font-weight: 400;
                flex-basis: 90px;
            }

            .reset {
                align-self: center;
                font-size: 12px;
                margin-left: 10px;
                flex-basis: 100px;
                cursor: pointer;
                color: $blue-2;
                text-decoration: underline;
            }
        }

        .error-message {
            position: absolute;
            padding: 0;
            border: none;
            color: $red;
            font-size: 10px;
            font-style: italic;
            font-weight: bold;
            height: 16px;
            letter-spacing: 0.20px;
            line-height: 16px;
            width: 100%;
        }
    }

    .char-override-indicator-container {
        .value-type {
            opacity: 0.5;
            
        }

        .edit-view {
            vertical-align: -webkit-baseline-middle;
        }

        .icon-infocircle {
            border: none;
            margin-left:5px;
        }
    }
}

.rx-grid {
    box-sizing: border-box;
    display: block;
    display: flex;
    flex-wrap: wrap;
    // letter-spacing: -.31em;
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    position: relative;
   // word-spacing: -0.43em;
}

.rx-col-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
}
.circuit-name {
   margin-left: 10px;
}

.rx-grid.rx-grid-fit-x {
    margin: auto -15px;
    margin-top: 10px;
    width: 105%;
}
.rx-grid.rx-grid-bleed-y>[class*=col-] {
    padding-bottom: 0;
    padding-top: 0;
}

.rx-grid-fit {
    margin: -10px;
    width: auto;
   }

.rx-grid-small-spacing {
    margin: -5px;
    width: auto;
   }
.rx-justify-end {
    justify-content: flex-end;
   }
.rx-col-6 {
    width: 50%;    
    
}

.rx-col-12 {
    width: 100%;
}
.rx-col-head{
   padding-left: 5px;
   font-size: 14px;
   font-weight: 600;
   font-family: "'InterVariable', 'Inter var', 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif";
}

label {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .26px;
    line-height: 15px;
}

.rx-grid [class*="rx-col-"] {
    padding: 8px;
}

           .param__value {
               margin-top: 5px;
    
               .fields-group {
                   display: flex;
    
                   .input-group,
                   .select-wrapper,
                   .reactDatePickerInput {
                       flex-grow: 1;
                       min-width: 200px;
                       max-width: unset;
                       width: auto;
                   }
    
                   .input-group {
                       display: flex;
                       align-items: center;
    
                       input {
                           flex-grow: 1;
                           text-align: left;
                       }
    
                       .uom {
                           padding-left: 5px;
                       }
                   }
    
                   .datepicker-input {
                       background: $tba-lightest2-grey;
                   }
    
                   .value-type {
                       align-self: center;
                       margin-left: 10px;
                       font-size: 12px;
                       font-style: italic;
                       font-weight: 400;
                       flex-basis: 90px;
                   }
    
                   .reset {
                       align-self: center;
                       font-size: 12px;
                       margin-left: 10px;
                       flex-basis: 100px;
                       cursor: pointer;
                       color: $blue-2;
                       text-decoration: underline;
                   }
               }
    
               .error-message {
                   position: absolute;
                   padding: 0;
                   border: none;
                   color: $red;
                   font-size: 10px;
                   font-style: italic;
                   font-weight: bold;
                   height: 16px;
                   letter-spacing: 0.20px;
                   line-height: 16px;
                   width: 100%;
               }
           }

           .fields-group {
            display: flex;

            .input-group, .select-wrapper, .reactDatePickerInput {
                flex-grow: 1;
                min-width: 200px;
                max-width: unset;
                width: auto;
            }

            .input-group {
                display: flex;
                align-items: center;

                input {
                    flex-grow: 1;
                    text-align: left;
                }

                .uom {
                    padding-left: 5px;
                }
            }

            .datepicker-input {
                background: $tba-lightest2-grey;
            }

            .value-type {
                align-self: center;
                margin-left: 10px;
                font-size: 12px;
                font-style: italic;
                font-weight: 400;
                flex-basis: 90px;
            }

            .reset {
                align-self: center;
                font-size: 12px;
                margin-left: 10px;
                flex-basis: 100px;
                cursor: pointer;
                color: $blue-2;
                text-decoration: underline;
            }
        }

        .value-type {
            align-self: center;
            margin-left: 10px;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            flex-basis: 90px;
        }

        .editButton {
            margin-left: auto;
            // width: 69px;
            padding-left: 20px;
            padding-right: 20px;
            height: 30px;
            border-radius: 2px;
            color: rgb(255, 255, 255);
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.36px;
            line-height: 14px;
            text-transform: none;
            background: rgb(70, 126, 38);
        }