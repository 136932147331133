@import "src/common/scss/colors.scss";
@import "src/common/scss/grid.scss";


.nav-dropdown > div.notification-list{
    top:34px;
    padding-left:0;
}

.no-notifications{
    background: white;
}

.notification-list {
    background: white; 
    .notification {
        padding: 20px;
        background: $tba-lightest-grey;
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
        &:not(:last-child) {
            border-bottom: 1px solid $tba-dark-grey;
        }
        &.read {
            color: $tba-darker-grey;
            background: $white;
        }

        &:hover {
            background-color: $notification-hover;
        }

        .type {
            width: 14px;
            height: 14px;
            margin-right: 12px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.info {
                span {
                    color: $tba-dark-grey;
                }
            }
            &.error {
                background: $red;
            }
            span {
                color: $white;
                font-size: 15px;
            }
        }

        .notification-body {
            width: 100%;
            .notification-header {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .title {
                    display: flex;
                    flex-flow: row;
                    color: $tba-darker-grey;
                    align-items: center;
                    font-size: 14px;
                    p {
                        color: $notification-title;
                        margin-right: 6px;
                    }
                    .datasource {
                        font-weight: bold;
                    }
                }
                .icon {
                    font-size: 10px;
                    font-weight: lighter;
                    cursor: pointer;
                }
                .icon-close {
                    color: $tba-darker-grey;
                }
            }
            .text {
                margin-bottom: 12px;
                width: 93%;
            }
            .notification-footer {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                a {
                    color: $blue-2;
                    font-size: 14px;
                }
                .date {
                    color: $tba-dark-grey;
                    font-size: 12px;
                    font-style: italic;
                }
            }
            @include screen-max-extra-small {
                .datasource {
                    color: $tba-light-grey;
                    margin-bottom: 10px;
                    display: block;
                }
                .text {
                    width: 100%;
                }
            }
        }
    }
}
