@import "./colors.scss";
@import "./typography.scss";
@import "./grid.scss";
@import "./custom-media-queries.scss";

.header-wrapper {
	font-weight: bold;
	line-height: 24px;
	letter-spacing: -0.31px; /* TODO: Figure out why this is so weirdly specific (comes from typography.scss) */

	@include styles-on-print {
		padding: 10px 0;
	}

	.title-wrapper {
		padding-bottom: 10px;
		display: flex;
		justify-content: space-between;
		.title {
			font-size: 21px;
			@include text-ellipsis;
		}
		/* padding: .75% 0; keep this aligned/same as padding for .offer-detail-header */
		@include screen-max-small {
			& {
				padding-right: 30px;
			}
		}
		.tooltip {
			padding: 0 6px;
			line-height: 24px;
		}
	}

	.toolbar {
		display: flex;
		align-content: space-between;
		align-items: center;
		min-height: 50px;

		@include hide-on-print;

		.filter-div {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			width: 100%;

			button.small {
				min-width: 88px;
				white-space: nowrap;
				@include screen-tiny {
					padding: 0 3px;
				}
			}
		}
	}

	hr {
		height: 2px;
		border: none;
		margin: 0;
		background-color: $tba-darker-grey;
	}
	.hidden {
		display: none;
	}

	@include screen-max-extra-small {
		.title-wrapper {
			max-width: calc(100% - 28px);
		}
	}

	@include screen-small {
		.title-wrapper {
			max-width: calc(100% - 295px);
		}
	}
}
