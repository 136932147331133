@import "../../common/colors.scss";

.loading-pill {
  height: 10px;
  border: 1px solid $tba-medium-grey;
  border-radius: 20px;
  margin: 3px 0;
  overflow: hidden;
  transition: opacity 0.4s ease;
  background-size: 30px 30px;
  background-image: linear-gradient(45deg,
          rgba($black, .3) 25%,
          transparent 25%,
          transparent 50%,
          rgba($black, .3) 50%,
          rgba($black, .3) 75%,
          transparent 75%,
          transparent);
  animation: barberpole 1.5s linear infinite;
}

