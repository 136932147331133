@import "src/components/legacy/common/colors.scss";

.toggle-box-container {
  margin: 20px 0;
  border: 1px solid $tba-dark-grey;
  border-radius: 3px;
  background-color: $white;

  .toggle-box-label {
    display: flex;
    cursor: pointer;
    padding: 4px 6px;
    line-height: 18px;
    background-color: $tba-dark-grey;
    color: $white;

    > .icon-expand {
      align-self: center;
      line-height: 0.8;
      margin-right: 5px;
      transition-duration: 400ms;
      transition-property: transform;
      transform: rotate(0);
      height: 100%;
    }
    > .label {
      display: flex;
      flex-grow: 1;
      height: 100%;
      align-items: center;
      .error-message {
        padding: 3px;
        display: flex;
        align-items: center;
        margin: 0 auto 0 8px;
        width: fit-content;
        .icon,
        .error-text {
          font-size: 12px;
          margin: 0;
        }
        .icon {
          margin-right: 3px;
        }
      }
    }
  }

  .toggle-box-body {
    margin: 0;
    padding: 0;
    border: 0;
    transition: all 0.4s ease;
    min-width: auto;

    .toggle-box-body-inner {
      margin: 0;
      padding: 9px 6px;
    }
  }


  &.expanded {
    .toggle-box-label {
      > .icon-expand {
        transform: rotate(180deg);
      }
    }
  }

  &.transparent {
    border: none;
    margin: 0;

    .toggle-box-label {
      padding: 0 0 7px 0;
      line-height: 16px;
      background-color: transparent;
      color: $black;
      border-bottom: 1px solid $tba-light-grey;
    }

    .toggle-box-body {
      .toggle-box-body-inner {
        margin: 10px 0 0 0;
        padding: 0;
      }
    }
  }
}
