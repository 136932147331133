@import "src/components/legacy/common/colors.scss";
@import "src/components/legacy/common/grid.scss";

.location-details {
	%flex-w-1-3 {
		flex: 0 0 33.33%;
		max-width: 33.33%;
	}

	%flex-w-2-3 {
		flex: 0 0 66.67%;
		max-width: 66.67%;
	}

	%flex-w-1 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	%flex-w-1-2 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	%details-section-style {
		padding-left: 20px;
		background-color: $white;
	}

	min-height: 100%;
	padding: 20px 20px 0 20px;
	width: 100%;

	input {
		overflow: hidden;
		text-overflow: ellipsis;

		&.readonly {
			background-color: $white;
			border: none;
			padding: 0;

			&.tba-lighter-green {
				background-color: $tba-almost2-white;
			}

			&:not(.label-name) {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}

	.required,
	.number {
		input.validation-error {
			background: $pink;
			border: 2px solid $red;
		}
	}

	.details-header,
	.details-footer {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 8px;
		width: 100%;

		.toolbar-buttons {
			flex: 0 0 auto;

			button {
				margin-left: 16px;
			}
		}
	}

	.details-header {
		@include screen-max-small {
			& {
				flex-flow: column nowrap;
				align-items: initial;

				.toolbar-buttons {
					margin-top: 5px;
					button {
						margin-left: 0;
					}
				}
			}
		}
	}

	.details-footer {
		padding-top: 16px;

		.toolbar-buttons {
			margin-left: auto;
		}
	}

	.details-header {
		.edit-view.location {
			flex: 0 0 30%;
			min-width: 332px;
			padding-left: 0;

			& > input {
				width: 100%;
			}

			&.read-mode {
				display: flex;
				align-items: center;

				.edit-view__label {
					display: block;
					flex: 0 0 auto;
					height: auto;
					width: auto;

					.edit-view__label-name {
						font-size: 21px;
						margin-right: 8px;
					}
				}

				.edit-view__value {
					flex: 0 0 auto;
					font-weight: bold;
					font-size: 21px;
					line-height: 24px;
					letter-spacing: -0.31px;
					width: auto;

					@include screen-max-small {
						& {
							flex: 0 .2 auto;
							white-space: normal;
							height: auto;
						}
					}
				}

				@include screen-max-small {
					& {
						align-items: initial;
					}
				}
			}
		}
	}

	.details-main {
		background-color: $tba-lightest-grey;
		border: 1px solid $tba-dark-grey;
		padding-bottom: 20px;

		.section {
			margin: 20px;

			.toggle-box-label {
				padding: 8px 20px;
				display: flex;
				align-items: center;
				position: relative;
				@include screen-max-small {
					& {
						flex-wrap: wrap;
						& .icon-expand.icon-toggle {
							align-self: end;
							margin-top: 2px;
						}
						& .label {
							flex-direction: column;
							width: calc(100% - 20px);
							align-items: flex-start;

							& .error-message {
								margin: 10px 0 0 0;
							}
						}
						& .nav-dropdown {
							margin: 10px 0 0 18px;
							& button[type=menu] span:first-child {
								max-width: inherit;
							}
						}
					}
				}
				.nav-dropdown {
					background: $tba-darker-grey;
					border-radius: 6px;
					button[type=menu]::after {
						background: $tba-darker-grey;
					}
					button[type=menu] {
						padding: 1px 11px;
						span:first-child {
							text-transform: uppercase;
							font-size: 12px;
							font-weight: 900;
							letter-spacing: 0.36px;
							line-height: 14px;
							display: flex;
							align-items: center;
						}
					}
					& > div .menu {
						background: $tba-lightest-grey;
					}
					&.opened {
						border-radius: 6px 6px 0 0;
					}
					button {

					}
				}
			}

			.modal-block .scroll {
				height: 125px;
			}

			.no-data-section {
				font-family: "Lato", sans-serif;
				font-size: 14px;
				font-style: italic;
				font-weight: 800;
				color: $tba-darker-grey;
				padding-bottom: 15px;
			}

			.account-information,
			.connectivity,
			.offering {
				@extend %details-section-style;

				transition: max-height 400ms, opacity 400ms;
				opacity: 1;
				display: flex;
				flex-flow: column;
				align-items: flex-start;
			}

			.account-information {

				&__note {
					color: $black;
					font-size: 13px;
					font-style: italic;
					padding-bottom: 10px;
				}

				.edit-view {
					width: 220px;
					padding: 0;

					&.office-select,
					&.organization-select
					{
						width: 450px;
						.edit-view__field{
							height:auto;
							}
					}
				}

				.organization-select {
					.rs-custom-no-results {
						.organization-not-found {
							div {
								padding: 7px 8px;
								font-size: 14px;
								font-weight: normal;
							}

							&__note {
								font-style: italic;
							}

							&__link {
								cursor: pointer;
								text-decoration: underline;
							}
						}
					}
				}
			}

			.offering {
				position: relative;
				margin-bottom: 0;

				.offering__row {
					display: flex;
					margin: 5px -10px;
					width: 100%;

					&.second__row {
						padding-left: 20px;
					}

					.edit-view {
						flex: 0 1 auto;
						padding-top: 0;
						width: 192px;

						&.subdomain {
							width: auto;
							max-width: 630px;

							.edit-view__nowrap {
								white-space: nowrap;
							}

							.edit-view__input {
								display: inline;
								width: auto;
								margin: 0 8px;
							}
						}

						&.offering-label {
							flex: 0 0 180px;
							width: 180px;

							.edit-view__field {
								line-height: normal;
								height: auto;
							}

							.edit-view__value {
								font-weight: bold;
								font-size: 13px;
							}

							.checkbox-label {
								white-space: nowrap;
							}
						}

						&.offering-dates {
							.edit-view__field {
								height: auto;

								.react-picker-input-txt.hasErr {
									font-size: 10px;
								}
							}
						}
					}

					@include screen-max-small {
						display: block;
					}
				}
			}


			.connectivity {
				max-height: 100%;

				&__note {
					color: $tba-darker-grey;
					font-size: 12px;
					font-style: italic;
					font-weight: bold;
					padding-bottom: 10px;
				  a {
					  color: $tba-darker-grey;
					  text-decoration: underline;
					  text-decoration-color: $tba-darker-grey;
				  }
				}

				.connectivity__row {
					display: flex;
					flex-flow: row nowrap;
					justify-content: flex-start;
					align-items: center;
					margin: 4px -8px;
					width: 100%;

					.edit-view {
						flex: 0 0 auto;

						&.connection-type {
							flex: 0 0 136px;

							.select-wrapper {
								min-width: 120px;

								.control,
								.select-dropdown {
									width: 145px;
								}
							}
						}

						&.serial-num {
							flex: 0 0 280px;
						}

						&.device-name {
							flex: 0 0 180px;
						}

						&__error {
							line-height: 12px;
						}
					}

					button.delete-row {
						align-self: center;
						font-size: 16px;
						border: none;
						color: $tba-darker-grey;
						background-color: transparent;
						padding-left: 8px;
					}

					.reg-status {
						flex: 0 0 140px;
						padding: 0;

						.conditional-tooltip {
							margin-left: 5px;

							.icon-infocircle {
								padding-left: 1px;
							}
						}

						&__label {
							font-size: 13px;
							font-weight: bold;
						}

						&__message-wrapper {
							display: flex;
							align-items: center;
							height: 26px;
						}

						&__message {
							font-weight: normal;
							font-size: 12px;

							&--registered {
								color: $tba-dark-green;
							}

							&--not-registered {
								color: $red;
							}
						}
					}
				}

				button.action {
					margin-top: 6px;
					margin-bottom: 20px;
				}

				@include screen-max-small {
					.connectivity__row {
						flex-flow: column;
						padding-bottom: 20px;
						.edit-view {
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							&.connection-type,&.serial-num,&.device-name {
								flex: 0 0 auto;
							}

							.edit-view__label,.edit-view__value,.edit-view__field {
								width: 50%;
							}
							.edit-view__label {
								text-align: right;
								padding-right: 5px;
							}
							.edit-view__value {
								line-height: normal;
							}

						}
						.reg-status {
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							flex: 0 0 auto;
							&__label {
								width: 50%;
								text-align: right;
								padding-right: 5px;
							}

							&__message-wrapper {
								width: 50%;
								height: auto;
							}
						}
					}
				}
			}
		}

		.details-info-header {
			@extend %details-section-style;
			padding: 16px 8px;
			margin-bottom: 16px;

			border-bottom: 2px solid $tba-darker-grey;
			border-top: 2px solid $tba-darker-grey;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			position: relative;

			.note {
				color: $tba-darker-grey;
				font-size: 12px;
				font-style: italic;
				font-weight: bold;
				padding: 0 8px;
			}

			.location-image {
				flex: 0 0 auto;
				width: auto;
				height: 100%;
				border-radius: 3px;
			}

			.address-block {
				flex: 1 0;
				// min-width: 480px;
				display: flex;
				flex-flow: row wrap;
				align-items: flex-start;
				align-content: flex-start;
				margin: 0 -8px;

				.search-suggestions-block{
					padding: 0;
				}

				.edit-view {
					@extend %flex-w-1;

					&.state {
						.select-block {
							padding-left: 0;

							.custom-label {
								padding: 0;
							}

							.select-wrapper {
								flex: 1 0 auto;
								max-width: 100%;
								min-width: auto;

								.control {
									width: 100%;
								}
							}
						}
					}

					&.street {
						&.validation-error {
							.places-search-input {
								background: $pink;
								border: 2px solid $red;
							}
						}
					}

					&.street,
					&.country {
						@extend %flex-w-1;
					}

					&.city,
					&.state,
					&.zip,
					&.floor-area {
						@extend %flex-w-1-2;
					}

					&.floor-area {
						display: flex;
						flex-flow: row wrap;

						.floor-area-input {
							flex: 1 1 33%;

							> input {
								width: 100%
							}
						}

						.floor-area-units {
							flex: 1 1 65%;
							padding-left: 10px;

							.select-wrapper {
								flex: 0 1 60%;
								min-width: auto;

								.control {
									width: auto;
								}
							}
						}

						&.read-mode {
							.floor-area-input,
							.floor-area-units {
								flex: 0 0 auto;
							}
						}
					}

					.select-block.picker {
						display: block;
						padding-left: 0;
					}
				}
			}

			.map-block {
				flex: 1 1;
				padding: 9px 8px;
				min-width: 260px;
				min-height: 236px;

				* {
					z-index: auto;
				}

				label {
					&:nth-child(1),
					&:nth-child(2) {
						padding-top: 0;
					}
				}

				.maps-place {
					width: 100%;
					height: 100%;
					border-radius: 3px;

					.maps-place-empty {
						border-radius: 3px;
						border: 1px solid $tba-light2-grey;
						display: flex;
						height: 100%;
						justify-content: center;
						overflow: hidden;
						width: 100%;

						.map-image {
							display: block;
						}
					}
				}
			}

			.address-block,
			.location-image {
				padding: 8px;
			}

			.location-weather {
				margin-top: -15px;

				.wrapper {
					display: flex;
					align-items: center;
					padding: 0 8px;

					.condition-image {
						height: 27px;
					}

					.temperature {
						font-weight: bold;
						padding-left: 3px;

						.degrees {
							font-size: 14px;
						}
						.symbol {
							font-size: 11px;
						}
					}
				}
			}
		}
	}

	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $black;
		z-index: 1;
		opacity: 0.6;

		.section-spinner {
			margin: 0;
			padding: 0;
			border: 0;
			background-color: transparent;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.custom-action {
		font-size: 12px;
		color: $tba-darker-grey;
		& a {
			color: $tba-darker-grey;
			text-decoration: underline;
		}
	}

	&.edit-mode {
		.details-header {
			padding-bottom: 0;
		}
	}

	&:not(.edit-mode ) {
		.details-info-header .address-block .floor-area-wrapper {
			.floor-area-input {
				display: inline;
				flex: 1 1 auto;
				max-width: unset;
			}

			.floor-area-units {
				display: inline;
				flex: 1 1 auto;
				max-width: unset;
			}
		}
	}
}

.location-management-warning-dialog {
	.modal-panel {
		> p {
			width: 100%;
		}
	}

	.location-management-warning-message,
	.location-management-warning-message .description {
		display: flex;
		flex-direction: column;
	}
}

.weather-info {
	font-family: "Lato", sans-serif;
	line-height: 18px;
	margin-right: 10px;

	.parameter {
		display: inline-block;
		width: 110px;
		text-align: right;
		margin-right: 5px;
	}

	.value {
		font-weight: bold;
	}

	.symbol {
		margin-left: 3px;
	}
}
