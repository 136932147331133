@import '../../denali-components/functions.scss';

.title {
  margin-bottom: rem(8);
}

.noWrap {
  white-space: nowrap;
}

.priceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--amplify-colors-gradients-blue-30);
  border-radius: 50%;
  color: var(--amplify-colors-white);
  width: rem(36);
  height: rem(36);
  box-shadow: var(--amplify-colors-gradients-blue-shadow);
}

