

.linedashed {
    stroke-dasharray: 5,5;
    stroke-linecap: square;
}

.linedotted {
    stroke-dasharray: 1,2;
    stroke-linecap: round;
}

.linedashDotted {
    stroke-dasharray: 5,5,1,5;
    stroke-linecap: round;
}

.linelongDash {
    stroke-dasharray: 15,10;
    stroke-linecap: square;
}
