@import "../../../components//legacy/common/colors.scss";
@import "../../../components//legacy/common/grid.scss";

.pdf-generator-wrapper {
    .download-pdf-button {
        display: flex;
        align-items: center;

        & .icon:not(.caret) {
            padding-right: 0;
        }

        .section-spinner {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;

            .spin-wheel {
                color: $white;
            }
        }

        .icon {
            font-size: 13px;
        }
    }

    .download-pdf-download {
        margin-left: 4px;

        @include screen-max-extra-small {
            display: none;
        }
    }
}

@include screen-max-extra-small {
    .header-wrapper .toolbar .filter-div button.small.download-pdf-button {
        min-width: auto;
        margin: 0 3px 0 0;
    }
}
