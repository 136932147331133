@import "../../../common/colors.scss";

.selected-items {
	height: auto;
	max-height: 120px;
	display: block;
	border-top: 1px solid $tba-dark-grey;
	padding: 30px 0px 0px 10px;
	background: $tba-lightest-grey;
	overflow-y: auto;
	position: relative;
	z-index: 9;

	h3 {
		margin: 0;
	}

	.selected-item {
		font-size: 12px;
		word-break: break-all;

		.icon {
			margin: 0 4px 0 0;
			font-size: 14px;
			color: $tba-darker-grey;
		}
	}
}
