@import "src/components/legacy/common/colors.scss";

//mixins
	@mixin disabled-button {
		background-color: $tba-lighter-grey;
		border-color: $tba-light-grey;
		color: $tba-light-grey;
		&.disabled:hover, &.disabled:active {
			color: $tba-light-grey;
			background-color: $tba-lighter-grey;
			border-color: $tba-light-grey;
		}
	};
	@mixin button-size {
		height: 30px;
		min-width: 80px;
		padding: 0 10px;
	};
	@mixin small-button-size {
		height: 22px;
		min-width: 36px;
		padding: 0 10px;
	};
	@mixin horizontal-tab-button-size {
		height: 36px;
		min-width: 80px;
		padding: 0 10px;
		border: 1px solid $tba-dark-grey;
	}
	@mixin vertical-tab-button-size {
		height: 32px;
		min-width: 32px;
		padding: 0 6px;
		border: none;
	}
	@mixin small-vertical-tab-button-size {
		height: 22px;
		min-width: 24px;
		padding: 4px;
		border: none;
	}
	@mixin right-tab-borders {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}
	@mixin left-tab-borders {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
	@mixin tab-borders {
		@include right-tab-borders;
		@include left-tab-borders;
	}

button {
	position: relative;
	cursor: pointer;
	outline: none;
	border: 1px solid $tba-dark-grey;
	border-radius: 2px;
	padding: 0;

	&[disabled] {
		cursor: not-allowed;
	}
	& .icon {
		padding-right: 6px;
	}
}
button.disabled {
	@include button-size;
	@include text-button;
	@include disabled-button;
	cursor: not-allowed;
}
button.primary {
	@include button-size;
	background-color: $tba-dark-green;
	@include text-button;

	&:hover {
		background-color: $tba-medium-green;
	}
	&:active {
		background-color: $tba-dark-green;
	}
	&[disabled] {
		@include disabled-button;
	}
}
button.secondary {
	@include button-size;
	background-color: $tba-darker-grey;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
}
button.outline {
	@include button-size;
	@include text-button;
	color: $blue-8;
	background-color: $white;
	border-color: $blue-8;

	&:hover {
		background-color: $blue-8;
		color: $white;
	}
	&:active {
		background-color: $tba-lightest-grey;
		color: $blue-8;
	}
	&[disabled] {
		@include disabled-button;
	}

}
button.icon-btn {
	height: 30px;
	min-width: 30px;
	padding: 0;
	background-color: $tba-green;
	font-size: 18px;
	color: $white;

	&:hover {
		background-color: $tba-medium-green;
	}
	&:active {
		background-color: $tba-dark-green;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 18px;
	}
}
/* example of specifing another background color for icon */
button.red {
	background-color: $red;
	color: $white;

	&:hover {
		background-color: $red-1;
	}
	&:active {
		background-color: $red-2;
	}
	&[disabled] {
		@include disabled-button;
	}
}
button.action {
	@include button-size;
	background-color: $tba-darker-grey;
	border-radius: 6px;
	@include text-button;

	&:hover {
		background-color: $tba-dark2-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
}
button.light-action-small {
	@include small-button-size;
	background-color: $tba-lighter-grey;
	border-radius: 6px;
	@include text-button-small;
	color: $black;

	&:hover {
		background-color: $tba-light-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		padding-right: 4px;
	}
}
button.top-tab {
	@include horizontal-tab-button-size;
	background-color: $tba-darker-grey;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	border-bottom : 0px;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 14px;
	}
}
button.bottom-tab {
	@include horizontal-tab-button-size;
	background-color: $tba-darker-grey;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	border-top: 0px;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 14px;
	}

}
button.left-tab {
	@include vertical-tab-button-size;
	background-color: $tba-darker-grey;
	@include left-tab-borders;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 20px;
		padding: 0;
	}
}
button.right-tab {
	@include vertical-tab-button-size;
	background-color: $tba-darker-grey;
	@include right-tab-borders;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 20px;
		padding: 0;
	}

}
button.left-tab-small {
	@include small-vertical-tab-button-size;
	background-color: $tba-darker-grey;
	@include left-tab-borders;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 14px;
		padding: 0;
	}
}
button.right-tab-small {
	@include small-vertical-tab-button-size;
	background-color: $tba-darker-grey;
	@include right-tab-borders;
	@include text-button;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 14px;
		padding: 0;
	}

}
button.hamburger-small {
	box-sizing: border-box;
	@include small-vertical-tab-button-size;
	background-color: $tba-darker-grey;
	@include tab-borders;
	@include text-button-small;

	&:hover {
		background-color: $tba-dark-grey;
	}
	&:active {
		background-color: $tba-medium-grey;
	}
	&[disabled] {
		@include disabled-button;
	}
	& .icon {
		font-size: 14px;
		padding: 0;
	}

	&.opened {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		background-color: $tba-medium-grey;
	}

}
button.small {
	@include small-button-size;
	@include text-button-small;

	& .icon:not(.caret) {
		padding-right: 4px;
	}
}
button.wait {
	@include button-size;
	@include disabled-button;
	margin: 0;
	cursor: wait;
	& .spinner {
		font-size: 16px;
	}
}

button.change {
	padding-left: 6px;
	padding-right: 6px;
	text-transform: uppercase;
	width: 80px;

	& span:first-child {
		padding-right: 6px;
	}

	&.opened {
		border-bottom-color: transparent;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		& .caret {
			transform: rotate(180deg);
		}
	}

	& .caret {
		display: inline-block;
		padding: 0;
		transition: all 0.4s ease;
	}
}

button.additional {
	background-color: $tba-lightest-grey;
	font-weight: bold;
	text-transform: none;
	color: $tba-darker-grey;

	& span {
		color: $tba-darker-grey;
		font-weight: 900;
		text-decoration: underline;
	}
	&:hover {
		background-color: $tba-lighter-grey;
	}
	&:active {
		background-color: $tba-light-grey;
	}
}

button.transparent {
	background: transparent;
	border: none;
}

button.action-icon {
	background: none;
	border: none;
	color: $tba-darker-grey;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	height: 100%;
	line-height: 16px;
	margin: 0 0 0 4px;

	&:hover {
		color: $tba-dark-grey;
		text-decoration: none;
	}
}
