@import "../../common/colors.scss";
@import "../../common/grid.scss";

.modal-datepicker-wrapper {
	margin-bottom: 15px;
	position: relative;
	.combine-error-icon-message{
    	display: flex;
		.date-error-text{
			display: block;
			padding-top: 0px;
			padding-right: 0px;
		}
		.mini-error-text{
			font-size: 10px;
			font-style: italic;
			color: $red;
			padding-left: 15px;
		}
	}
	.icon-error{
		color: $red;
		position: absolute;
		padding-top: 2px;
	}
	.is-loading {
		position: absolute;
		top: 6px;
		right: 12px;
	}
	input.modal-date-input.has-error{
		border-width: 2px;
		border-color: $red;
		background-color: $red-1;
	}
	.has-error{
		color: $red;
	}
	.date-container{
		position: relative;
		width: 85%;
		display: flex;
		input.modal-date-input{
			width: 200px;
		}
		.datepicker-react{
			position:  absolute;
			font-size: 26px;
		}
	}
	.date-error-text{
		display: block;
	}
	.mini-error-text{
		font-size: 10px;
		font-style: italic;
		color: $red;
		padding-left: 15px;
	}
	span.custom-label{
		display: block;
	}
}

.is-iconError .modal-datepicker-wrapper .icon-error{
	position: relative!important;
	margin-right: 0px!important;
}

.is-iconError .modal-text-wrapper .custom-label{
    padding : 0px !important;
  }