@import "src/components/legacy/common/colors.scss";

.places-autocomplete-wrapper {

    .places-search-bar-container {
        width: 100%;
        position: relative;

        > .places-search-input-container {
            display: flex;
        }

        input {
            width: 100%;
        }

        button {
            width: 20px;
            color: $white;
        }
    }

    .address-autocomplete-container {
        background-color: $tba-lightest-grey;
        border: 1px solid $tba-dark-grey;
        border-radius: 2px;
        box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
        margin-top: -1px;
        width: 100%;
        position: absolute;
        z-index: 10;

        .address-suggestion-item {
            padding: 8px;
            text-align: left;
            cursor: pointer;

            &.address-suggestion-item-active {
                background-color: $tba-green;
            }
        }

        .address-autocomplete-footer {
            display: flex;
            justify-content: flex-end;
            padding: 4px;
        }

        .autocomplete-footer-image {
            display: inline-block;
            width: auto;
            height: 18px;
        }
    }
}