@import './functions.scss';
@import 'src/denali-components/Amplify/amplify.scss';

[data-amplify-theme='denali-theme'] {
  font-size: 14px;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  .body-xs {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(12);
    line-height: 1.28571;

    font-style: normal;
    font-weight: 400;
  }

  .body-sm {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(20);
  }

  .body-md {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(14);
    line-height: 1.28571;
    font-style: normal;
    font-weight: 400;
  }

  .body-lg {
    color: var(--amplify-colors-font-secondary);
    font-size: rem(16);
    line-height: 1.25;
    font-style: normal;
    font-weight: 500;
  }

  h1,
  .h1 {
    font-size: rem(32);
    line-height: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0 0 rem(10);
  }

  h2,
  .h2 {
    font-size: rem(28);
    line-height: 1.15;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0 0 rem(10);
  }

  h3,
  .h3 {
    font-size: rem(26);
    line-height: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0 0 rem(10);
  }

  h4,
  .h4 {
    font-size: rem(24);
    line-height: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0 0 rem(10);
  }

  h5,
  .h5 {
    font-size: rem(21);
    line-height: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    margin: 0 0 rem(10);
  }

  h6,
  .h6 {
    font-size: rem(12);
    line-height: 1.3333;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: normal;
    margin: 0 0 rem(10);
  }

  p {
    margin: 0 0 rem(16);
  }

  .title-sm {
    font-size: rem(18);
    line-height: normal;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--amplify-colors-neutral-90);

    @media (max-width: 1280px) {
      font-size: rem(16);
    }
  }

  .title-md {
    color: var(--amplify-colors-neutral-90);
    font-size: rem(24);
    line-height: normal;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
  }

  .noWrap {
    white-space: nowrap;
  }

  .heading-gradient {
    background-image: linear-gradient(150deg, #eafeea, #daf9da);
    // background-image: linear-gradient(135deg, #fff, #000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .button-transparent {
    background: var(--amplify-components-button-transparent-background);
    border: var(--amplify-components-button-transparent-border);
    padding-block-start: var(
      --amplify-components-button-transparent-padding-block-start
    );
    padding-block-end: var(
      --amplify-components-button-transparent-padding-block-end
    );
    padding-inline-start: var(
      --amplify-components-button-transparent-padding-inline-start
    );
    padding-inline-end: var(
      --amplify-components-button-transparent-padding-inline-end
    );

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      background: var(
        --amplify-components-button-transparent-disabled-background
      );
      color: var(--amplify-components-button-transparent-disabled-foreground);
    }
  }

  .button-icon-left {
    svg {
      margin-right: rem(8);
    }
  }

  .button-inverse {
    background: var(--amplify-components-button-inverse-background);
    color: var(--amplify-components-button-inverse-foreground);
  }

  .button-red {
    background: var(--amplify-components-button-red-background);
    color: var(--amplify-components-button-red-foreground);

    &:hover {
      background: var(--amplify-components-button-red-hover-background);
      color: var(--amplify-components-button-red-hover-foreground);
    }

    &:focus {
      background: var(--amplify-components-button-red-focus-background);
      color: var(--amplify-components-button-red-focus-foreground);
    }
  }

  .button-gray {
    background: var(--amplify-components-button-gray-background);
    color: var(--amplify-components-button-gray-foreground);

    &:hover {
      background: var(--amplify-components-button-gray-hover-background-color);
      color: var(--amplify-components-button-gray-hover-foreground);
    }

    &:focus {
      background: var(--amplify-components-button-gray-focus-background-color);
      color: var(--amplify-components-button-gray-focus-foreground);
    }

    &[disabled] {
      background: var(--amplify-components-button-disabled-background-color);
      color: var(--amplify-components-button-disabled-color);
    }
  }

  .button-green {
    background: var(--amplify-components-button-green-background);
    color: var(--amplify-components-button-green-foreground);
    box-shadow: var(--amplify-components-button-green-shadow);

    &:hover {
      background: var(--amplify-components-button-green-hover-background-color);
      color: var(--amplify-components-button-green-hover-foreground);
    }

    &:focus {
      background: var(--amplify-components-button-green-focus-background-color);
      color: var(--amplify-components-button-green-focus-foreground);
    }

    &[disabled] {
      background: var(--amplify-components-button-disabled-background-color);
      color: var(--amplify-components-button-disabled-color);
    }
  }

  .button-link-secondary {
    color: var(--amplify-colors-font-secondary);

    &:hover,
    &:focus {
      color: var(--amplify-colors-font-primary);
      background: transparent;
    }
  }

  .blue-link a,
  a.blue-link {
    color: var(--amplify-colors-font-link);
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .text-highlight {
    color: var(--amplify-colors-green-50);
  }

  .text-highlight-pink {
    background: linear-gradient(
      141deg,
      rgba(156, 0, 255, 0.75) 9.19%,
      rgba(255, 188, 255, 0.6) 200%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .tableCheckboxBulkAction {
    svg {
      width: rem(20);
      height: rem(20);
    }

    .input-default {
      fill: #f9fafc;
    }

    .input-error-stroke {
      fill: #fff;
    }
    .input-error-bg {
      fill: #f00;
    }

    .input-hover-stroke {
      fill: #fff;
    }
    .input-hover-bg {
      fill: #000;
    }

    .input-active-stroke,
    .input-intermediate-stroke {
      fill: #000;
    }

    .input-inactive-disabled {
      fill: #eee;
    }
  }

  // Fix for tooltip (uses existing)
  .tooltip-base {
    min-height: 150px;
  }

  table.empty {
    border: none;
  }
}
