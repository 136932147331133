@import 'src/components/legacy/common/colors.scss';

@keyframes menuOpenAnimation {
  from {
    overflow-y: hidden;
  }

  to {
    overflow-y: auto;
  }
}

.select-wrapper {
  align-self: center;
  max-height: 50px;
  min-width: 200px;
  max-width: 200px;
  position: relative;

  &.small,
  &.medium {
    min-width: 100px;
  }

  &.mediumLarge {
    min-width: 150px;
  }

  &:focus {
    outline: none;
  }

  .icon {
    margin: 7px 9px;
    font-size: 15px;
  }

  .input-search-text {
    margin: 4px 6px;
    width: 95%;
  }

  .control {
    background: $tba-lightest2-grey;
    border: 1px solid $tba-dark2-grey;
    border-radius: 2px;
    display: flex;
    min-height: 26px;
    width: inherit;
    &.small {
      width: 80px;
    }
    &.medium {
      width: 115px;
    }
    &.error {
      background-color: $light-red;
      border: 1px solid $red;
    }
    .control-label {
      border-right: 1px solid $tba-dark2-grey;
      flex: 1 0 0;
      font-size: 12px;
      font-weight: bold;
      overflow: hidden;
      padding: 0 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 26px;
      height: 25px;
      width: 100%;

      &.control-placeholder {
        color: $tba-dark-grey;
      }

      &.with-end-icon {
        display: flex;
        > span:first-child {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .control-icon {
      align-items: center;
      border-left: 1px solid $white;
      display: flex;
      flex: 0 0 28px;
      justify-content: center;

      .select-expand-trigger {
        background-color: transparent;
        border: 0;
        color: $black;
        cursor: pointer;
        font-size: 14px;
        transition: transform 0.4s ease;
        position: relative;
        top: 2px;
      }
    }
    .spin-wheel {
      font-size: inherit;
      display: inline-block;
      font-family: 'Emerald' !important;
      line-height: 1;
      color: $tba-dark-grey;
      animation: rotate 1.2s infinite linear;
    }
    @media all and (max-width: 519px) {
      margin-bottom: 10px;
    }
    @media all and (max-width: 899px) {
      margin-bottom: 10px;
    }
    @media all and (max-width: 709px) {
      margin-bottom: 10px;
    }
  }

  .select-dropdown {
    animation: menuOpenAnimation 1s linear;
    background: $tba-lightest2-grey;
    border: 1px solid $tba-dark2-grey;
    border-radius: 2px;
    border-top: 0;
    box-shadow: 3px 4px 6px rgba($black, 0.1);
    font-size: 12px;
    font-weight: bold;
    left: 0;
    max-height: 186px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 100%;
    width: inherit;
    z-index: 5;

    &.top {
      top: auto;
      bottom: 24px;
      border-top: 1px solid $tba-dark-grey;
      box-shadow: -3px -4px 6px rgba($black, 0.1);
    }

    .select-menu-item {
      cursor: pointer;
      line-height: 1;
      padding: 7px 8px;
      text-align: left;
      white-space: pre-line;
      word-wrap: break-word;
      display: flex;
      align-items: center;

      .icon {
        margin: 5px 7px;
        font-size: 15px;
      }

      &:hover {
        background-color: $tba-green;
        color: $white;
      }
      &.disabled,
      &.disabled:hover {
        background-color: $tba-lightest-grey;
        color: $tba-light-grey;
      }
    }
  }

  &.disabled .control {
    background: $tba-light-grey;
    border-color: $tba-medium-grey;
    cursor: not-allowed;

    .control-icon {
      border-color: $tba-light-grey;

      .select-expand-trigger {
        cursor: not-allowed;
      }
    }

    .control-label,
    .control-icon .select-expand-trigger {
      border-color: $tba-medium-grey;
      color: $tba-dark-grey;
    }
  }
}

.dark-select {
  cursor: pointer;
  min-width: 0;
  .control {
    background: $tba-darker-grey;
    border-radius: 4px;
    color: $white;
    display: inline-flex;
    width: auto;
    .control-icon .select-expand-trigger {
      color: $white;
    }
    .control-label {
      align-self: center;
      text-align: center;
    }
    .control-icon {
      flex: 0;
      margin: 0 5px 0 0;
      padding: 0 0 0 5px;
    }
  }
  &.open {
    border-radius: 4px 4px 0 0;
    .control {
      background: $tba-dark-grey;
    }
  }
  .select-dropdown {
    background: $tba-dark-grey;
    border-radius: 4px;
    color: $white;
    width: 100%;

    .select-menu-item {
      border-bottom: 1px solid $tba-medium-grey;
      margin: 0 2px;

      &:hover {
        background-color: $tba-dark-grey;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
