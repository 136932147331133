@import "src/components/legacy/common/colors.scss";

.checkbox-container {
    &.has-error {
        input + label::before {
            border-color: $red;
            background: $pink;
        }
        label {
            color: $red;
        }
    }
}
