@import '../../denali-components/functions.scss';

.title {
  margin-bottom: rem(8);
}

.noWrap {
  white-space: nowrap;
}

.connectedDevices {
  button {
    font-size: rem(14);
    line-height: rem(20);
    margin-bottom: rem(4);
    margin-left: rem(4);

    svg {
      margin-right: rem(8);
    }
  }
}

.locationIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--amplify-colors-neutral-10);
  border-radius: 50%;
  width: rem(36);
  height: rem(36);
}

.building {
  font-size: rem(14);
  line-height: rem(18);
  white-space: nowrap;
}

.available {
  color: var(--amplify-colors-green-60) !important;

  svg {
    color: var(--amplify-colors-green-60);
  }
}

.notAvailable {
  color: var(--amplify-colors-red-50) !important;

  svg {
    color: var(--amplify-colors-red-50);
  }
}

tr[data-testid^='deviceConnectPage-table'] {
  td:hover {
    cursor: default;
  }
}
