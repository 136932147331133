.filter-component {
	display: flex;
	align-items: center;

	.search-box {
		margin-left: -2px;
	}

	.select-wrapper {
		position: relative;
		z-index: 1;
	}

	.custom-label {
		font-size: 13px;
		font-weight: bold;
	}

	.filter-component__description {
		font-size: 13px;
		line-height: 18px;
		font-weight: bold;
		font-style: italic;
		padding-left: 10px;
	}

	&.filter-component--disabled {
		cursor : not-allowed;
		pointer-events: none;
	}

	.checkbox-select {
		min-width: 200px;
	}
}
