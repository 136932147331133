@import '../functions.scss';

.badgeWrapper {
  position: relative;

  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: -7px;
    height: 14px;
    width: 14px;
    font-size: 10px;
    border-radius: 50%;
    background-color: var(--amplify-colors-blue-50);
    color: var(--amplify-colors-white);
  }
}