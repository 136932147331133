.amplify-checkbox {
}

.amplify-checkbox__button {
  &:before {
    background: var(
      --amplify-components-checkbox-button-before-background-color
    );
  }

  .amplify-checkbox__icon,
  .amplify-checkbox__icon--checked,
  .amplify-checkbox__icon--checked svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }

  &.amplify-checkbox__button--focused {
    &:before {
      border-color: var(
        --amplify-components-checkbox-button-focused-before-border-color
      );
      box-shadow: var(
        --amplify-components-checkbox-button-focused-before-box-shadow
      );
    }
  }
}
