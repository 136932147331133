@import "src/components/legacy/common/colors.scss";

.range-selector {
  position: relative;
  height: 52px;
  padding-top: 15px;

  .has_error {
    border: 1px solid $red;
  }
  .range-bar-base {
    width: 100%;
    height: 16px;
    background-color: $tba-green;
    border-radius: 3px;
    border: 1px solid $tba-dark-grey;
    position: relative;
    overflow: hidden;
    .cautionary {
      background-color: $yellow;
    }
    .critical {
      background-color: $red;
    }
    .range-bar-right {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      pointer-events: none;
      border-left: 1px solid $tba-dark-grey;
    }
    .range-bar-left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      pointer-events: none;
      border-right: 1px solid $tba-dark-grey;
    }

  }

  .pointer-block {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 1em;
    width: 50%;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    &.left {
      right: 0;
    }
    &.right {
      left: 0;
    }
    &.up {
      top: 56%;
      transform: translateX(-50%);
      flex-flow: column;
    }
    &.down {
      top: -20%;
      transform: translateX(-50%);
      flex-flow: column-reverse;
    }
    .pointer{
      &.cautionary {
        color: $black;
      }
      &.critical {
        color: $black;
      }
    }
  }
  .max {
    position: absolute;
    top: 2px;
    pointer-events: none;
    line-height: 1em;
    &.left {
      left: 2px;
    }
    &.right {
      right: 2px;
    }
  }

  &.range-selector--disabled  {
    color: $tba-dark-grey;

    .range-bar-base {
      background-color: $tba-light-green;
      .cautionary {
        background-color: $light-yellow;
      }
      .critical {
        background-color: $light-red;
      }
    }

    .pointer-block {
      .pointer {
        color: $tba-dark-grey
      }
    }
  }
}
