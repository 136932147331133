@import '../functions.scss';

.page {
  padding: rem(50) rem(60);
  margin: 0 -30px -50px -20px; /* cancel out previous building selector and page spacing. */
  min-height: 100vh;
  background: var(--amplify-components-page-background);

  & > header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(40);

    .pageHeader {
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  &[data-background='alt'] {
    background: var(--amplify-components-page-background-alt);
  }

  &[data-background='invert'] {
    background: var(--amplify-components-page-background-invert);
  }

  a {
    text-decoration: underline;
    color: var(--amplify-colors-neutral-90);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.pageIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(40);
  width: rem(40);
  background: var(--amplify-colors-black);
  padding: rem(8);
  border-radius: rem(12);
  line-height: 0;
  margin-right: rem(16);
  vertical-align: middle;
  flex-grow: 0;

  &[data-color='blue'] {
    background: var(--amplify-colors-gradients-blue-background);
    box-shadow: var(--amplify-colors-gradients-blue-shadow);
  }

  &[data-color='cobolt'] {
    background: var(--amplify-colors-gradients-blue-cobolt);
    box-shadow: var(--amplify-colors-gradients-blue-cobolt-shadow);
  }

  &[data-color='purple'] {
    background: var(--amplify-colors-gradients-purple-background);
    box-shadow: var(--amplify-colors-gradients-purple-shadow);
  }

  &[data-color='orange'] {
    background: var(--amplify-colors-gradients-orange-background);
    box-shadow: var(--amplify-colors-gradients-orange-shadow);
  }

  &[data-color='lightorange'] {
    background: var(--amplify-colors-gradients-lightorange-background);
    box-shadow: var(--amplify-colors-gradients-lightorange-shadow);
  }

  svg {
    width: rem(18);
    height: rem(18);
    path {
      fill: var(--amplify-colors-gradients-purple-foreground);
    }
  }
}

.left {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: nowrap;
  gap: rem(8);
}

.right {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: rem(12);
}
