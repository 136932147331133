
	/* Primary Colors */

	$tba-lighter-green: #dae2d0;
	$tba-light-green: #c3dfa2;
	$tba-green: #61ae34;
	$tba-medium-green: #4c912d;
	$tba-medium2-green: #467e26;
	$tba-dark-green: #367326;
	$white: #ffffff;
	$black: #000000;
	$black1: #1e1e1e;
	$black2: #262525;
	$black3: #030303;
	$tba-darker-grey: #666666;
	$tba-dark-grey: #999999;
	$tba-dark2-grey: #7d7d7d;
	$tba-dark3-grey: #979797;
	$tba-medium-purple: #6b77c7;
	$tba-medium-purple2: #455fd9;
	$tba-medium-dark-grey: #757575;
	$tba-medium-dark-grey1: #6b6b6b;
	$tba-medium-dark-grey2: #5c5c5c;
	$tba-medium-grey: #bbbbbb;
	$tba-light-grey: #cccccc;
	$tba-light2-grey: #d2d2d2;
	$tba-light3-grey: #dddddd;
	$tba-lighter-grey: #eeeeee;
	$tba-lighter2-grey: #e6e6e6;
	$tba-lightest-grey: #f4f4f4;
	$tba-lightest2-grey: #f9fafc;
	$tba-almost-white: #fafafa;
	$tba-almost2-white: #f5f5f5;
	$tba-lighter-grey-3: #dce3e5;

	/* Secondary Colors */

	$green: $tba-green;
	$yellow: #fff10b;
	$light-yellow: #fbf9d1;
	$red: #d52b1e;
	$light-red: #f4d3d2;
	$blue: #00b9e4;
	$orange: #fb9a09;
	$purple: #501c8b;
	$brown: #703915;
	$grey: #959595;
	$pink: #facbcb;
	$light-pink: #fae8e1;

	/* Tertiary Colors */

	$green-1: #daffa7;
	$green-2: #b7ff5b;
	$green-3: #89c506;
	$green-4: #367326;
	$yellow-1: #fff787;
	$yellow-2: #e1d47b;
	$yellow-3: #ab9a1f;
	$yellow-4: #817b14;
	$red-1: #fea7aa;
	$red-2: #fd575d;
	$red-3: #e10018;
	$red-4: #8c0000;
	$blue-1: #cfd5e8;
	$blue-2: #6c97bf;
	$blue-3: #326089;
	$blue-4: #203854;
	$blue-5: #bfebfc;
	$blue-6: #cceffd;
	$blue-7: #8fb0bd;
	$blue-8: #3c5e99;
	$blue-9: #272942;
	$blue-10: #4e6da2;
	$orange-1: #ffeec1;
	$orange-2: #fede87;
	$orange-3: #fecf54;
	$orange-4: #d7770c;
	$orange-5: #ff6244;
	$purple-1: #d5c5e3;
	$purple-2: #ab90cb;
	$purple-3: #865eb0;
	$purple-4: #432762;
	$brown-1: #efd4b8;
	$brown-2: #c89146;
	$brown-3: #a06d30;
	$brown-4: #6d3415;
	$grey-1: #efefef;
	$grey-2: #bababa;
	$grey-3: #6c6c6c;
	$grey-4: #3c3c3c;
	$grey-5: #aaaaaa;
	$grey-6: #777777;
	$grey-7: #d3d4de;

	/* KPI colors */

	$comfort: #2c10eb;
	$reliability: #3a77b3;
	$performance: #00aeef;
	$energy-usage: $tba-green;
	$compliance: #7340a4;
	$custom: #fb8f0d;
	$custom2: #bf5de6;

	/* Tag colors */

	$tag-main: #6ba6eb;
	$tag-hover: #507db0;
	$tag-active: #2a4e77;

	/* Notification colors */

	$notification-link: #000;
	$notification-modal-description-grey: #707070;
	$notification-title: #717171;
	$notification-hover: #ebf6ff;
