.tt {
  background: #222;
  border-radius: 4px;
  color: white;
  font-size: 90%;
  font-weight: bold;
  left: 0;
  padding: 5px;
  position: absolute;
  top: 0;
  width: max-content;
  z-index: 2;
}

.tt-arrow {
  background: inherit;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  width: 10px;
  z-index: 1;
}

.tt-content {
  z-index: 2;
  position: relative;
}

.tt-item {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.tt-item::first-letter {
  text-transform: capitalize;
}

.tt-child {
  margin-left: 10px;
}
