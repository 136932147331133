@import '../functions.scss';

.flyoutMenu {
  background: var(--amplify-colors-white);
  color: var(--amplify-components-denali-table-filter-color);
  border: none !important;
  border-radius: 30px;
  outline: none;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
  padding: rem(12);
  z-index: 3;

  &:hover,
  &:selected,
  &:focused,
  &:focus-visible {
    border: none;
    outline: none;
  }

  button {
    background: none;
    border: none;
    box-shadow: none;
  }

  .flyoutMenuItem {

  }

  .flyoutMenuLabel {
  }
}