/* SVG styles */
svg.t-chart-timeline {
  display: block;
  overflow: visible; /* fix gridlines overflowing the left side in IE */
}
.t-chart-timeline {
  .grid > .domain {
    display: none;
  }
  .grid line {
    // stroke: #ffffff; // #515151;
    stroke: #d8d8d8; // #515151;
    stroke-width: 1px;
  }

  .axis path {
    display: none;
  }

  .tick .range-text {
    fill: #999999;
    font-size: 11px;
  }

  .tick {
    shape-rendering: crispEdges;
  }

  .brush .selection {
    fill-opacity: 0.16;
    shape-rendering: crispEdges;
    stroke: #206fcf;
    fill: #206fcf;
    stroke-opacity: 0.7;
    stroke-width: 2;
  }

  .range-bar {
    stroke: #d8d8d8;
  }

  #barGradient .stop1 {
    // stop-color: #454545;
    stop-color: #ffffff;
    stop-opacity: 1;
  }

  #barGradient .stop2 {
    // stop-color: #181818;
    stop-color: #ffffff;
    stop-opacity: 1;
  }

  #buttonGradient .stop1 {
    stop-color: transparent;
    stop-opacity: 1;
  }

  #buttonGradient .stop2 {
    stop-color: transparent;
    stop-opacity: 1;
  }

  #brushGradient .stop1 {
    stop-color: #cacaca;
    stop-opacity: 0;
  }

  #brushGradient .stop2 {
    stop-opacity: 0.13;
  }

  #brushGradient .stop3 {
    stop-color: #e0f8c0;
    stop-opacity: 0.5;
  }

  #timeGradient .stop1 {
    stop-color: #ffffff;
    stop-opacity: 0.6;
  }

  #timeGradient .stop2 {
    stop-color: #ffffff;
    stop-opacity: 0.7;
  }

  #grabberGradient .stop1 {
    stop-color: #ffffff;
  }

  #grabberGradient .stop2 {
    stop-color: #206fcf;
  }

  .hide-el {
    display: none;
  }

  .indicator {
    rect {
      fill-opacity: 1;
      pointer-events: none;
      stroke: #206fcf;
      stroke-width: 0.8;
    }

    text {
      fill: #000;
      fill-opacity: 1;
      font-size: 10px;
      pointer-events: none;
      stroke: none;
    }
  }
}
