@import "../../common/typography.scss";
@import "../../common/colors.scss";

.custom-picker {
	height: 400px;
	border: 1px solid $tba-dark-grey;
	border-radius: 2px;
	padding-top: 6px;
	background: $tba-lightest-grey;
	display: flex;
	flex-flow: column;
	flex: 1;

	&.invalid {
		border: 2px solid $red;

		header {
			color: $red;
		}
	}

	header {
		padding-left: 8px;
		height: 20px;
		font-size: 14px;
		line-height: 14px;
	}

	.search-box {
		width: 100%;
		border-radius: 0;
		border: none;
	}

	.custom-table {
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow: hidden;

		.generic-table-wrapper {
			overflow-x: hidden;
			position: relative;
		}
	}
}
